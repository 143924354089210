import { first } from "lodash"
import { w3cwebsocket as W3CWebSocket } from "websocket"
import { MESSAGE_WEBSOCKET_DOMAIN, EYE_REFRACT_SOCKET_KEY, VX_650_SOCKET_KEY } from "./optic/contants"
import { Diagnostic } from "types/payload"

export interface SocketPayload {
  domain: MESSAGE_WEBSOCKET_DOMAIN
  type: string
  payload: any
  action: string
  // body?: {
  //   [x: string]: unknown
  // }
  result: "OK" | "ONGOING" | "KO" | "TIMEOUT"
}

export enum ReadyState {
  CLOSED = 3,
  OPEN = 1,
}
export interface EyeRefractPayload {
  filename?: string
  filecontent?: string | Blob
}
export const WEBSOCKET_OPEN_STATE = 1

export async function staging(
  client: W3CWebSocket
): Promise<boolean | unknown> {
  console.log(
    "%c[ creating a new Websocket client...]",
    "background:  #8bc34a",
    client
  )
  const clientState = new Promise((resolve) => {
    client.onerror = function () {
      return resolve(false)
    }
    client.onopen = function () {
      if (client.readyState === WEBSOCKET_OPEN_STATE) return resolve(true)
      else return resolve(false)
    }
    client.onclose = function () {
      return resolve(false)
    }
  })
  return await clientState
}

export const getWssHost = (): string => {
  return "wss://wss.medadom.com:8080"
}
export function getFileExtByType(type: string) {
  switch (type) {
    case "get_exam_pdf":
      return "pdf"
    case "get_eye_refract_xml":
      return "xml"
    default:
      return "pdf"
  }
}

export function getDocumentEndpoint(type: string) {
  switch (type) {
    case "get_exam_pdf":
      return "admin/optician/v1/exam"
    case "get_eye_refract_xml":
      return "admin/optician/v1/refraction"
    default:
      return ""
  }
}

export function isEngineDiagnosticOk(diagnostic: {[x: string]: Diagnostic}) {
  return diagnostic && diagnostic?.[EYE_REFRACT_SOCKET_KEY]?.status === "OK" && diagnostic?.[VX_650_SOCKET_KEY]?.status === "OK";
}

export function getEngineDiagnosticError(diagnostic : {[x: string]: Diagnostic}): null | Array<string> {
  return diagnostic && Object.keys(diagnostic).map((key) => diagnostic[key].error);
}
