import { languages } from "lib/languages"
import { Call, CallState } from "types/payload"
import dayjs from "dayjs"
import { Carousel } from "components/Carousel/Index"
import { cdn } from "core/cdn"
import FatButton from "components/Button/FatButton/FatButton"
import React, { useState } from "react"
import { ModalDocument } from "client/documents/utils"
import { Buttons } from "components/Card/Card"
import { Button } from "components/Button"
import { Important } from "components/Title/Title"

interface HistoryProps {
  calls: Call[]
}

const CallDisplayer: React.FC<Call & { onSelect: () => void }> = (props) => (
  <FatButton
    onClick={props.onSelect}
    doNotFormat
    img={cdn("icons/camera_picto.svg")}
  >
    {`${languages.tlcOf} ${dayjs(props.created_at).format(
      "DD/MM/YYYY à HH:mm"
    )}`}
  </FatButton>
)

export const History: React.FC<HistoryProps> = (props): JSX.Element => {
  const [selectedCall, setSelectedCall] = useState<Call | undefined>()
  const items = props.calls
    .filter(
      (call) =>
        ![
          CallState.CANCELLED_BY_DOCTOR,
          CallState.CANCELLED_BY_PATIENT,
          CallState.EXPIRED,
        ].includes(call.state)
    )
    .map((call, key) => (
      <CallDisplayer
        key={key}
        {...call}
        onSelect={() => setSelectedCall(call)}
      />
    ))
  return (
    <>
      {items.length ? (
        <Carousel items={items} />
      ) : (
        <Buttons>
          <Important text={languages.noTLC} />
        </Buttons>
      )}

      <ModalDocument
        call={selectedCall}
        footer={
          <Buttons>
            <Button onClick={() => setSelectedCall(undefined)}>
              {languages.previous}
            </Button>
          </Buttons>
        }
        onCancel={() => setSelectedCall(undefined)}
        acceptText={languages.cancel}
      />
    </>
  )
}
