import { has } from "lodash"

import { ERROR_API } from "types/error"
import { ResponsePayload } from "types/payload"

import { handleGenericApiError } from "./api-errors"

interface Params {
  method: string
  additionalHeaders?: HeadersInit
  payload?: string | any
  body?: any
  responseFormat?: "blob" | "xml"
}
export const httpSuccess = (response: ResponsePayload, fields?: string) => {
  if (response.status === "ok") {
    if (fields) return has(response, fields)
    else return true
  } else return false
}
function request<T>(
  url: string,
  {
    method,
    additionalHeaders = {},
    payload = "",
    responseFormat = undefined,
    body,
  }: Params
): Promise<T> {
  const defaultHeaders = {
    "Content-Type": method === "GET" ? "" : "application/json; charset=utf-8",
    "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
  }

  const headers = Object.assign({}, defaultHeaders, additionalHeaders)
  const params: { headers: HeadersInit; method?: string } = {
    headers,
    method,
  }

  if (payload) {
    Object.assign(params, { body: JSON.stringify(payload) })
  }
  if (body) {
    Object.assign(params, { body })
  }

  return fetchWithTimeout(`/api${url}`, {
    ...params,
  })
    .then(handleGenericApiError)
    .then(async (response: Response | string) => {
      if (typeof response === "string") return response
      if (responseFormat === "blob") {
        const blob = await response.blob()
        return blob
      } else if (responseFormat === "xml") {
        return response
      } else {
        return response.json()
      }
    })
    .then((response) => {
      if (response.jwt) sessionStorage.setItem("X-USER-Token", response.jwt)
      return response
    })
    .catch((e) => {
      throw e
    })
}

export const fireTimeout = async (
  func: Promise<Response>,
  time: number
): Promise<Response | unknown> => {
  return Promise.race([
    func,
    new Promise((res) =>
      setTimeout(
        () => res({ status: 408, error: ERROR_API.TIMEOUT_ERROR }),
        time
      )
    ),
  ])
}

async function fetchWithTimeout(
  resource: RequestInfo,
  options?: RequestInit
): Promise<Response> {
  const response = await fireTimeout(
    fetch(resource, {
      ...options,
    }),
    10000
  )
  return response as Response
}

export { request }
