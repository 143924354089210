import { httpSuccess, request } from "lib/request"
import { call, put, takeLatest } from "redux-saga/effects"
import { ResponseConfiguration } from "types/payload"
import { Configuration } from "types/props"
import { Action, GWatcher } from "types/redux"
import { CONFIGURATION_REQUEST } from "./constant"
import config from "react-global-configuration"
import actions from "./actions"
async function configurationApi(): Promise<ResponseConfiguration> {
  return await request(config.get("admin.config.route"), {
    method: config.get("admin.config.method"),
  })
}
function* configurationFlow() {
  try {
    const response: ResponseConfiguration = yield call(configurationApi)
    if (httpSuccess(response, "configuration"))
      yield put(actions.success(response.configuration))
    else yield put(actions.error("Aucune configuration trouvée"))
  } catch (e) {
    console.error(e, {
      route: config.get("admin.config.route"),
    })
    yield put(actions.error(e as string))
  }
}

function* configurationWatcher(): GWatcher {
  yield takeLatest(CONFIGURATION_REQUEST, configurationFlow)
}

export default configurationWatcher
