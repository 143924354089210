import { BirthPlace, Gender, Relative } from "types/entity"
import { InputNames } from "types/props"

import {
  Amo,
  Cmu,
  DataCarteVitale,
  Patient,
  RightsPeriod,
  T_AsnBeneficiaire,
} from "types/sesam"
import { century, DATE_FORMAT } from "lib/utils"
import { NirReaderResponse } from "types/store"
import dayjs, { Dayjs } from "dayjs"
import { capitalize } from "lodash"

export const getPatientFromDataCarteVitale = (
  data: DataCarteVitale
): Partial<Patient> => {
  let beneficiaire: T_AsnBeneficiaire | T_AsnBeneficiaire[] =
    data.T_AsnDonneesVitale?.listeBenef?.T_AsnBeneficiaire || {}
  if (Array.isArray(beneficiaire)) beneficiaire = beneficiaire[0]

  return getPatientFromBeneficiaire(beneficiaire)
}

export const getInseeCodeFromNir = (nir: string) => {
  return nir && nir.substring(5, 10) || 99999;
}

export const getPatientFromBeneficiaire = (
  beneficiaire: T_AsnBeneficiaire,
  isRelative: boolean = false,
): Partial<Patient> => {
  const nir: string = beneficiaire.ident?.nir?.replace(" ", "") || ""
  const birthdateInCard =
    beneficiaire.ident?.naissance?.date ??
    beneficiaire.ident?.naissance?.dateEnCarte
  const patient: Partial<Patient> = {
    nir: nir,
    birthdate: getBirthdateFromNir(birthdateInCard),
    first_birth_firstname: capitalize(beneficiaire.ident?.prenomUsuel?.trim()) || "",
    firstname: capitalize(beneficiaire.ident?.prenomUsuel?.trim()) || "",
    lastname: capitalize(beneficiaire.ident?.nomUsuel?.trim()) || "",
    birth_lastname: capitalize(beneficiaire.ident?.nomPatronymique?.trim()) || capitalize(beneficiaire.ident?.nomUsuel?.trim()) || "",
    gender: !isRelative ? nir && nir[0] === "1" ? Gender.MALE : Gender.FEMALE : null,
    birth_country: nir ? nir.substring(5, 7) === "99" ? `${BirthPlace.FOREIGN}` : `${BirthPlace.FRANCE}` : `${BirthPlace.UNKNOWN}`,
    amo: beneficiaire.amo
      ? { periods: getAMORightsPeriod(beneficiaire.amo) }
      : undefined,
    cmu: beneficiaire.cmu
      ? { periods: getCMURightsPeriod(beneficiaire.cmu) }
      : undefined,
    [InputNames.INSEE_CODE]: isRelative ? null : getInseeCodeFromNir(nir)
  }
  return patient
}

export const getMainBeneficiaire = (
  data: DataCarteVitale
): T_AsnBeneficiaire => {
  let beneficiaire: T_AsnBeneficiaire | T_AsnBeneficiaire[] =
    data.T_AsnDonneesVitale?.listeBenef?.T_AsnBeneficiaire || {}
  if (Array.isArray(beneficiaire)) beneficiaire = beneficiaire[0]
  return beneficiaire
}

export const getRelativesFromDataCarteVtiale = (
  data: DataCarteVitale
): Partial<Patient>[] => {
  const beneficiaires: T_AsnBeneficiaire | T_AsnBeneficiaire[] =
    data.T_AsnDonneesVitale?.listeBenef?.T_AsnBeneficiaire || {}

  if (Array.isArray(beneficiaires)) {
    const relatives: Partial<Relative>[] = []
    beneficiaires.forEach((beneficiaire, key) => {
      if (key !== 0) {
        relatives.push(getPatientFromBeneficiaire(beneficiaire, true))
      }
    })
    return relatives
  } else return []
}

export const isPatientExempted = (patient: Partial<Patient>): boolean => {
  const cmu = patient.cmu
  if (!cmu) return false
  const now = dayjs()
  if (cmu.periods?.end && cmu.periods.end >= now) return true
  return false
}

export const getPatientsFromDataCarteVitale = (
  data: DataCarteVitale
): NirReaderResponse => {
  return {
    patient: getPatientFromDataCarteVitale(data),
    relatives: getRelativesFromDataCarteVtiale(data),
  }
}

const getCMURightsPeriod = (cmu: Cmu): RightsPeriod | undefined => {
  if (cmu?.periode?.fin) return { end: nirDateToDate(cmu?.periode?.fin) }
  return undefined
}
const getAMORightsPeriod = (amo: Amo): RightsPeriod => {
  return {
    start: amo?.listePeriodesDroits?.T_AsnPeriode?.debut
      ? nirDateToDate(amo.listePeriodesDroits.T_AsnPeriode.debut)
      : undefined,
    end: amo?.listePeriodesDroits?.T_AsnPeriode?.fin
      ? nirDateToDate(amo.listePeriodesDroits.T_AsnPeriode.fin)
      : undefined,
  }
}

const nirDateToDate = (nirDate: string): Dayjs | undefined => {
  // nirDate = "" || nirDate =
  if (nirDate.length === 6) {
    // AAMMJJ
    return dayjs(nirDate, "YYMMDD")
  } else if (nirDate.length === 8) {
    // JJMMAAAA
    return dayjs(nirDate, "DDMMYYYY")
  } else return undefined
}

const getBirthdateFromNir = (date: string | undefined): Dayjs | undefined => {
  if (!date) return undefined
  let day,
    month,
    year = ""
  if (date.length === 6) {
    day = date.substring(4, 6)
    month = date.substring(2, 4)
    // Get Century
    const yy = parseInt(date.substr(0, 2))
    year = century(yy).toString()
  }
  if (date.length === 8) {
    day = date.substring(0, 2)
    month = date.substring(2, 4)
    year = date.substring(4, 8)
  }
  const birthdate: Dayjs = dayjs(`${day}/${month}/${year}`, DATE_FORMAT)
  return birthdate
}
