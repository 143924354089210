/* eslint-disable no-underscore-dangle */
import dayjs, { Dayjs } from 'dayjs';
import { omit } from 'lodash';

import { Customer, Prospect, Relative } from 'types/entity';
import { CustomerApi, RelativeApi } from 'types/payload';

import { API_DATE_FORMAT, capitalize, DATE_FORMAT, formatInsString } from './utils';

/*
 * Just say please
 *
 *
 * (.. and conform the objects that you think are more in line with the front :) .)
 * 
 * @TODO Since it's impossible (at least I don't see how) to retrieve the 
 * "from" and "to" types, I'd better pass "api" "app" instead. 
 * ex. if (from === "api") then to = "app" 
 */

export const dateForApi = (d: Dayjs): string | undefined => {
    if (!d) return undefined;
    if (!d.format) return undefined;
    return d.format(DATE_FORMAT);
};
export const dateFromApi = (d: string): Dayjs | undefined => {
    if (!d) return undefined;
    return dayjs(d, API_DATE_FORMAT, true);
};

const instanceOfProspectApp = (object: any): object is Prospect => ('password' in object);
const instanceOfCustomerApi = (object: any): object is CustomerApi => !('password' in object) && ('is_verified' in object) && ('sex' in object);
const instanceOfCustomerApp = (object: any): object is Customer => !('password' in object) && ('is_verified' in object) && ('gender' in object);
const instanceOfRelativeApi = (object: any): object is RelativeApi => !('password' in object) && !('is_verified' in object) && ('sex' in object);
const instanceOfRelativeApp = (object: any): object is Relative => !('password' in object) && !('is_verified' in object) && ('gender' in object);

const isGenericCustomerApp = <T>(object: T): boolean => (
    instanceOfProspectApp(object)
    || instanceOfRelativeApp(object)
    || instanceOfCustomerApp(object));

const isGenericCustomerApi = <T>(object: T): boolean => (
    instanceOfCustomerApi(object)
    || instanceOfRelativeApi(object));

const isGenericCustomer = <T>(object: T): boolean => {
    if (isGenericCustomerApp(object) || isGenericCustomerApi(object)) return true;
    return false;
};

class Compliance<From, To> {
    private _result: To | undefined = undefined;

    public please = (): To | undefined => this._result;

    constructor(unformatted: From) {
        const unformattedCapitalized = this.capitalizor<From>(unformatted);
        if (isGenericCustomer(unformattedCapitalized)) { // Customer / Relative / Prospect
            this._result = this.transform.customer(unformattedCapitalized);
        } else if ((unformatted as unknown as Customer).birthdate) {
            this._result = {
                ...unformatted as unknown as To,
                birthdate: (
                    typeof (unformatted as unknown as Customer).birthdate === 'string'
                        ? dateFromApi((unformatted as unknown as CustomerApi).birthdate)
                        : dateForApi((unformatted as unknown as Customer).birthdate)
                ),
                password_expiration_date: (typeof (unformatted as unknown as Customer).password_expiration_date === "string"
                    ? dateFromApi((unformatted as unknown as CustomerApi).password_expiration_date)
                    : null),
            };
        } else if ((unformatted as unknown as Customer)['old-password']) {
            this._result = {
                ...omit(unformatted as any, ['old-password']) as unknown as To,
                old_password: (unformatted as unknown as Customer)['old-password'] ?? undefined
            };
        } else this._result = unformattedCapitalized as unknown as To;
    }

    // eslint-disable-next-line class-methods-use-this
    private capitalizor<T>(obj: any) {
        const capitalized = {
            ...obj,
            firstname: obj.firstname && capitalize(obj.firstname),
            lastname: obj.lastname && capitalize(obj.lastname),
            email: obj.email && (obj.email as string).toLowerCase()
        };
        return capitalized as T;
    }

    transform = {
        customer: (unformatted: From): To | undefined => {
            if (isGenericCustomerApi(unformatted)) {
                // change CustomerApi into CustomerApp
                return {
                    ...omit<CustomerApi>(unformatted as unknown as CustomerApi, ['sex', 'birthdate']) as To,
                    gender: (unformatted as unknown as CustomerApi).sex,
                    birthdate: dateFromApi((unformatted as unknown as CustomerApi).birthdate),
                    password_expiration_date: (typeof (unformatted as unknown as Customer).password_expiration_date === "string"
                        ? dateFromApi((unformatted as unknown as CustomerApi).password_expiration_date)
                        : null),
                    old_password: (unformatted as unknown as Customer)['old-password'] ?? undefined
                } as To;
            }
            if (isGenericCustomerApp(unformatted)) {
                // change customerApp into customerApi
                return {
                    ...omit<Customer>(unformatted as unknown as Customer, ['gender', 'birthdate', 'old-password']) as To,
                    sex: (unformatted as unknown as Customer).gender,
                    birthdate: dateForApi((unformatted as unknown as Customer).birthdate),
                    old_password: (unformatted as unknown as Customer)['old-password'] ?? undefined
                } as To;
            }

            // Cannot comply the interface
            return unformatted as unknown as To;
        }
    };
}

export const formatCustomerINSPayload = (payload) => {
    const { insee_code, birth_country, birth_location, old_password, ...rest } = payload;
    return {
        ...rest,
        birth_location: insee_code,
        ...(rest.first_birth_firstname ? { first_birth_firstname: formatInsString(rest.first_birth_firstname) } : null),
        ...(rest.birth_lastname ? { birth_lastname: formatInsString(rest.birth_lastname) } : null),
        firstname: rest.firstname ? rest.firstname : rest.first_birth_firstname,
        lastname: rest.lastname ? rest.lastname : rest.birth_lastname,
    }
}

export default Compliance;
