import { connect } from "react-redux"
import { ActionWithoutPayload } from "types/redux"
import { StatisticsStore } from "types/store"
import { actions } from "./services/actions"
import { Statistics as StatisticsInterface } from "types/payload"
import React, { useEffect } from "react"
import { HOME_PAGE } from "core/constants"
import PreviousButton from "components/Previous/PreviousButton"
import { languages } from "lib/languages"
import Loader from "components/Loaders/Loader/Loader"
import { Buttons, Card } from "components/Card/Card"

import FatButton from "components/Button/FatButton/FatButton"
interface StatisticsProps {
  statistics: StatisticsInterface
  getStatistics: () => void
}

const Statistics: React.FC<StatisticsProps> = (props): JSX.Element => {
  useEffect(() => {
    if (!props.statistics) props.getStatistics()
  })

  return (
    <Card title={languages.tlcMade}>
      <PreviousButton to={HOME_PAGE} />
      {!props.statistics ? (
        <Loader />
      ) : (
        <div>
          <Buttons>
            <FatButton>
              <div><b>{languages.daily} :</b> <br/> {props.statistics.tlcs.daily}</div>
            </FatButton>
            <FatButton>
              <div><b>{languages.weekly} :</b> <br/> {props.statistics.tlcs.weekly}</div>
            </FatButton>
            <FatButton>
              <div><b>{languages.monthly} :</b> <br/> {props.statistics.tlcs.monthly}</div>
            </FatButton>
            <FatButton>
              <div><b>{languages.yearly} :</b> <br/> {props.statistics.tlcs.yearly}</div>
            </FatButton>
          </Buttons>
          <Buttons>
            <FatButton>
              <div><b>{languages.total} :</b> <br/> {props.statistics.tlcs.total}</div>
            </FatButton>
          </Buttons>
        </div>
      )}
    </Card>
  )
}

const mapStateToProps = (state: {
  statistics: StatisticsStore
}): Omit<StatisticsProps, "getStatistics"> => {
  return { ...state.statistics }
}

const mapDispatchToProps = (
  dispatch: (e: ActionWithoutPayload) => void
): Omit<StatisticsProps, "statistics"> => {
  return {
    getStatistics: () => dispatch(actions.getStatisticsRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics)
