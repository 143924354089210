import { call, put, select, takeLatest } from "@redux-saga/core/effects"
import config from "react-global-configuration"
import { all, takeLeading } from "redux-saga/effects"
import { w3cwebsocket as W3CWebSocket } from "websocket"

import { formatReaderSocketErrorByType } from "lib/api-errors"
import { getPatientsFromDataCarteVitale } from "lib/sesam"
import {
  Action,
  ActionWithoutPayload,
  GFlow,
  GWatcher,
  Message,
} from "types/redux"
import { DataCarteVitale } from "types/sesam"
import { GlobalStore, NirReaderResponse, SocketStore } from "types/store"

import { SOCKET_NIR_READER_MANAGER } from "../constants"
import { ACTION_WEBSOCKET_TYPE } from "../optic/contants"
import { initSocket, sendMessage } from "../saga"
import { ReadyState, SocketPayload } from "../utils"
import {
  errorCardSocketNirReader,
  removeCardSocketNirReader,
  removedCardSocketNirReader,
  resetSocketNirReader,
  socketNirReaderResponse,
} from "./actions"
import {
  SOCKET_CANCEL_CARD_VITALE_READER,
  SOCKET_INIT_CARD_VITALE_READER,
  SOCKET_LAUNCH_CARD_VITALE_READER,
} from "./constants"

function* handleSocketErrorMsg(action: ActionWithoutPayload) {
  const translatedError = formatReaderSocketErrorByType(action.type)
  yield put(errorCardSocketNirReader(translatedError))
  return
}

export function* readerSocketManager(
  action: Action<SocketPayload>
): GFlow<Action<NirReaderResponse | Message>> {
  const webSocketAction = action.payload
  const { type, result, payload } = webSocketAction
  if (result === "KO") {
    yield handleSocketErrorMsg(webSocketAction.payload as ActionWithoutPayload)
  }
  if (payload.type === "REMOVE_TIMEOUT") {
    yield put(removedCardSocketNirReader())
  }
  if (result === "TIMEOUT") {
    yield handleSocketErrorMsg(webSocketAction.payload as ActionWithoutPayload)
  } else if (
    result === "OK" &&
    type === "get" &&
    (payload as Action<DataCarteVitale>).payload
  ) {
    const response: NirReaderResponse = yield getPatientsFromDataCarteVitale(
      (payload as Action<DataCarteVitale>).payload
    )
    yield put(socketNirReaderResponse(response as NirReaderResponse))
  } else {
    if (payload.type === "REMOVE") {
      yield put(removeCardSocketNirReader())
    } else if (payload.type === "REMOVED") {
      yield put(removedCardSocketNirReader())
    }
  }
}

function* launchReaderCard(): GFlow<ActionWithoutPayload> {
  const client: W3CWebSocket | undefined = yield call(initSocket)
  const stopLookingForClient = yield select(
    ({ socket }: GlobalStore) => socket.stop || false
  )
  if (stopLookingForClient) return yield all([put(resetSocketNirReader())])
  if (!client)
    return yield put(
      errorCardSocketNirReader(
        "Nous ne parvenons pas à lancer le lecteur de carte"
      )
    )
  try {
    if (client.readyState !== ReadyState.OPEN) {
      console.warn("[launchReaderCard] client not ready")
      yield put(errorCardSocketNirReader("impossible de lancer le lecteur"))
    } else {
      console.info("[launchReaderCard] SEND carte_vitale")
      yield sendMessage({
        type: "carte_vitale",
        action: ACTION_WEBSOCKET_TYPE.GET,
        body: {
          timeout: config.get("timeoutNirReaderInS"),
        },
      })
      yield put({ type: SOCKET_LAUNCH_CARD_VITALE_READER })
    }
  } catch (e) {
    console.warn("[launchReaderCard] error", e)
    yield put(errorCardSocketNirReader("[launchReaderCard] error"))
    console.error(e, {
      route: "wss::launchReaderCard"
    })
  }
}

function* cancelReaderCard() {
  console.info("---- CANCEL vital card READER ----")
  yield 0
}

export default function* nirSocketSaga(): GWatcher {
  yield takeLatest(SOCKET_INIT_CARD_VITALE_READER, launchReaderCard)
  yield takeLatest(SOCKET_CANCEL_CARD_VITALE_READER, cancelReaderCard)
  yield takeLeading(SOCKET_NIR_READER_MANAGER, readerSocketManager)
}
