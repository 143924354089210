import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { languages } from "lib/languages"
import { getNameOfCurrentInput } from "components/Form/utils"
import { Prospect } from "types/entity"
import { useDispatch, useSelector } from "react-redux"
import { InputFormProps } from "types/props"
import profileActions from "containers/Profile/services/actions"
import { FormPage } from "components/Form/Form"
import { GenericModal } from "./Modal"
import { Buttons, Card } from "components/Card/Card"
import { cdn } from "core/cdn"
import DOMPurify from "dompurify"
import { Button } from "components/Button"
import { LOGOUT } from "containers/Login/services/constants"
import Spacer from "components/Spacer"
import { GlobalStore } from "types/store"
import Message from "components/Message/Message"
enum PASSWORD_STEPS {
    ADVICE_PASSWORD = "ADVICE_PASSWORD",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",

}

export const ChangePasswordModal = (props) => {

    // patient / onFinish / onCancel
    const [step, setStep] = useState(PASSWORD_STEPS.ADVICE_PASSWORD)
    const profileStore = useSelector((states: GlobalStore) => states.profile)
    const [cancelable, setCancelable] = useState(true)
    const [daysBeforeExpiration, setDaysBeforeExpiration] = useState(0)
    const [description, setDescription] = useState("Changer votre mot de passe")
    const dispatch = useDispatch()
    const inputs: InputFormProps[] = [
        {
            name: "old-password",
            required: true,
            label: languages.InputYourOldPassword,
        },
        {
            name: "new-password",
            required: true,
            label:
                props.label || languages.modifyYour(getNameOfCurrentInput("new-password")),
        },
    ]

    useEffect(() => {
        if (profileStore.success) {
            dispatch(profileActions.reset())
            props.onFinish()
        }
    }, [profileStore.success])

    useEffect(() => {
        const now = dayjs()
        const daysDiff = (props.patient.password_expiration_date).diff(now, "days") + 1
        const daysSince = dayjs(props.patient.password_reset_at).diff(now, "days") - 1
        setDaysBeforeExpiration(daysDiff > 0 ? daysDiff : daysSince)
        if (daysDiff <= 0) {
            // Forcer le changement de mot de passe
            setCancelable(false)
        }
        else if (daysDiff <= 7) {
            // Propose le changement de mot de passe
            setCancelable(true)
        }
    }, [])
    const handleFinished = (values: Partial<Prospect>) => {
        dispatch(profileActions.changePatientRequest(values))
    }

    return <GenericModal
        visible={true}
        noFooter={true}
        style={{ width: "95vw" }}
    >
        <div style={{ padding: "30px" }}>
            <Message message={profileStore.message} />
            {step === PASSWORD_STEPS.ADVICE_PASSWORD &&
                <DescriptionChangePassword
                    daysBeforeExpiration={daysBeforeExpiration}
                    cancelable={cancelable}
                    onCancel={() => {
                        dispatch(profileActions.reset())
                        props.onCancel()
                    }}
                    onAccept={() => setStep(PASSWORD_STEPS.CHANGE_PASSWORD)} />}

            {step === PASSWORD_STEPS.CHANGE_PASSWORD &&
                <FormPage
                    onCancel={() => {
                        setStep(PASSWORD_STEPS.ADVICE_PASSWORD)
                    }}
                    acceptText={languages.modify}
                    loading={profileStore.loading}
                    inputs={inputs}
                    onFinish={handleFinished}
                />}
        </div>
    </GenericModal>
}

const DescriptionChangePassword = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    return <Card>
        <Spacer direction="vertical" size={50}>
            <div className="flex-center">
                <img height="132" src={props.cancelable ? cdn("images/warning_picto.svg") : cdn("images/caution_picto.svg")} alt="" />
            </div>
            <span className="flex-center font-size-xlarge" dangerouslySetInnerHTML={{
                __html: (DOMPurify.sanitize(languages.passwordHasNotBeenChangedSince_html(props.daysBeforeExpiration)))
            }} />
            {props.cancelable ?
                <Buttons>
                    <Button type="default" onClick={() => props.onCancel()} >
                        {languages.later}
                    </Button>
                    <Button type="primary" onClick={() => props.onAccept()}>
                        {languages.modifyPassword}
                    </Button>
                </Buttons>
                :
                <Buttons direction="vertical">
                    <Button loading={loading} disabled={loading} type="primary" onClick={() => props.onAccept()} >
                        {languages.modifyPassword}
                    </Button>
                    <Button loading={loading} disabled={loading} danger type="link" onClick={() => {

                        setLoading(true)
                        dispatch({ type: LOGOUT })
                    }}>
                        {languages.logout}
                    </Button>
                </Buttons>}
        </Spacer>
    </Card >
}