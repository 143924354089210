import React, { useEffect, useState } from "react";
import { Carousel } from "components/Carousel/Index"

import { INSERT_RELATIVE_PAGE }  from "core/constants";
import { Input } from "components/Input"
import { InputNames } from "types/props";
import { BirthPlace } from "types/entity";

import config from "react-global-configuration";
import FatButton from "components/Button/FatButton/FatButton";
import { languages } from "lib/languages";
import searchCityFullNameWithInseeCode from "api/SearchBirthLocationName"
import searchLocation, {getDefaultLocalisationList} from "api/SearchLoaction"

export const getTitleByCountry = (pathname, country) => {
  const isRelative = pathname === INSERT_RELATIVE_PAGE;
  switch(`${country}`) {
    case `${BirthPlace.FRANCE}`:
      return isRelative ? languages.inputYourRelativeBirthCity :  languages.inputYourBirthCity ;
    case `${BirthPlace.FOREIGN}`:
      return isRelative ? languages.inputYourRelativeBirthCountry : languages.inputYourBirthCountry;
    case `${BirthPlace.UNKNOWN}`:
      return languages.unknownBirthPlace;
    default:
      return languages.unknownBirthPlace;
  }
}

const UNKNOWN_INSEE_CODE = "99999";

const getSelectedCountry = (formRef: any) => (formRef && formRef.getFieldValue(InputNames.BIRTH_COUNTRY)) || `${BirthPlace.FRANCE}`

const onLocationClicked = (inseeCode, fullname_with_suffix, props) => {
  props.formRef.setFieldsValue({[InputNames.BIRTH_LOCATION]: fullname_with_suffix, [InputNames.INSEE_CODE]: inseeCode  })
  props.onChange(fullname_with_suffix);
}

const setSelectedCountry = (props: any, country: string) => { 
  props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_COUNTRY, country);
  props.changeTitle(getTitleByCountry(window.location.pathname, country));
  if(country === `${BirthPlace.UNKNOWN}`) {
    props.formRef.setFieldsValue({[InputNames.BIRTH_LOCATION]: languages.unknown, [InputNames.INSEE_CODE]: UNKNOWN_INSEE_CODE})
  }
};

const BirthLocation = (props: any) => {
    const [locationList, setLocationList] = useState(getDefaultLocalisationList());
    useEffect(() => {
      // Si le code INSEE est pré-remplis depuis la carte vitale
      // Nous allons chercher la ville associée
      // Et injecter sa valeur dans l'input
      const inseeCode = props.formRef && props.formRef.getFieldValue(InputNames.INSEE_CODE);
      if(inseeCode === UNKNOWN_INSEE_CODE) {
        props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, languages.unknown);
      } else if(inseeCode) {
        try {
          searchCityFullNameWithInseeCode(inseeCode, props.formRef && props.formRef.getFieldValue(InputNames.BIRTHDATE))
          .then((res: any) => {
            if(res && res.status === "ok") {
              if(inseeCode.slice(0,2) === "99") {
                setSelectedCountry(props, `${BirthPlace.FOREIGN}`)
              }
              props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, res?.locations?.[0]?.fullname_with_suffix);
              props.formRef && props.formRef.setFieldValue(InputNames.INSEE_CODE, inseeCode);
              props.onChange(res?.locations?.[0]?.fullname_with_suffix);
            } else {
              props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, languages.unknown);
              props.formRef && props.formRef.setFieldValue(InputNames.INSEE_CODE, UNKNOWN_INSEE_CODE);
              props.onChange();
            }
            setLocationList([]);
          })
          return;
        } catch(error) {
          console.error(error, {
            route: config.get("locations")
          });
          props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, languages.unknown);
        }
      }
    }, []);

    useEffect(() => {
      // DO NOT TRIGGER SEARCH AFTER CITY SELECTION
      if(/[(][0-9]{1,}[)]/.test(props.value)) {
        return;
      }
        let launchRequest = setTimeout(() => {
            if(!props.value || props.value === "") {
              setLocationList(getDefaultLocalisationList());
              props.formRef && props.formRef.setFieldsValue({ [InputNames.INSEE_CODE]: ""  })
            }
            props.value && searchLocation(props.formRef && props.formRef.getFieldValue(InputNames.BIRTHDATE), getSelectedCountry(props.formRef), props.value).then((cityFound: any) => {
                setLocationList(cityFound);
            })
        }, 500);
        return () => clearTimeout(launchRequest);
    }, [props.value]);

    useEffect(() => {
      if(!props.value || props.value==="") {
        setLocationList(getDefaultLocalisationList());
        props.formRef && props.formRef.setFieldsValue({ [InputNames.INSEE_CODE]: ""  })
      }

    }, [props.formRef && props.formRef.getFieldValue(InputNames.BIRTH_COUNTRY)])


    return (
      props.disabled ?
      <Input  {...(props as unknown as any)} value={!props.value? languages.not_informed_singular : props.value}/>
      :
      <div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            <div><input style={{width: "1250px"}} type="text" name="birth_location" value={props.value} readOnly /> </div>
          </div>
          <div style={{height: "90px"}}>
            {locationList.length > 0 && (
              <Carousel
                itemsPerPage={3}
                items={locationList.map((location) => (
                    <FatButton style={{height: "90px", fontSize: "25px" }} onClick={() => onLocationClicked(location.insee_code, location.fullname_with_suffix, props)}><div style={{display: "flex", flexDirection: "column", alignContent: "space-evenly", textAlign: "center" }}> <span
                    style={{
                      maxHeight: "90px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "250px",
                    }}>{location.fullname_with_suffix}</span> </div></FatButton>
                ))}
                infinite={false}
                style={{height: "auto", width: "auto"}}
              />
            )}
          </div>
    </div>
    )
}


export default BirthLocation;