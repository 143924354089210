import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import { Card } from "components/Card/Card"
import { UiCarousel } from "components/Carousel/Index"
import { UiFooter } from "components/Carousel/Ui"
import Loader from "components/Loaders/Loader/Loader"
import { GenericModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { InsertCarerModal } from "containers/InsertCarer"
import { FINALE_PAGE, PROFILE_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { PATH } from "models/survey/_paths"
import { FOR_PATIENT } from "models/survey/constants"
import { Carer } from "types/entity"
import { Dispatcher } from "types/redux"
import { SurveyStore } from "types/store"

import actions from "./services/actions"
import { getCurrentSelectedPatientId } from "./services/selector"
import {
  ACTION,
  Answer,
  QUESTION_TYPE,
  Reply,
  SurveyProps,
  Value,
} from "./types"

const DefaultReply = (props: { reply: Reply; onSelect: () => void }) => {
  return (
    <Button onClick={props.onSelect} wide="long" type={props.reply?.ui?.type}>
      {props.reply.title}
    </Button>
  )
}

const Index: React.FC<SurveyProps> = (props): JSX.Element => {
  const { current: question, answer: selectedItems, status } = props
  const [tooYoungModalVisible, setTooYoungModalVisible] = useState(false)
  const [insertCarerModalVisible, setInsertCarerModalVisible] = useState(false)
  const currentPatientId = useSelector(getCurrentSelectedPatientId)
  const navigate = useNavigate()

  useEffect(() => {
    if (props.current.value && question.type === QUESTION_TYPE.redirect) {
      navigate(props.current.value)
    }
  }, [props.current])

  useEffect(() => {
    switch (props.status) {
      case "finish":
        return navigate(FINALE_PAGE)
      case "cancel":
        props.reset()
        return navigate(PROFILE_PAGE)
      case "on_going":
        break
    }
  }, [props.status])

  if (!question) return <Loader />

  const next = () => {
    props.next()
  }

  const onSelect = (r: Reply) => {
    if (r.action === ACTION.redirect) {
      navigate(r.value as string)
      return
    }
    if (r.action === ACTION.inability) {
      if (r.value === "relative_too_young") {
        setTooYoungModalVisible(true)
      }
      return
    }
    if (!question.multipleReplies) {
      const oldValues = question.replies.filter((old) => old.value !== r.value)
      props.unset(oldValues)
      props.set([r])
      next()
      return
    }
    props.set([r])
  }

  const unSelect = (r: Reply) => {
    if (!question.multipleReplies) {
      const oldValue = question.replies.filter((old) => old.value === r.value)
      if (oldValue) {
        props.unset([r])
        props.set([r])
        next()
      }
    } else {
      props.unset([r])
    }
  }

  return (
    <>
      <PreviousButton onClick={props.previous} />
      <Card
        title={question.title}
        subtitle={question.subtitle}
        imgUrl={question.imgUrl}
      >
        {question.replies.length ? (
          <UiCarousel
            items={question.replies}
            selected={selectedItems}
            onSelect={onSelect}
            unSelect={unSelect}
            ui={question.ui}
          />
        ) : null}
        {question.informationText && (
          <div className="information-card">{question.informationText}</div>
        )}
        <UiFooter
          style={question.id === PATH.inform_anamnesis && { paddingTop: 0 }}
          nextVisible={!!question.multipleReplies}
          nextDisabled={!selectedItems.length}
          onFinish={props.next}
        >
          {question.defaultReply && (
            <DefaultReply
              reply={question.defaultReply}
              onSelect={() => {
                // default reply pass on the next direct
                onSelect(question.defaultReply as Reply)
              }}
            />
          )}
        </UiFooter>
        {question.id === PATH.inform_anamnesis && (
          <div>
            <span
              className="small-link"
              onClick={() => setInsertCarerModalVisible(true)}
            >
              {currentPatientId === FOR_PATIENT
                ? languages.ImCaredForThisTlc
                : languages.ImCaredForThisTlc_relative}
            </span>
            <InsertCarerModal
              visible={insertCarerModalVisible}
              onFinish={(carer: Carer) => {
                setInsertCarerModalVisible(false)
                next()
              }}
              onCancel={() => setInsertCarerModalVisible(false)}
            />
          </div>
        )}
        <GenericModal
          visible={tooYoungModalVisible}
          title={languages.notSuitableTLC}
          acceptText={languages.IGotIt}
          onAccept={() => {
            setTooYoungModalVisible(false)
          }}
          contentText={languages.notSuitableTLCContent}
        />
      </Card>
    </>
  )
}

const mapStateToProps = ({ survey }: { survey: SurveyStore }): SurveyProps => {
  const current = survey.current
  const answer: Value[] =
    survey.answers.find(({ questionId }: Answer) => questionId === current?.id)
      ?.value || []

  return {
    current,
    answer,
    status: survey.status,
    answers: survey.answers,
    questions: survey.questions,
  } as SurveyProps
}

const mapDispatchToProps = (dispatch: Dispatcher): Partial<SurveyProps> => {
  return {
    set: (replies_to_add: Reply[]) =>
      dispatch(actions.setStepReplies({ replies: replies_to_add })),
    unset: (replies_to_remove?: Reply[]) =>
      dispatch(actions.unsetStepReplies({ replies: replies_to_remove })),
    next: () => dispatch(actions.next()),
    previous: () => dispatch(actions.previous()),
    reset: () => dispatch(actions.reset()),
    cancel: () => dispatch(actions.cancel()),
    submit: (a: Answer[]) => dispatch(actions.submit(a)),
  } as Partial<SurveyProps>
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
