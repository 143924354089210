export const DIAGNOSTIC = '/diagnostic';
export const HOME_PAGE = '/home';
export const SURVEY_PAGE = '/survey';
export const ADMIN_PAGE = '/admin';
export const VERIFY_PAGE = '/verify';
export const LOBBY_PAGE = '/lobby';
export const LOGIN_PAGE = '/login';
export const FINALE_PAGE = '/print';
export const REGISTER_PAGE = '/register';
export const NOT_AUTHORIZED_PAGE = '/not-authorized';
export const INSERT_RELATIVE_PAGE = '/relative';
export const CALL_PAGE = '/call';
export const PAYMENT_PAGE = '/payment';
export const PROFILE_PAGE = '/profile';
export const VERIFY_PHONE_PAGE = '/verify';
export const STATISTICS_PAGE = '/statistics';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const NIR_AND_EXEMPTION_PAGE = '/nir-and-rights';
export const MODIFY_RELATIVE_PAGE = '/modify-relative';
export const GET_DOCTOR_PAGE = '/doctors';
export const NO_NETWORK_PAGE = '/bad-network';
export const TIMELINE_PAGE = '/parcours-de-teleconsultation';
export const TUTORIAL_OPTIC_PAGE = '/demonstration-de-la-machine-optique';
export const SET_RELATIVE_PAGE = '/selection-genre-proche';
export const CONSENT_PAGE = '/consent';
export const ADMIN_CARERS_PAGE = '/carers';
