import { all } from "redux-saga/effects"

import configurationSaga from "client/config/saga"
import documentSaga from "client/documents/saga"
import OpticSaga from "client/optic/saga"
import clientSaga from "client/services/saga"
import NirSocketSaga from "client/socket/nir/saga"
import OpticSocketSaga from "client/socket/optic/saga"
import socketSaga from "client/socket/saga"
import nirReaderSaga from "components/NirReader/services/saga"
import adminSaga from "containers/Admin/services/saga"
import callSaga from "containers/Call/services/saga"
import DoctorsSaga from "containers/Doctors/services/saga"
import CarerSaga from "containers/InsertCarer/services/saga"
import insertRelativeSaga from "containers/InsertRelative/services/saga"
import loginSaga from "containers/Login/services/saga"
import paymentSaga from "containers/Payment/services/saga"
import profileSaga from "containers/Profile/services/saga"
import ResetPasswordSaga from "containers/ResetPassword/services/saga"
import SignupSaga from "containers/Signup/services/saga"
import StatisticsSaga from "containers/Statistics/services/saga"
import StripeSaga from "containers/Stripe/services/saga"
import surveySaga from "containers/Survey/services/saga"
import verifyPhoneSaga from "containers/VerifyPhone/services/saga"

export default function* IndexSaga(): Generator {
  yield all([
    clientSaga(),
    socketSaga(),
    OpticSaga(),
    OpticSocketSaga(),
    NirSocketSaga(),
    adminSaga(),
    loginSaga(),
    paymentSaga(),
    documentSaga(),
    nirReaderSaga(),
    insertRelativeSaga(),
    surveySaga(),
    profileSaga(),
    callSaga(),
    SignupSaga(),
    StripeSaga(),
    verifyPhoneSaga(),
    StatisticsSaga(),
    ResetPasswordSaga(),
    configurationSaga(),
    DoctorsSaga(),
    CarerSaga(),
  ])
}
