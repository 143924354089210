export const SOCKET_CANCEL_CARD_VITALE_READER =
  "SOCKET_CANCEL_CARD_VITALE_READER"
export const SOCKET_ERROR_CARD_VITALE_READER = "SOCKET_ERROR_CARD_VITALE_READER"
export const SOCKET_LAUNCH_CARD_VITALE_READER =
  "SOCKET_LAUNCH_CARD_VITALE_READER"
export const SOCKET_REMOVE_CARD_VITALE_READER =
  "SOCKET_REMOVE_CARD_VITALE_READER"
export const SOCKET_REMOVED_CARD_VITALE_READER =
  "SOCKET_REMOVED_CARD_VITALE_READER"
export const SOCKET_RESET_CARD_VITALE_READER = "SOCKET_RESET_CARD_VITALE_READER"
export const SOCKET_INIT_CARD_VITALE_READER = "SOCKET_INIT_CARD_VITALE_READER"
export const SOCKET_NIR_READER_RESPONSE = "SOCKET_NIR_READER_RESPONSE"