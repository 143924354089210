import react from "react"

import { useNavigate } from "react-router-dom"
import { Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { languages } from "lib/languages"
import { capitalize } from "lib/utils"
import { SURVEY_PAGE } from "core/constants"
import { useDispatch, useSelector } from "react-redux"
import {
  getCurrentSelectedPatientId,
  getCurrentPatientFirstName,
} from "containers/Survey/services/selector"
import actions from "containers/Profile/services/actions"
import surveyActions from "containers/Survey/services/actions"

const SetRelativeGender = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentSelectedRelativeId =
    useSelector(getCurrentSelectedPatientId) || props.relativeId
  const currentSelectedRelativeFirstName = useSelector(
    getCurrentPatientFirstName
  )
  const label = `${capitalize(languages.inputThe("gender"))} ${
    currentSelectedRelativeFirstName
      ? `de ${capitalize(currentSelectedRelativeFirstName)}`
      : ""
  }`
  const inputs = [
    {
      name: "gender",
      required: true,
      label: label,
      keepLabelFormat: true,
    },
  ]
  return (
    <Card subtitle={languages.enterRelativeInformations}>
      <FormPage
        loading={false}
        inputs={inputs}
        onFinish={(values) => {
          dispatch(
            actions.changeRelativeRequest({
              id: currentSelectedRelativeId,
              ...values,
            } as any)
          )
          navigate(SURVEY_PAGE)
        }}
        acceptText={languages.next}
        onCancel={() => {
          dispatch(surveyActions.previous())
          navigate(SURVEY_PAGE)
        }}
      />
    </Card>
  )
}

export default SetRelativeGender
