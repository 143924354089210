import { FormInstance, InputProps, } from "antd"

import React, { useEffect, useState } from "react"
import { getCountryCallingCode } from "react-phone-number-input"
import { CountrySelect } from "./CountrySelect"
import styles from "./PhoneInput.module.scss"
import PhoneInput from "react-phone-number-input"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import { omit } from "lodash"

type Country = string
const getCode = (country: Country) => ({
  country,
  code: "+" + getCountryCallingCode(country),
})

const defaultCode = {
  country: "FR",
  code: "+33",
}

const formatPhone = (phone: string) => {
  // phone = +3306XXXXXXXX || +3306XX || +3306
  // expected output = +336XXXXXXXX
  const formatted = formatPhoneNumberIntl(phone)
  if (!formatted) return phone
  return "+" + formatted.replace(/\D/g, "")
}

export interface OurPhoneProps extends Omit<InputProps, "onChange"> {
  initialValue?: string
  onChange?: (phone: string) => void
  formref?: FormInstance
}
interface FlagsProps {
  value?: string
  onChange?: (flag: string) => void
}

const Phone: React.FC<OurPhoneProps> = (props): JSX.Element => {
  const [currentCode, setCurrentCode] = useState<{
    country: Country
    code: string
  }>(defaultCode)
  const [value, setValue] = useState<string>(props.value as string)

  const retrieveCountryFromForm = () => {
    if (props.formref) {
      const countryRetriever =
        props.formref.getFieldValue("country-phone") || defaultCode.country
      const countryHasChanged = currentCode.country !== countryRetriever
      if (countryHasChanged) setCurrentCode(getCode(countryRetriever))
    }
  }

  const handleCountryChanged = (code: { country: Country; code: string }) => {
    setCurrentCode(code)
    props.onChange && props.onChange(code.code)
    // If country has changed, we reset phoneField
    props.formref &&
      props.formref?.setFieldsValue({
        [props.name as string]: code.code,
        ["country-phone"]: code.country,
      })
  }

  const valueHasChanged = (value?: string) => {
    if (!value) return currentCode.code
    if (value.includes("+")) return formatPhone(value) || value
    else return formatPhone(currentCode.code + value)
  }

  useEffect(() => {
    retrieveCountryFromForm()
    const formattedPhone = valueHasChanged(props.value as string)
    setValue(formattedPhone)
    props.onChange && props.onChange(formattedPhone)
  }, [props.value])

  return (
    <PhoneInput
      className={styles.PhoneInput}
      countrySelectComponent={(countryProps: {
        onChange?: (e: string | undefined) => void
      }) => {
        return (
          <CountrySelect
            disabled={props.disabled}
            value={currentCode.country}
            onChange={(country?: Country) => {
              country && handleCountryChanged(getCode(country))
            }}
          />
        )
      }}
      defaultCountry={currentCode.country}
      {...omit(props, "formref")}
      value={props.value}
    />
  )
}

export default Phone
