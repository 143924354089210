import { call, put, takeLatest } from "redux-saga/effects"
import { DOCUMENTS_REQUEST, SEND_DOCUMENTS_REQUEST } from "./constants"
import { actions } from "./actions"
import { DocumentType } from "./types"
import config from "react-global-configuration"

import { request } from "lib/request"
import { Action, GFlow, GWatcher, Message } from "types/redux"
import { getMergedBlob } from "lib/pdf-merger"

const apiSendDocuments = async ({ id }: { id: number }) => {
  return await request(`${config.get("admin.sendDocuments")}/${id}`, {
    method: "POST",
  })
}
const getUrlDocument = (documentType: DocumentType) => {
  switch (documentType) {
    case DocumentType.REPORT:
      return config.get("patient.get.document.report")
    case DocumentType.WORKLEAVE:
      return config.get("patient.get.document.workleave")
    case DocumentType.CERTIFICATE:
      return config.get("patient.get.document.certificate")
    case DocumentType.PRESCRIPTION:
    default:
      return config.get("patient.get.document.prescription")
  }
}

async function getDocumentBlobApi(
  callId: string,
  documentUrl: string
): Promise<Blob> {
  return await request<Blob>(`${documentUrl}/${callId}`, {
    // @TODO APIside : Change POST to GET
    method: "POST",
    responseFormat: "blob",
  })
}

async function getMergedDocumentsBlobApi(callId: string): Promise<Blob> {
  return await request<Blob>(`/calls/documents/${callId}`, {
    method: "GET",
    responseFormat: "blob",
  })
}

function* sendDocumentsFlow(action: Action<{ id: number }>) {
  try {
    yield call(apiSendDocuments, action.payload)
    yield put(
      actions.sendDocumentsSuccess("Les documents ont bien été envoyés !")
    )
  } catch (e) {
    yield put(
      actions.sendDocumentsError("Les documents n'ont pas pu être envoyé")
    )
    console.error(e, {route: `${config.get("admin.sendDocuments")}/${action.payload}`})
  }
}

async function getMergedDocumentsBlob(
  callId: string,
  documentsType: DocumentType[]
) {
  const promises = documentsType.map((document) => {
    const id = callId
    const documentUrl = getUrlDocument(document)
    return getDocumentBlobApi(id, documentUrl)
  })
  const blobs: Blob[] = await Promise.all(promises)

  return await getMergedBlob(blobs)
}

function* documentsFlow(
  action: Action<{ documentsType: DocumentType[]; callId: string }>
): GFlow<Action<Message>> {
  const { documentsType, callId } = action.payload
  try {
    const blob = yield documentsType
      ? getMergedDocumentsBlob(callId, documentsType)
      : getMergedDocumentsBlobApi(callId)

    let documentUrl: string = window.URL.createObjectURL(blob)

    yield put(actions.success(documentUrl))
  } catch (e) {
    yield put(actions.error(e as string))
    console.error(e, {
      route: "documentsFlow::getMergedDocumentsBlob"
    })
  }
}

function* documentsWatcher(): GWatcher {
  yield takeLatest(DOCUMENTS_REQUEST, documentsFlow)
  yield takeLatest(SEND_DOCUMENTS_REQUEST, sendDocumentsFlow)
}

export default documentsWatcher
