import React, {
  createContext,
  ReactNode,
  RefObject,
  useRef,
  useState,
} from "react"
import config from "react-global-configuration"
import { connect } from "react-redux"
import {
  connect as connectTwilio,
  LocalTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from "twilio-video"

import { ActionWithoutPayload } from "types/redux"

import { getCall } from "../services/actions"
import {
  RoomState,
} from "../types/types"
import { createUserLocalTracks } from "../utils"
import styles from "./../Call.module.scss"

export type ConnectParams = {
  tool_token?: string
  token: string
  roomName: string
}

export type VideoContextProps = {
  connect: (params: ConnectParams) => void
  disconnect: () => void
  connected: boolean
  state: RoomState
  children?: ReactNode
}

const initialProps = {
  state: RoomState.ROOM_CONNECTION,
  connected: false,
}

const VideoContext = createContext<VideoContextProps>(
  initialProps as VideoContextProps
)
const VideoProvider = (
  props: React.PropsWithChildren<{ getCurrentCall: () => void }>
) => {
  const [state, setState] = useState(RoomState.ROOM_CONNECTION)
  const [connected, setConnected] = useState(false)
  const [room, setRoom] = useState<Room>()
  const localMedia = useRef<HTMLDivElement>(null)
  const remoteMedia = useRef<HTMLDivElement>(null)

  const [toolParticipant, setToolParticipant] = useState(null)

  const connectTools = async ({
    roomName,
    tool_token,
  }: {
    roomName: string
    tool_token: string
  }) => {
    console.log("connecting tool to the room...", roomName)
    return connectTwilio(tool_token, {
      name: roomName,
      tracks: [],
    })
      .then((room) => {
        setToolParticipant(room.localParticipant)
      })
      .catch((e) => console.error(e, {
        route: "twilio::connectTools::connectTwilio"
      }))
  }

  const connect = async ({ token, tool_token, roomName }: ConnectParams) => {
    console.log("connecting to the room...", roomName)
    setConnected(true)
    setState(RoomState.ROOM_CONNECTING)

    tool_token && (await connectTools({ tool_token, roomName }))

    return await createUserLocalTracks()
      .then((localTracks: Array<LocalTrack>) => {
        const videoTrack = localTracks.find((x) => x.kind === "video")
        trackSubscribed(videoTrack as LocalVideoTrack, localMedia)
        return connectTwilio(token, {
          name: roomName,
          tracks: localTracks,
        })
      })
      .then((room) => {
        roomEvent(room)
      })
      .catch((e) => {
        console.error(e, {
          route: "twilio::connect::createUserLocalTracks"
        })
      })
  }

  const disconnect = () => {
    setConnected(false)
    room?.disconnect()
  }

  const roomEvent = (_room: Room) => {
    setRoom(_room)
    setState(RoomState.WAITING_PEER)

    _room.participants.forEach(participantEvent)

    _room.on("participantConnected", participantEvent)

    _room.on("participantDisconnected", (participant) => {
      console.warn(`Participant "${participant.identity}" disconnected`)
    })

    _room.once("disconnected", (room, error) => {
      console.warn(`[Bye bye] Room disconnected`)
      room.localParticipant.tracks.forEach(
        (publication: LocalTrackPublication) => {
          if (
            publication.track.kind === "audio" ||
            publication.track.kind === "video"
          ) {
            publication.track.stop()
            const attachedElements = publication.track.detach()
            attachedElements.forEach((element) => element.remove())
          }
          if (error) {
            console.log(
              "You were disconnected from the Room:",
              error.code,
              error.message
            )
          }
        }
      )
      disconnect()
      props.getCurrentCall()
    })
  }

  const participantEvent = (participant: Participant) => {
    console.warn(`Participant "${participant.identity}" connected`)

    participant.on(
      "trackSubscribed",
      (track: RemoteVideoTrack | RemoteAudioTrack) =>
        participant.identity.includes("doctor") &&
        trackSubscribed(track, remoteMedia)
    )

    participant.on("trackUnsubscribed", (e) => trackUnsubscribed(e))
  }

  const trackSubscribed = (
    track: RemoteTrack | LocalTrack,
    div: RefObject<HTMLDivElement>
  ) => {
    track.kind !== "data" && div.current?.appendChild(track.attach())
  }

  const trackUnsubscribed = (track: RemoteTrack | LocalTrack) => {
    track.kind !== "data" &&
      track.detach().forEach((element: HTMLMediaElement) => element.remove())
  }

  return (
    <VideoContext.Provider
      value={{
        connect,
        disconnect,
        state,
        connected,
      }}
    >
      <div id={styles.CallContainer}>
        <div className={styles.RemoteContainer}>
        <div
            id={styles.RemoteMedia}
            className={`${styles.LoadingBackgroundVideo}`}
            ref={remoteMedia}
          />
          <div className={styles.ChildrenContainer}>{props.children}</div>
        </div>
        <div>
          <div
            id={styles.LocalMedia}
            className={styles.LoadingBackgroundVideo}
            ref={localMedia}
          />
          <div className={styles.HelpSupport}>
            Support : {config.get("support")}
          </div>
        </div>
      </div>
    </VideoContext.Provider>
  )
}

export { VideoContext }

const mapDispatchToProps = (dispatch: (a: ActionWithoutPayload) => void) => {
  return {
    getCurrentCall: () => dispatch(getCall()),
  }
}
export default connect(null, mapDispatchToProps)(VideoProvider as any) as any
