import React, { useState } from "react"

import { Buttons } from "components/Card/Card"
import { Modal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Important } from "components/Title/Title"
import { cdn } from "core/cdn"
import { languages } from "lib/languages"
import { Eta } from "types/payload"

export const FifoWaiting: React.FC<{
  eta: Eta
  loading: boolean
  cancelCall: () => void
}> = ({ eta, cancelCall, loading }): JSX.Element => {
  const [modalVisible, setModalVisibility] = useState(false)
  return (
    <>
      <PreviousButton
        text={languages.cancel}
        onClick={() => setModalVisibility(true)}
      />

      <Buttons direction="vertical" style={{ marginTop: "0" }}>
        {eta.computed !== null && (
          <b className="font-size-large">{languages.tlcExpectedIn}</b>
        )}
        <div className="center flex">
          <img src={cdn("/loaders/gimmick-video.gif")} alt="" height="90" />
          <Important shape={true} text={`${eta.displayed}`} />
        </div>
        <p className="font-size-large">
          {languages.youWillBeInformedBySMSFifo}{" "}
        </p>
      </Buttons>
      <Modal
        loading={loading}
        title={languages.confirmCancelCall}
        visible={modalVisible}
        onAccept={cancelCall}
        acceptText={languages.cancelCall}
        acceptButtonProps={{ danger: true, type: "primary" }}
        onCancel={() => setModalVisibility(false)}
      />
    </>
  )
}
