import React from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import { RESET_STORE } from "client/services/constants"
import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { LOGIN_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import { GlobalStore } from "types/store"

import actions from "./services/actions"

interface ResetPasswordProps {
  message?: Message
  loading?: boolean
  requestIsMade?: boolean
  history: { push: (s: string) => void }
  resetPassword: ({ phone }: { phone: string }) => void
  resetStore: () => void
}

const ResetPassword: React.FC<ResetPasswordProps> = (
  props: ResetPasswordProps
) => {
  const navigate = useNavigate()
  const onResetPassword = ({ phone }: { phone: string }) => {
    props.resetPassword({ phone })
  }

  if (props.requestIsMade) {
    return (
      <Card title={languages.resetPasswordRequestIsMadeTitle} message={props.message}>
        <Buttons>
          <Button
            onClick={() => {
              props.resetStore()
              navigate(LOGIN_PAGE)
            }}
          >
            {languages.comeBackToLogin}
          </Button>
        </Buttons>
      </Card>
    )
  }

  const inputsObj = [
    {
      name: "phone",
      required: true,
      label: languages.resetPasswordTitle,
      extra: languages.tooltipResetPassword,
    },
  ]
  return (
    <Card message={props.message}>
      <FormPage
        loading={props.loading}
        inputs={inputsObj}
        onFinish={(value) => onResetPassword(value as { phone: string })}
        acceptText={languages.next}
        onCancel={() => {
          navigate(LOGIN_PAGE)
        }}
      />
    </Card>
  )
}

const mapStateToProps = (
  state: GlobalStore
): Omit<ResetPasswordProps, "resetPassword" | "resetStore" | "history"> => {
  return state.resetPassword
}

const mapDispatchToProps = (
  dispatch: (a: Action<{ phone: string }> | ActionWithoutPayload) => void
): Omit<ResetPasswordProps, "loading" | "success" | "history"> => ({
  resetStore: () => dispatch({ type: RESET_STORE }),
  resetPassword: ({ phone }: { phone: string }) =>
    dispatch(actions.resetPasswordRequest({ phone })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
