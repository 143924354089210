/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export enum RoomState {
  ERROR = -1,
  ROOM_CONNECTION = 0,
  WAITING_PEER = 1,
  LINKING_PEER = 2,
  LOADING_STREAM = 3,
  CONNECTION_LOST = 4,
  RECONNECTION = 5,
  PEER_CONNECTION_LOST = 6,
  PEER_RECONNECTION = 7,
  ROOM_CONNECTING = 8,
}
