import React from "react"
import { EditOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import { useForm } from "antd/lib/form/Form"
import { useEffect, useState } from "react"
import { LOBBY_PAGE } from "core/constants"
import { Form, FormPage } from "components/Form/Form"
import { GetItemForm } from "components/Form/Input"
import { getNameOfCurrentInput, labelFromName } from "components/Form/utils"
import { GenericModal } from "components/Modal/Modal"
import { languages } from "lib/languages"
import { Gender, Prospect } from "types/entity"
import { Patient } from "types/sesam"
import { Buttons, Card } from "components/Card/Card"
import { Button } from "components/Button"
import styles from "./Signup.module.scss"
import { ModalProps } from "types/props"
import { Input } from 'antd';
import { cdn } from "core/cdn"
import PreviousButton from "components/Previous/PreviousButton"
import { Steps } from "components/Steps"
import { InputNames } from "types/props"
import searchCityFullNameWithInseeCode from "api/SearchBirthLocationName"
import { API_DATE_FORMAT } from "lib/utils"

const AddPseudoButton = ({ onClick }: { onClick: () => void; }) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={onClick}>
      <img height="70px" src={cdn("/images/add_btn.svg")} alt="" />
    </div>
  )
}

export const Displayer: React.FC<{
  name: string
  loading: boolean
  btnType?: string | "add" | "modify" | undefined
  updatePatientPseudoInfo?: (values: Partial<Prospect>) => void;
  genderValue?: number;
  readOnly?: boolean;
}> = (props) => {

  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [openFormModal, setOpenFormModal] = useState(false)
  const [acceptText, setAcceptText] = useState("")
  const [modalTitle, setModalTitle] = useState<JSX.Element>(<></>)

  const modalBtnAcceptText = {
    first_birth_firstname: languages.add_pseudo_firstname,
    birth_lastname: languages.add_pseudo_lastname
  }

  const modalTitleText = {
    birth_lastname: (<div>{languages.you_can_add} <b> {languages.a_pseudo_last_name_different} </b> {languages.of_your_birth_last_name}</div>),
    first_birth_firstname: (<div>{languages.you_can_add} <b> {languages.a_pseudo_first_name_different} </b> {languages.of_your_birth_first_name}</div>),
  }

  const inputs = [
    {
      name: props.name,
      required: true,
      label: languages.modifyYour(getNameOfCurrentInput(props.name)),
    },
  ]

  const [displayInput, setDisplayInput] = useState(inputs)

  const handleFinished = (values: Partial<Prospect>) => {
    setOpenFormModal(false)
    props.updatePatientPseudoInfo(values)
  }

  const handleOpenInfoModal = () => {
    setModalTitle(modalTitleText[props.name])
    setAcceptText(modalBtnAcceptText[props.name])
    setOpenInfoModal(true)
  }

  const handleBtnClick = (value) => {
    switch (value) {
      case "add":
        return handleOpenInfoModal()
      case "modify":
        setOpenFormModal(true)
    }
  }

  const AddPseudoNameAccept = () => {
    switch (props.name) {
      case InputNames.FIRST_BIRTH_FIRSTNAME:
        setDisplayInput([
          {
            name: InputNames.FIRSTNAME,
            required: true,
            label: languages.addA(InputNames.FIRSTNAME),
          },
        ])
        setOpenInfoModal(false)
        setOpenFormModal(true)
        return
      case InputNames.BIRTH_LASTNAME:
        setDisplayInput([
          {
            name: InputNames.LASTNAME,
            required: true,
            label: languages.addA(InputNames.LASTNAME),
          },
        ])
        setOpenInfoModal(false)
        setOpenFormModal(true)
        return
      default:
        return
    }
  }
  if (props.name === InputNames.GENDER) {
    return (
      <Row gutter={[16, 16]} style={{ display: "flex", marginBottom: 20 }}>
        <Col span="7" className={styles.NirDataPageLabele}>
          {languages.sex}
        </Col>
        <Col span="17">
          <Input
            disabled
            value={`${props.genderValue === Gender.MALE ? languages.male.toUpperCase() : languages.female.toUpperCase()}`}
            style={{ maxWidth: 190, border: 0, color: "#7A7A7A" }}
          />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row gutter={[16, 16]} style={{ display: "flex", marginBottom: "20px" }}>
        <Col span="7" className={styles.NirDataPageLabele}>
          {(props.name === InputNames.BIRTH_LOCATION) ?
            `${languages.birthLocation_with_insee_code} :` :
            `${labelFromName(props.name)} :`}
        </Col>
        <Col span="14">
          <GetItemForm
            // To display new-password as a password
            name={getNameOfCurrentInput(props.name)}
            visible={true}
            disabled={true}
            readOnly
          />
        </Col>
        <Col span="3">
          {props.btnType ? (
            props.btnType === "add" ? <AddPseudoButton onClick={() => handleBtnClick("add")} />
              : (<Button
                loading={false}
                icon={<EditOutlined />}
                size="xsmall"
                onClick={() => handleBtnClick("modify")}
              >
                {languages.modify}
              </Button>)
          ) : <></>}
        </Col>
      </Row>

      <AddPseudoNameModal
        title={modalTitle}
        acceptText={acceptText}
        cancelText={languages.cancel}
        visible={openInfoModal}
        onCancel={() => {
          setOpenInfoModal(false)
        }}
        onAccept={AddPseudoNameAccept}
      />

      <GenericModal
        visible={openFormModal}
        noFooter={true}
        style={{ width: "95vw" }}
      >
        <FormPage
          onCancel={() => {
            setOpenFormModal(false)
          }}
          acceptText={props.btnType === "modify" ? languages.modify : languages.add}
          loading={props.loading}
          inputs={displayInput}
          onFinish={handleFinished}
        />
      </GenericModal>

    </>
  )
}

interface InfoFromCarteVitale {
  patient: Partial<Patient>;
  loading: boolean;
  handlePseudoNameUpdate: (values: Partial<Patient>) => void;
}

export const CarteVitaleInfoPage: React.FC<InfoFromCarteVitale> = (
  {
    patient,
    loading,
    handlePseudoNameUpdate
  }
): JSX.Element => {
  const [formRef] = useForm()
  const [pseudoFirstnameIsSet, setPseudoFirstname] = useState(false)
  const [pseudoLastnameIsSet, setPseudoLastname] = useState(false)
  const [patientData, setPatientData] = useState(patient)
  const [buttonTypes, setButtonTypes] = useState({
    firstname: "modfify",
    lastname: 'modify',
    first_birth_firstname: "add",
    birth_lastname: "add"
  })

  useEffect(() => {
    // should be updated after the patient edit a value
    const birthDate = (patient.birthdate).format(
      API_DATE_FORMAT
    )
    if (/^\d+$/.test(patient.insee_code as string)) {
      searchCityFullNameWithInseeCode(patient.insee_code as string, birthDate).then(
        res => {
          const birthLocationDisplayedValue = `${res.locations[0].name} (${patient.insee_code})`
          formRef.setFieldValue(InputNames.BIRTH_LOCATION, birthLocationDisplayedValue)
          setPatientData(p => ({
            ...p,
            birth_location: res.locations[0].name
          }))
        }
      ).catch((error) => console.error(error, { path: "locations/public/search" }))
    }

    formRef.setFieldsValue({ ...patient })
  }, [patient, loading])

  const addPatientPseudoInfo = (values: Partial<Patient>) => {
    if (values.firstname) {
      setPatientData(data => ({ ...data, firstname: values.firstname }))
      setPseudoFirstname(true)
      setButtonTypes(type => ({ ...type, first_birth_firstname: undefined }))
      formRef.setFieldValue(InputNames.FIRSTNAME, values.firstname)
    }
    if (values.lastname) {
      setPatientData(data => ({ ...data, lastname: values.lastname }))
      setPseudoLastname(true)
      setButtonTypes(type => ({ ...type, birth_lastname: undefined }))
      formRef.setFieldValue(InputNames.LASTNAME, values.lastname)
    }
  }

  return (
    <>
      <Steps array={[""]} current={0} />
      <PreviousButton to={LOBBY_PAGE} />
      <Card title={languages.information_from_carte_vitale} titleSize="medium">
        <Form
          form={formRef}
          initialValues={patientData}
        >
          <Displayer loading={loading} name={InputNames.GENDER} genderValue={patientData.gender} />
          <Displayer loading={loading}
            name={InputNames.FIRST_BIRTH_FIRSTNAME}
            btnType={buttonTypes.first_birth_firstname}
            updatePatientPseudoInfo={addPatientPseudoInfo}
          />
          {pseudoFirstnameIsSet &&
            <Displayer
              loading={loading}
              name={InputNames.FIRSTNAME}
              btnType={buttonTypes.firstname}
              updatePatientPseudoInfo={addPatientPseudoInfo} />}
          <Displayer loading={loading}
            name={InputNames.BIRTH_LASTNAME}
            btnType={buttonTypes.birth_lastname}
            updatePatientPseudoInfo={addPatientPseudoInfo}
          />
          {pseudoLastnameIsSet &&
            <Displayer
              loading={loading}
              name={InputNames.LASTNAME}
              btnType={buttonTypes.lastname}
              updatePatientPseudoInfo={addPatientPseudoInfo} />}
          <Displayer loading={loading} name={InputNames.BIRTHDATE} />
          <Displayer loading={loading} name={InputNames.BIRTH_LOCATION} readOnly />
          <Buttons>
            <Button type="primary" style={{ width: 400 }} onClick={() => handlePseudoNameUpdate(patientData)}>
              Suivant
            </Button>
          </Buttons>
        </Form>
      </Card>
    </>
  )
}

export const AddPseudoNameModal: React.FC<ModalProps> = (props): JSX.Element => {
  const { acceptText, cancelText, title, onAccept, onCancel } = props
  const TitleBlock = (
    <div className={styles.title__block}>
      <div className={styles.title__icon}>
        <img src={cdn("images/attention_picto.svg")} alt="warning image" />
      </div>
      <h1 className={styles.title__text}
      >{title}
      </h1>
    </div>
  )

  return (
    <GenericModal
      {...props}
      buttonWrapperStyle={{ marginTop: "-40px" }}
      bodyStyle={{ padding: 0 }}
      titleProps={{ doNotAnimateTitle: true }}
      title={TitleBlock}
      acceptText={acceptText}
      cancelText={cancelText}
      acceptButtonProps={{ wide: "long", type: "primary", danger: false }}
      cancelButtonProps={{ wide: "long" }}
      onCancel={onCancel}
      onAccept={onAccept}
    />
  )
}
