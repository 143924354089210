import config from "react-global-configuration"
import { all, put, takeLatest } from "redux-saga/effects"

import { languages } from "lib/languages"
import { request } from "lib/request"
import { ResponsePayload } from "types/payload"
import { Action, GFlow, GWatcher, Message } from "types/redux"
import { CardInformation } from "types/stripe"

import actions from "./actions"
import {
  CARDS_REQUEST,
  DELETE_CARD_REQUEST,
  GET_TOKEN_REQUEST,
  SETUPINTENT_ATTACH_REQUEST,
  SETUPINTENT_CREATE_REQUEST,
} from "./constants"

async function getpaymentApiKey(): Promise<{ payment_api_key: string }> {
  return await request(config.get("patient.get.payment_api_key"), {
    method: "GET",
  })
}

async function getCardsApi(): Promise<CardInformation[]> {
  return await request(config.get("patient.get.cards"), { method: "GET" })
}

async function deleteCardApi(payload: {
  payment_method_id: string
}): Promise<ResponsePayload> {
  return await request(config.get("patient.get.cards"), {
    method: "DELETE",
    payload,
  })
}

async function setupIntentAttachApi(payload: {
  setupintent_id: string
}): Promise<ResponsePayload> {
  return await request(config.get("patient.setupintent.attach"), {
    method: "POST",
    payload,
  })
}

async function setupIntentCreateApi(): Promise<{
  setupintent: { client_secret: string }
}> {
  return await request(config.get("patient.setupintent.create"), {
    method: "POST",
  })
}
/*
 * Create SetupIntent | receive id
 */
function* createFlow(): GFlow<Action<Message | string>> {
  try {
    const {
      setupintent: { client_secret: clientSecret },
    } = yield setupIntentCreateApi()
    yield put(actions.createSuccess(clientSecret))
  } catch (error) {
    yield put(actions.createError(typeof error === "string" ? error : "Erreur"))
  }
}

function* stripeTokenFlow() {
  try {
    const { payment_api_key }: { payment_api_key: string } =
      yield getpaymentApiKey()
    yield put(actions.getTokenSucess(payment_api_key))
  } catch (error) {
    yield put(
      actions.getTokenError(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}

function* attachFlow({
  payload,
}: Action<{ setupintent_id: string }>): GFlow<Message> {
  yield put(actions.hasStripeError(false))
  try {
    yield setupIntentAttachApi(payload)
    yield all([
      put(actions.attachSuccess(languages.createCardSuccess)),
      put(actions.cardsRequest()),
      put(actions.resetCard()),
    ])
  } catch (error) {
    yield all([
      put(
        actions.attachError(
          typeof error === "string" ? error : languages.createCardError
        )
      ),
      put(actions.hasStripeError(true)),
    ])
  }
}

function* cardsFlow(): GFlow<Action<Message>> {
  try {
    const { cards } = yield getCardsApi()
    yield put(actions.cardsSuccess(cards as CardInformation[]))
  } catch (error) {
    yield put(
      actions.cardsError(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}
function* deleteCardFlow({
  payload,
}: Action<{
  payment_method_id: string
}>): GFlow<Action<Message>> {
  try {
    const response = yield deleteCardApi(payload)
    if (response)
      yield all([
        put(actions.deleteCardSuccess("Votre carte a été supprimée")),
        put(actions.cardsRequest()),
      ])
  } catch (error) {
    yield put(
      actions.deleteCardError(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}
export default function* cardsSaga(): GWatcher {
  yield takeLatest(CARDS_REQUEST, cardsFlow)
  yield takeLatest(SETUPINTENT_ATTACH_REQUEST, attachFlow)
  yield takeLatest(SETUPINTENT_CREATE_REQUEST, createFlow)
  yield takeLatest(DELETE_CARD_REQUEST, deleteCardFlow)
  yield takeLatest(GET_TOKEN_REQUEST, stripeTokenFlow)
}
