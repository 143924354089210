import { languages } from "lib/languages"
import { PATH } from "./_paths"

// JSON SCHEMA
export const surveyLanguages = [
  {
    id: PATH.reason,
    patient: {
      title: languages.anamneseReasonQuestion,
    },
    relative: {
      male: { title: languages.anamneseReasonQuestion_relative_male },
      female: { title: languages.anamneseReasonQuestion_relative_female },
    },
  },
  {
    id: PATH.patient,
    patient: {
      title: languages.questionForWho,
    },
    relative: {
      male: { title: languages.questionForWho },
      female: { title: languages.questionForWho },
    },
  },
  {
    id: PATH.doctor,
    patient: {
      title: languages.orderForWhichDoctor,
      defaultReply: languages.noAppointment,
    },
    relative: {
      male: {
        title: languages.orderForWhichDoctor_relative_male,
        defaultReply: languages.noAppointment_relative_male,
      },
      female: {
        title: languages.orderForWhichDoctor_relative_female,
        defaultReply: languages.noAppointment_relative_female,
      },
    },
  },
  {
    id: PATH.slot,
    patient: {
      title: languages.questionWhichTime,
    },
    relative: {
      male: { title: languages.questionWhichTime },
      female: { title: languages.questionWhichTime },
    },
  },
  {
    id: PATH.pregnant,
    patient: {
      title: languages.anamnesePregnantQuestion,
    },
    relative: {
      male: { title: languages.anamnesePregnantQuestion_relative_female },
      female: { title: languages.anamnesePregnantQuestion_relative_female },
    },
  },
  {
    id: PATH.operation,
    patient: {
      title: languages.anamneseOperationsQuestion,
    },
    relative: {
      male: { title: languages.anamneseOperationsQuestion_relative_male },
      female: { title: languages.anamneseOperationsQuestion_relative_female },
    },
  },
  {
    id: PATH.doctor_available,
    patient: {
      title: languages.anamneseDoctorAvailableQuestion,
    },
    relative: {
      male: { title: languages.anamneseDoctorAvailableQuestion_relative_male },
      female: {
        title: languages.anamneseDoctorAvailableQuestion_relative_female,
      },
    },
  },
  {
    id: PATH.drug,
    patient: {
      title: languages.anamneseDrugsQuestion,
    },
    relative: {
      male: { title: languages.anamneseDrugsQuestion_relative_male },
      female: { title: languages.anamneseDrugsQuestion_relative_female },
    },
  },
  {
    id: PATH.allergy,
    patient: {
      title: languages.anamneseAllergiesQuestion,
      subtitle: languages.anamneseAllergiesSubtitle,
    },
    relative: {
      male: {
        title: languages.anamneseAllergiesQuestion_relative_male,
        subtitle: languages.anamneseAllergiesSubtitle,
      },
      female: {
        title: languages.anamneseAllergiesQuestion_relative_female,
        subtitle: languages.anamneseAllergiesSubtitle,
      },
    },
  },
  {
    id: PATH.illness,
    patient: {
      title: languages.anamneseIllnessQuestion,
      subtitle: languages.anamneseIllnessSubtitle,
    },
    relative: {
      male: {
        title: languages.anamneseIllnessQuestion_relative_male,
        subtitle: languages.anamneseIllnessSubtitle,
      },
      female: {
        title: languages.anamneseIllnessQuestion_relative_female,
        subtitle: languages.anamneseIllnessSubtitle,
      },
    },
  },
  {
    id: PATH.inform_anamnesis,
    patient: {
      title: languages.inform_anamnesis,
    },
    relative: {
      male: { title: languages.inform_anamnesis_relative_male },
      female: { title: languages.inform_anamnesis_relative_female },
    },
  },
]
