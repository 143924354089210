import { cdn } from "core/cdn"
import { languages } from "lib/languages"
import { Question } from "containers/Survey/types"
import { PATH } from "models/survey/_paths"
import { booleanReplies, booleanSubReplies } from "./questions"

// Enceinte
export const pregnant: Question = {
  imgUrl: cdn("/images/enceinte_illustration.svg"),
  title: languages.anamnesePregnantQuestion,
  id: PATH.pregnant,
  multipleReplies: false,
  replies: booleanReplies,
}
// Opérations
export const operation: Question = {
  imgUrl: cdn("/images/operation_illustration.svg"),
  title: languages.anamneseOperationsQuestion,
  id: PATH.operation,
  multipleReplies: false,
  replies: booleanReplies,
}
// Médicaments
export const drug: Question = {
  imgUrl: cdn("/images/medicament_illustration.svg"),
  title: languages.anamneseDrugsQuestion,
  id: PATH.drug,
  multipleReplies: false,
  replies: booleanSubReplies,
}

// Allergies
export const allergy_details: Question = {
  title: languages.anamneseAllergiesQuestionDetails,
  id: PATH.allergy_details,
  // ui: { view: "checkbox" },
  multipleReplies: true,
  replies: [
    {
      title: languages.drugAllergy,
      value: languages.drugAllergy,
      imgUrl: cdn("/images/allergies/picto_medicament.svg"),
    },
    {
      title: languages.pollenAllergy,
      value: languages.pollenAllergy,
      imgUrl: cdn("/images/allergies/picto_pollen.svg"),
    },
    {
      title: languages.foodAllergy,
      value: languages.foodAllergy,
      imgUrl: cdn("/images/allergies/picto_alimentaire.svg"),
    },
    {
      title: languages.otherAllergy,
      value: languages.otherAllergy,
      imgUrl: cdn("/images/allergies/picto_autre.svg"),
    },
  ],
}

export const allergy: Question = {
  imgUrl: cdn("/images/allergie_illustration.svg"),
  title: languages.anamneseAllergiesQuestion,
  subtitle: languages.anamneseAllergiesQuestionSubtitle,
  id: PATH.allergy,
  multipleReplies: false,
  subquestion: allergy_details,
  replies: booleanSubReplies,
}

// Maladies chroniques
export const illness_details: Question = {
  title: languages.anamneseIllnessQuestionDetails,
  id: PATH.illness_details,
  multipleReplies: true,
  // ui: { view: "checkbox" },
  replies: [
    {
      title: languages.diabetes,
      value: languages.diabetes,
      imgUrl: cdn("/images/illnesses/picto_diabete.svg"),
    },
    {
      title: languages.hypertension,
      value: languages.hypertension,
      imgUrl: cdn("/images/illnesses/picto_hypertension.svg"),
    },
    {
      title: languages.cancer,
      value: languages.cancer,
      imgUrl: cdn("/images/illnesses/picto_cancer.svg"),
    },
    {
      title: languages.respiratoryDisease,
      value: languages.respiratoryDisease,
      imgUrl: cdn("/images/illnesses/picto_respiration.svg"),
    },
    {
      title: languages.heartDisease,
      value: languages.heartDisease,
      imgUrl: cdn("/images/illnesses/picto_cardiaque.svg"),
    },
    {
      title: languages.kidneyDisease,
      value: languages.kidneyDisease,
      imgUrl: cdn("/images/illnesses/picto_renal.svg"),
    },
    {
      title: languages.digestiveDisease,
      value: languages.digestiveDisease,
      imgUrl: cdn("/images/illnesses/picto_digestion.svg"),
    },
    {
      title: languages.infectiousDisease,
      value: languages.infectiousDisease,
      imgUrl: cdn("/images/illnesses/picto_infection.svg"),
    },
    {
      title: languages.otherDisease,
      value: languages.otherDisease,
      imgUrl: cdn("/images/allergies/picto_autre.svg"),
    },
  ],
}
export const illness: Question = {
  imgUrl: cdn("/images/maladie_chronique_illustration.svg"),
  title: languages.anamneseIllnessQuestion,
  subtitle: languages.anamneseIllnessQuestionSubtitle,
  id: PATH.illness,
  subquestion: illness_details,
  multipleReplies: false,
  replies: booleanSubReplies,
}
