import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { cdn } from "core/cdn"
import { SURVEY_PAGE, TIMELINE_PAGE } from "core/constants"
import withSurveyController from "hocs/withSurveyController"

import styles from "./Timeline.module.scss"

const Timeline = (_) => {
  const { current: currentStep } = useSelector(({ survey }) => survey)
  const navigate = useNavigate()
  const [imgUrl, setImgUrl] = useState(
    cdn("/images/optic/timeline/frise_step_initialize.svg")
  )

  useEffect(() => {
    if (currentStep.value !== TIMELINE_PAGE) navigate(SURVEY_PAGE)
    if (currentStep.imgUrl) setImgUrl(currentStep.imgUrl)
  }, [currentStep])
  return (
    <>
      <img className={styles.TimelineImg} src={imgUrl} alt="" />
    </>
  )
}

export default withSurveyController(Timeline as any)
