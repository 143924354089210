import config from "react-global-configuration"
import { all, call, put, takeLatest } from "redux-saga/effects"

import clientActions from "client/services/actions"
import {
  insertRelativeSuccess,
  resetRelativeStore,
} from "containers/InsertRelative/services/actions"
import { request } from "lib/request"
import {
  Call,
  CustomerApi,
  RelativeApi,
  ResponseHistory,
  ResponsePutCustomer,
  ResponsePutCustomerVerify,
  ResponsePutRelative,
} from "types/payload"
import { Action, GFlow, GWatcher, Message } from "types/redux"

import actions from "./actions"
import {
  CHANGE_PATIENT_REQUEST,
  CHANGE_RELATIVE_REQUEST,
  HISTORY_REQUEST,
  UNSUBSCRIBE_NEWSLETTER,
} from "./constants"

async function getHistoryApi() {
  return await request(config.get("patient.get.history"), { method: "GET" })
}

async function changeRelativeApi(payload: CustomerApi) {
  return await request(`${config.get("patient.put.relative")}/${payload.id}`, {
    method: "PUT",
    payload,
  })
}

async function changePatientVerifyCancelApi(payload: {
  update_verify_id: string
}) {
  return await request(`${config.get("patient.post.secured_update_cancel")}`, {
    method: "POST",
    payload,
  })
}
async function changePatientApi(payload: CustomerApi) {
  return await request(config.get("patient.put.patient"), {
    method: "PUT",
    payload,
  })
}
async function changePatientVerifyResendApi(payload) {
  return await request(
    config.get("patient.post.secured_token") + "/" + payload.type,
    {
      method: "POST",
      payload: {
        update_verify_id: payload.update_verify_id,
      },
    }
  )
}
async function changePatientVerifyApi(payload: {
  update_verify_id: string
  token: string
}) {
  return await request(config.get("patient.post.verify"), {
    method: "POST",
    payload,
  })
}

function* historyFlow(): GFlow<Call[] | Message> {
  try {
    const response: ResponseHistory = yield getHistoryApi()
    yield put(actions.historySuccess(response.calls || []))
  } catch (error) {
    console.error(error, {
      route: config.get("patient.get.history"),
    })
    yield put(actions.historyError(error as string))
  }
}

function* changePatientVerifyFlow(response: ResponsePutCustomerVerify) {
  yield put({
    type: "UPDATE_REQUIRES_ACTION",
    payload: {
      type: response.customer.type,
      update_verify_id: response.customer.update_verify_id,
      recipient: response.customer.recipient,
    },
  })
}

function* changePatient(response: ResponsePutCustomer) {
  yield all([
    put(clientActions.setCustomer((response as ResponsePutCustomer).customer)),
    put(actions.changePatientSuccess("Nous avons bien modifié votre profil")),
  ])
}
function* changePatientFlow(action: Action<CustomerApi>) {

  if (action.payload?.old_password && action.payload?.password && action.payload?.old_password === action.payload?.password) {
    yield all([put(actions.changePatientError("Les deux mots de passe sont identiques"))])
    return
  }

  try {
    const response: ResponsePutCustomer | ResponsePutCustomerVerify =
      yield call(changePatientApi, action.payload)
    if (response.customer) {
      if ((response as ResponsePutCustomerVerify).customer.update_verify_id) {
        const recipient = action.payload.phone ?? action.payload.email
        const responseVerify = {
          customer: {
            ...response.customer,
            recipient,
          },
        }
        yield changePatientVerifyFlow(
          responseVerify as ResponsePutCustomerVerify
        )
      } else yield changePatient(response as ResponsePutCustomer)
    }
  } catch (e) {
    console.error(e, {
      route: config.get("patient.put.patient"),
    })
    yield put(
      actions.changePatientError(
        (e as string) || "Impossible de modifier votre"
      )
    )
  }
}

function* changeRelativeFlow(action: Action<RelativeApi>) {
  try {
    const response: ResponsePutRelative = yield call(
      changeRelativeApi,
      action.payload
    )
    if (response.relative) {
      yield all([
        put(insertRelativeSuccess(response.relative)),
        put(resetRelativeStore()),
        put(
          actions.changeRelativeSuccess(
            "Nous avons bien modifié les informations de votre proche"
          )
        ),
      ])
    }
  } catch (e) {
    console.error(e, {
      route: `${config.get("patient.put.relative")}/${action.payload?.id}`,
    })
    yield put(
      actions.changeRelativeError(
        (e as string) || "Impossible de modifier le proche"
      )
    )
  }
}

function* updateVerifyCancelFlow(action: Action<{ update_verify_id: string }>) {
  const response = yield changePatientVerifyCancelApi(action.payload)
}

function* updateVerifyFlow(
  action: Action<{ token: string; update_verify_id: string }>
) {
  try {
    const response = yield changePatientVerifyApi(action.payload)
    if (response.status === "ok" && response.customer)
      yield all([
        put(
          clientActions.setCustomer((response as ResponsePutCustomer).customer)
        ),
        put({ type: "UPDATE_VERIFY_SUCCESS" }),
        put(
          actions.changePatientSuccess("Nous avons bien modifié votre profil")
        ),
      ])
    else {
      yield all([
        put({ type: "UPDATE_VERIFY_ERROR" }),
        put({
          type: "UPDATE_VERIFY_MESSAGE",
          payload: { type: "error", text: "Une erreur est survenue" },
        }),
      ])
    }
  } catch (e) {
    yield all([
      put({ type: "UPDATE_VERIFY_ERROR" }),
      put({
        type: "UPDATE_VERIFY_MESSAGE",
        payload: {
          type: "error",
          text: "Le code de vérification est incorrect",
        },
      }),
    ])
  }
}
function* updateVerifyResendFlow(
  action: Action<{ type: string; update_verify_id: string }>
) {
  const response = yield changePatientVerifyResendApi(action.payload)
  if (response.status === "ok")
    yield all([
      put({
        type: "UPDATE_REQUIRES_ACTION",
        payload: {
          update_verify_id: response.update_verify_id,
        },
      }),
      put({
        type: "UPDATE_VERIFY_MESSAGE",
        payload: {
          type: "success",
          text: `Un ${action.type === "email" ? "email" : "sms"} a été envoyé`,
        },
      }),
    ])
  else {
    yield all([
      put({ type: "UPDATE_VERIFY_ERROR" }),
      put({
        type: "UPDATE_VERIFY_MESSAGE",
        payload: { type: "error", text: "le code n'a pas pu être renvoyé." },
      }),
    ])
  }
}
function* unsubscribeNewsletter() {
  const newsletterSubId = config.get("newsletterSubPatientId")
  try {
    yield request(`/crm/unsubscribe/customer/${newsletterSubId}`, {
      method: "POST",
    })
  } catch (error) {
    console.error(error, {
      route: `crm/unsubscribe/customer/${newsletterSubId}`,
    })
  }
}

function* ProfileWatcher(): GWatcher {
  yield takeLatest(HISTORY_REQUEST, historyFlow)
  yield takeLatest(CHANGE_RELATIVE_REQUEST, changeRelativeFlow)
  yield takeLatest(CHANGE_PATIENT_REQUEST, changePatientFlow)
  yield takeLatest(UNSUBSCRIBE_NEWSLETTER, unsubscribeNewsletter)
  yield takeLatest("UPDATE_VERIFY_REQUEST", updateVerifyFlow)
  yield takeLatest("UPDATE_VERIFY_RESEND", updateVerifyResendFlow)
  yield takeLatest("UPDATE_VERIFY_CANCEL", updateVerifyCancelFlow)
}

export default ProfileWatcher
