import { MedicalPath } from "types/entity"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  OPTIC_MESSAGE,
  SET_OPTIC_MEDICAL_PATH,
  OPTIC_CANCEL_PATH,
} from "./constants"

const actions = {
  cancelPath: (): ActionWithoutPayload => ({ type: OPTIC_CANCEL_PATH }),
  setPath: (payload: MedicalPath): Action<MedicalPath> => ({
    type: SET_OPTIC_MEDICAL_PATH,
    payload,
  }),
  inform: (payload: Message): Action<Message> => ({
    type: OPTIC_MESSAGE,
    payload,
  }),
}
export default actions
