import Select from "antd/lib/select";
import React from "react";

import { CardInformation } from "types/stripe";

import { Card } from "./Card";

export const SelectCard: React.FC<{
  cards: CardInformation[];
  defaultValue?: CardInformation;
  onSelect: (card: CardInformation) => void;
}> = (props): JSX.Element => {
  return (
    <>
      <Select
        style={{ width: "100%", minWidth: "500px" }}
        defaultValue={props.defaultValue?.id}
        value={props.defaultValue?.id}
        onChange={(selectedCardId: string) => {
          props.onSelect(
            props.cards.find(
              ({ id }: CardInformation) => id === selectedCardId
            ) as CardInformation
          );
        }}
      >
        {props.cards.map((cardInfo, key) => (
          <Select.Option key={key} value={cardInfo.id}>
            <Card card={cardInfo.card} />
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
