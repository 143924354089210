import { w3cwebsocket as W3CWebSocket } from "websocket"

import { SocketSendMessage } from "types/payload"
import { Action, ActionWithoutPayload } from "types/redux"

import {
  SET_SOCKET,
  SOCKET_EMIT_MESSAGE,
  SOCKET_ERROR_CLOSED,
  SOCKET_LOG_TERMINAL_INFORMATION,
  SOCKET_IN_OPEN_STATE,
  SOCKET_LAUNCH_WORKFLOW,
  SOCKET_RETRY_CONNECT_DECREMENT_RETRY,
  SOCKET_RETRY_CONNECT_RESET,
  SOCKET_SEND_CANCELED_CALL,
  SOCKET_SEND_MESSAGE,
  SOCKET_ENGINE_RESET_DIAGNOSTIC,
} from "./constants"
import { SocketPayload } from "./utils"

export function decrementRetryBy(step: number): Action<number> {
  return {
    type: SOCKET_RETRY_CONNECT_DECREMENT_RETRY,
    payload: step,
  }
}

export function emitMessage(payload: SocketPayload): Action<SocketPayload> {
  return {
    type: SOCKET_EMIT_MESSAGE,
    payload,
  }
}

export function launchWorkflow(): ActionWithoutPayload {
  return {
    type: SOCKET_LAUNCH_WORKFLOW,
  }
}

export const resetNumberOfRetry = (): ActionWithoutPayload => ({
  type: SOCKET_RETRY_CONNECT_RESET,
})

export const sendCallCanceled = (): ActionWithoutPayload => ({
  type: SOCKET_SEND_CANCELED_CALL,
})

export function sendMessageToSocket(
  payload: SocketSendMessage
): Action<SocketSendMessage> {
  return { type: SOCKET_SEND_MESSAGE, payload }
}

export function setSocket(payload: W3CWebSocket): Action<W3CWebSocket> {
  return { type: SET_SOCKET, payload }
}

export const setSocketInOpenState = (): ActionWithoutPayload => ({
  type: SOCKET_IN_OPEN_STATE,
})

export const socketErrorClosed = (): ActionWithoutPayload => ({
  type: SOCKET_ERROR_CLOSED,
})


export const logTerminalInformation = (): ActionWithoutPayload => ({
  type: SOCKET_LOG_TERMINAL_INFORMATION
})

export const resetDiagnostic = (): ActionWithoutPayload => ({
  type: SOCKET_ENGINE_RESET_DIAGNOSTIC,
});

