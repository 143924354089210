import React, { useState } from "react"
import NirReader from "components/NirReader/NirReader"
import { NirReaderResponse } from "types/store"

function withNirReader<T>(WrappedComponent: React.FC<T>): React.FC<T> {
  const WithState = (props: T): JSX.Element => {
    const [showForm, setShowForm] = useState(false)
    const [withNirReaderResponse, setResponse] = useState<NirReaderResponse>()

    if (!showForm)
      return (
        <>
          <NirReader
            hasNir={(value: boolean) => {
              !value && setShowForm(true)
            }}
            manual={false}
            onFinish={(dataInCard: NirReaderResponse) => {
              setResponse(dataInCard)
              setShowForm(true)
            }}
          />
        </>
      )
    else
      return (
        <WrappedComponent
          {...props}
          withNirReaderResponse={withNirReaderResponse}
        />
      )
  }
  return WithState
}
export default withNirReader
