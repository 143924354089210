import { Call } from "types/payload"
import { Action, Message } from "types/redux"
import { ProfileStore, ResetPasswordStore } from "types/store"
import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_STORE,
  RESET_PASSWORD_REQUEST_IS_MADE,
} from "./constants"

const initialState: Partial<ResetPasswordStore> = {
  loading: false,
  success: false,
  requestIsMade: false,
}
const Reducer = (
  state = initialState,
  action: Action<Call[] | Message>
): Partial<ProfileStore> => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST_IS_MADE:
      return {
        ...state,
        loading: false,
        requestIsMade: true,
      }
    case RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, success: false }
    case RESET_PASSWORD_STORE:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default Reducer
