import { Carer, Relationship } from "types/entity"
import { Action, Message } from "types/redux"
import { InsertCarerStore } from "types/store"

import { ADD_PATIENT_CARERS_ERROR, ADD_PATIENT_CARERS_REQUEST, ADD_PATIENT_CARERS_SUCCESS, ADD_PHARMACY_CARERS_ERROR, ADD_PHARMACY_CARERS_REQUEST, ADD_PHARMACY_CARERS_SUCCESS, ADD_RELATIONSHIP_PATIENT_CARERS_ERROR, ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST, ADD_RELATIONSHIP_PATIENT_CARERS_SUCCESS, CANCEL_PROFESSIONAL_TO_INSERT, CARERS_CLEAR_MESSAGE, CARERS_RESET_STORE, DELETE_PHARMACY_CARER_ERROR, DELETE_PHARMACY_CARER_REQUEST, DELETE_PHARMACY_CARER_SUCCESS, GET_IDENTITY_FROM_RPPS_ERROR, GET_IDENTITY_FROM_RPPS_REQUEST, GET_IDENTITY_FROM_RPPS_SUCCESS, GET_PATIENT_CARERS_ERROR, GET_PATIENT_CARERS_REQUEST, GET_PATIENT_CARERS_SUCCESS, GET_PHARMACY_CARERS_ERROR, GET_PHARMACY_CARERS_REQUEST, GET_PHARMACY_CARERS_SUCCESS, GET_RELATIONSHIP_PATIENT_CARERS_ERROR, GET_RELATIONSHIP_PATIENT_CARERS_REQUEST, GET_RELATIONSHIP_PATIENT_CARERS_SUCCESS } from "./constants"

export const initialState: Partial<InsertCarerStore> = {
  success: false,
  loading: false,
  pharmacy_carers: null,
  patient_carers: null,
}

const reducer = function relativeReducer(
  state = initialState,
  action: Action<Carer[] | Carer | Message | Relationship[]>
): Partial<InsertCarerStore> {
  switch (action.type) {
    case ADD_PHARMACY_CARERS_REQUEST:
    case GET_PHARMACY_CARERS_REQUEST:
    case DELETE_PHARMACY_CARER_REQUEST:
    case GET_RELATIONSHIP_PATIENT_CARERS_REQUEST:
    case ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST:
    case GET_PATIENT_CARERS_REQUEST:
    case ADD_PATIENT_CARERS_REQUEST:
    case GET_IDENTITY_FROM_RPPS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      }
    case ADD_PHARMACY_CARERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        professionalToInsert: null,
      }
    case ADD_RELATIONSHIP_PATIENT_CARERS_SUCCESS:
    case ADD_PATIENT_CARERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case GET_IDENTITY_FROM_RPPS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        professionalToInsert: action.payload as Carer,
      }
    case CANCEL_PROFESSIONAL_TO_INSERT:
      return {
        ...state,
        message: null,
        professionalToInsert: null,
      }
    case GET_PHARMACY_CARERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        pharmacy_carers: (action.payload as Carer[]).map((c) => ({
          ...c,
          is_carer: true,
        })),
      }
    case GET_PATIENT_CARERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        patient_carers: (action.payload as Carer[]).map((c) => ({
          ...c,
          is_carer: true,
        })),
      }

    case DELETE_PHARMACY_CARER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case GET_RELATIONSHIP_PATIENT_CARERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        relationships_current_patient: action.payload as Relationship[],
      }
    case GET_IDENTITY_FROM_RPPS_ERROR:
    case ADD_PHARMACY_CARERS_ERROR:
    case GET_PHARMACY_CARERS_ERROR:
    case DELETE_PHARMACY_CARER_ERROR:
    case GET_RELATIONSHIP_PATIENT_CARERS_ERROR:
    case ADD_RELATIONSHIP_PATIENT_CARERS_ERROR:
    case GET_PATIENT_CARERS_ERROR:
    case ADD_PATIENT_CARERS_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        message: action.payload as Message,
      }
    case CARERS_RESET_STORE:
      return initialState
    case CARERS_CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
      }
    default:
      return state
  }
}

export default reducer
