import { LoadingOutlined } from "@ant-design/icons"
import Spin from "antd/lib/spin"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { SOCKET_ENGINE_DIAGNOSTIC_START, SOCKET_ENGINE_RESET_DIAGNOSTIC } from "client/socket/constants"
import Alert from "components/Alert/Alert"
import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import { EngineDiagnoticModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { PREVIOUS_STEP, SET_CURRENT_STEP } from "containers/Survey/services/constants"
import { cdn } from "core/cdn"
import { TIMELINE_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { PATH } from "models/survey/_paths"
import { SocketStore } from "types/store"

import { isEngineDiagnosticOk } from "../../client/socket/utils"
import Styles from "./diagnostic.module.scss"

const SuccessMessage = () => (
  <div className={Styles.SuccessContainer}>
    <div>
      <img src={cdn("images/check_icon.svg")} alt="icone_check_vert" />
    </div>
    <div>
      <p>{languages.connexionSuccess}</p>
    </div>
  </div>
)

const FailedMessage = () => (
  <>
    <div className={Styles.FailedContainer}>
      <div>
        <img
          src={cdn("icons/picto_error.svg")}
          alt="Icône - Erreur lors de la vérification"
        />
      </div>
      <div>
        {languages.connexionFail}
        <br />
        <b>{languages.contactOptician}</b> {languages.toCheckmachines}
      </div>
    </div>
    <div></div>
  </>
)

const antIcon = (
  <LoadingOutlined style={{ fontSize: 80, color: "#9AA6AC" }} spin />
)

const ResultMessage = ({
  loading,
  success,
}: {
  loading: boolean
  success: boolean
}) => {
  return (
    <div>
      {loading ? (
        <div className={Styles.LoaderContainer}>
          <div className={Styles.SpinContainer}>
            <Spin
              spinning={true}
              indicator={antIcon}
              style={{ marginTop: "20px", marginRight: "70px" }}
            />
            <div className={Styles.LoaderLabel}>
              {languages.diagnosticSubtitle}
            </div>
          </div>
        </div>
      ) : (
        <div>{success && <SuccessMessage />}</div>
      )}
    </div>
  )
}

const Diagnostic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [visible, setVisible] = useState(false)
  const diagnostic = useSelector(
    ({ socket }: { socket: SocketStore }) => socket?.diagnostic?.engine
  )
  useEffect(() => {
    dispatch({ type: SOCKET_ENGINE_DIAGNOSTIC_START })
  }, [])

  useEffect(() => {
    if (diagnostic) {
      setLoading(false)
      if (isEngineDiagnosticOk(diagnostic)) {
        setSuccess(true)
      } else {
        setVisible(true)
      }
    }
  }, [diagnostic])
  return (
    <Card title={languages.diagnostic}>
      <PreviousButton
        text="Retour"
        onClick={() => {
          dispatch({ type: PREVIOUS_STEP })
          navigate(TIMELINE_PAGE)
        }}
      />
      <EngineDiagnoticModal
        title={languages.diagnosticFailedTitle}
        visible={visible}
        onAccept={() => {
          dispatch({ type: SOCKET_ENGINE_RESET_DIAGNOSTIC })
          dispatch({ type: SET_CURRENT_STEP, payload: PATH.not_adapted })
          navigate(TIMELINE_PAGE)
        }}
        onCancel={() => {
          setLoading(true)
          setVisible(false)
          console.error("optic diagnostic KO, user continue anyway", {
            route: "wss::diagnostic",
          })
          dispatch({ type: SOCKET_ENGINE_DIAGNOSTIC_START })
        }}
      >
        <FailedMessage />
      </EngineDiagnoticModal>
      <ResultMessage loading={loading} success={success} />
      <Buttons direction="vertical">
        <Button
          style={{
            backgroundColor: loading && "#F5F5F5",
            border: loading && "2px solid #D9D9D9",
            color: loading && "#D9D9D9",
            marginTop: "25px",
          }}
          type="primary"
          wide="long"
          disabled={loading}
          className="disable-like-able"
          onClick={() => {
            dispatch({ type: SOCKET_ENGINE_RESET_DIAGNOSTIC })
            dispatch({ type: SET_CURRENT_STEP, payload: PATH.not_adapted })
            navigate(TIMELINE_PAGE)
          }}
        >
          <b>
            {languages.continue.toUpperCase()}{" "}
            {!loading && !success && languages.allTheSame.toUpperCase()}
          </b>
        </Button>
      </Buttons>
    </Card>
  )
}

export default Diagnostic
