import { Button, Modal } from "antd"
import { formatPhoneNumber } from "react-phone-number-input"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { RESET_STORE } from "client/services/constants"
import { Buttons, Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import useOurForm from "components/Form/useForm"
import Message from "components/Message/Message"
import { GenericModal } from "components/Modal/Modal"
import { HOME_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { UpdateVerifyCodeProps } from "types/store"

import styles from "./Profile.module.scss"

export const UpdateVerifyCode = (props: UpdateVerifyCodeProps) => {
  const formRef = useOurForm()
  const dispatch = useDispatch()

  const inputsObj = [
    {
      name: "twoFa",
      required: true,
      label: "",
      autoSubmit: true,
    },
  ]
  return (
    <GenericModal visible={props.visible} noFooter>
      <div>
        <Message message={props.message} />
        <div className={styles.Information}>
          {props.type === "email"
            ? languages.verifyEmail
            : languages.verifyPhone}{" "}
          : <b>{props.recipient}</b>
        </div>
        <FormPage
          formRef={formRef}
          inputs={inputsObj}
          acceptText={languages.validate}
          cancelText={languages.cancel}
          loading={props.loading}
          onFinish={(values) => {
            const code = (values as any).twoFa
            dispatch({
              type: "UPDATE_VERIFY_REQUEST",
              payload: {
                token: code,
                update_verify_id: props.update_verify_id,
              },
            })
            formRef.reset()
          }}
          onCancel={() => {
            dispatch({
              type: "UPDATE_VERIFY_CANCEL",
              payload: { update_verify_id: props.update_verify_id },
            })
            props.cancel()
          }}
        />
        <Buttons style={{ paddingTop: 20 }}>
          <Button
            type="link"
            size="small"
            onClick={() =>
              dispatch({
                type: "UPDATE_VERIFY_RESEND",
                payload: {
                  type: props.type,
                  update_verify_id: props.update_verify_id,
                },
              })
            }
          >
            Renvoyer le code
          </Button>
        </Buttons>
      </div>
    </GenericModal>
  )
}
