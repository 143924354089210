import { PATH } from "models/survey/_paths"
import {
  NUMBER_OF_SLOTS_AFTER_NOW,
  NUMBER_OF_SLOTS_BEFORE_NOW,
} from "models/survey/constants"
import { CustomerType } from "types/props"
import { Action, ActionWithoutPayload, Message } from "types/redux"

import { Answer, Question, Reply } from "../types"
import {
  CANCEL_SURVEY,
  CREATE_STEP,
  INIT_SURVEY,
  NEXT_STEP,
  PREVIOUS_STEP,
  STEP_SLOTS_CHOICE,
  REMOVE_STEP,
  RESET_SURVEY_STORE,
  SET_CURRENT_STEP,
  SET_CUSTOMER_TYPE,
  SET_STEP,
  SUBMIT_SURVEY_ERROR,
  SUBMIT_SURVEY_REQUEST,
  SUBMIT_SURVEY_SUCCESS,
  UNSET_STEP,
  UPDATE_TIMELINE,
} from "./constants"

const actions = {
  setCurrentStep: (payload: PATH) => ({ type: SET_CURRENT_STEP, payload }),
  setStepReplies: (payload: {
    replies: Reply[]
    id?: PATH
  }): Action<{ replies: Reply[]; id?: PATH }> => ({
    type: SET_STEP,
    payload,
  }),

  unsetStepReplies: (payload: {
    replies?: Reply[]
    id?: PATH
  }): Action<{ replies?: Reply[]; id?: PATH }> => ({
    type: UNSET_STEP,
    payload,
  }),
  reset: (): ActionWithoutPayload => ({ type: RESET_SURVEY_STORE }),
  init: (): ActionWithoutPayload => ({ type: INIT_SURVEY }),
  cancel: (): ActionWithoutPayload => ({ type: CANCEL_SURVEY }),
  next: (): ActionWithoutPayload => ({ type: NEXT_STEP }),

  previous: (): ActionWithoutPayload => ({ type: PREVIOUS_STEP }),

  setSlots: (): Action<{ slotsBeforeNow: number; slotsAfterNow: number }> => ({
    type: STEP_SLOTS_CHOICE,
    payload: {
      slotsBeforeNow: NUMBER_OF_SLOTS_BEFORE_NOW,
      slotsAfterNow: NUMBER_OF_SLOTS_AFTER_NOW,
    },
  }),
  create: (payload: {
    question: Question
    options?: { after?: PATH; before?: PATH }
  }): Action<{
    question: Question
    options?: { after?: PATH; before?: PATH }
  }> => ({
    type: CREATE_STEP,
    payload,
  }),

  remove: (payload: string): Action<string> => ({
    type: REMOVE_STEP,
    payload,
  }),
  for: (customerType: CustomerType): Action<CustomerType> => ({
    type: SET_CUSTOMER_TYPE,
    payload: customerType,
  }),
  success: (): ActionWithoutPayload => ({ type: SUBMIT_SURVEY_SUCCESS }),

  error: (e: string): Action<Message> => ({
    type: SUBMIT_SURVEY_ERROR,
    payload: { type: "error", text: e },
  }),

  submit: (answers: Answer[]): Action<Answer[]> => {
    return { type: SUBMIT_SURVEY_REQUEST, payload: answers }
  },

  updateTimeline: () => {
    return { type: UPDATE_TIMELINE }
  }
}

export default actions
