import config from "react-global-configuration"
import { call, put, takeLatest } from "redux-saga/effects"

import opticActions from "client/optic/actions"
import { CALL_HAS_CHANGED } from "containers/Call/services/constants"
import { CALL_FINISHED } from "containers/Call/services/saga"
import surveyActions from "containers/Survey/services/actions"
import { Question } from "containers/Survey/types"
import { languages } from "lib/languages"
import { request } from "lib/request"
import { PATH } from "models/survey/_paths"
import { FOR_PATIENT } from "models/survey/constants"
import { DoctorType, MedicalPath } from "types/entity"
import { Call, ResponseMedicalPath } from "types/payload"
import { Action, GFlow, Message } from "types/redux"

import { CHECK_OPTIC_PATH } from "./constants"

async function createMedicalPathApi(): Promise<ResponseMedicalPath> {
  return await request(`${config.get("optic.get.medicalPath")}`, {
    method: "POST",
  })
}

export async function getMedicalPathApi(): Promise<ResponseMedicalPath> {
  return await request(`${config.get("optic.get.medicalPath")}`, {
    method: "GET",
  })
}

export const getNextDoctor = (path?: MedicalPath): DoctorType | false => {
  if (!path) return false
  else if (path.is_orthoptia && path.is_opthalmology) return false
  else if (path.is_orthoptia) return DoctorType.OPHTALMOLOGIST
  else return DoctorType.ORTHOPTIST
}

export function* getMedicalPath(): GFlow<MedicalPath> {
  const { path } = yield call(getMedicalPathApi)
  switch (path?.state) {
    case undefined:
    case null:
    case "terminated":
    case "canceled":
      const { path: newPath } = yield call(createMedicalPathApi)
      yield put(opticActions.setPath(newPath))
      return newPath
    case "created":
    case "ongoing":
    default:
      yield put(opticActions.setPath(path))
      return path
  }
}

function* informPatientOfMedicalPath(
  path: MedicalPath
): GFlow<Action<Message>> {
  if (!path)
    yield put(
      opticActions.inform({
        type: "success",
        text: "Aucun Parcours optique n'a été trouvé",
      })
    )
  else if (path.state === "ongoing") {
    const nextDoctorCategory = getNextDoctor(path)
    yield put(
      opticActions.inform({
        type: "success",
        text: `Nous avons trouvé un parcours optique pour vous ! prochaine mise en relation avec : ${languages.doctorType(
          nextDoctorCategory
        )}`,
      })
    )
  } else if (path.state === "created")
    yield put(
      opticActions.inform({
        type: "success",
        text: "Nous avons créé un parcours optique ! ",
      })
    )
}
function* setCurrentPatientFromPath(
  path: MedicalPath
): GFlow<Action<Question>> {
  if (path.relative_id) {
    // the medical_path is owned by a relative
    // lets push it into the survey
    yield put(
      surveyActions.setStepReplies({
        replies: [
          {
            title: "force-relative-reply-from-medicalpath",
            value: path.relative_id,
          },
        ],
        id: PATH.relative,
      })
    )
  } else {
    yield put(
      surveyActions.setStepReplies({
        replies: [
          { title: "force-patient-reply-from-medicalpath", value: FOR_PATIENT },
        ],
        id: PATH.patient,
      })
    )
  }
}
function* setExemptionFromPath(path: MedicalPath): GFlow<Action<Message>> {
  // Force exemption
  // -> @TOCHANGE Consultation Free
  yield put(
    surveyActions.setStepReplies({
      replies: [
        { title: "force-exemption-reply-from-medicalpath", value: true },
      ],
      id: PATH.exemption,
    })
  )
}

function* checkPathFlow(): GFlow<any> {
  try {
    const path = yield call(getMedicalPath)
    yield call(informPatientOfMedicalPath, path)
    // line below is commented because optic TLC is not free
    // yield setExemptionFromPath(path)
    const nextDoctorCategory = getNextDoctor(path)
    if (nextDoctorCategory === DoctorType.OPHTALMOLOGIST) {
      // Path is ongoing and the patient owner must be set
      yield setCurrentPatientFromPath(path)
      yield put(surveyActions.setCurrentStep(PATH.payment))
    }
  } catch (e) {
    const error = typeof e === "string" ? e : languages.genericAPIError
    yield put(opticActions.inform({ type: "error", text: error }))
    console.error(error, {
      route: config.get("optic.get.medicalPath"),
    })
  }
}

function* checkOpticCallFLow(action: Action<Call>) {
  const call: Call = action.payload
  if (call && CALL_FINISHED.includes(call.state)) {
    yield put({ type: CHECK_OPTIC_PATH })
  }
}

function* opticWatcher() {
  yield takeLatest(CHECK_OPTIC_PATH, checkPathFlow)
  yield takeLatest(CALL_HAS_CHANGED, checkOpticCallFLow)
}
export default opticWatcher
