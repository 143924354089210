import { Layout } from "antd";
import { Fragment, useState } from "react";
import config from "react-global-configuration";
import IdleTimer from "react-idle-timer";
import { useSelector } from "react-redux";

import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { IdleModal } from "components/Modal/Modal";
import { HOME_PAGE } from "core/constants";
import { ClientStore } from "types/store";

import styles from "./Hocs.module.scss";
import { useEffect } from "react";
const _IdleTimer = IdleTimer as any;

function withHeaderAndFooter<T>(WrappedComponent: React.FC<T>): React.FC<T> {
  return (props: T): JSX.Element => {
    const [pause, setPause] = useState(false);
    useEffect(() => {
      document.addEventListener('visibilitychange', function() {
        // clear Interval if user leave the tab
        if(document.hidden) {
          setPause(true);
        } else {
          setPause(false);
        }
      });
    }, [])
    const [isIdle, setIdle] = useState(false);
    const { patient } = useSelector(({ client }: { client: ClientStore }) => ({
      patient: client.customer,
    }));
    const timeoutMinutes = config.get("timeoutInactivity.minutes") || 15;

    const triggerIdleOnHomePage =
      patient && [HOME_PAGE, "/"].includes(window.location.pathname);

    const doNotTriggerIdle = triggerIdleOnHomePage
      ? false
      : config
          .get("timeoutInactivity.doNotTriggerOn")
          .includes(window.location.pathname);
    return (
      <Fragment>
        <Layout className={`${styles.Layout} page`}>
          <Layout.Header className={styles.Header}>
            <Header patient={patient} />
          </Layout.Header>
          {!doNotTriggerIdle && (
            <_IdleTimer
              timeout={1000 * 60 * timeoutMinutes}
              onIdle={() => {
                setIdle(true);
              }}
              pause={pause}
            />
          )}
          <Layout.Content className={`${styles.Content} `}>
            <WrappedComponent {...props} />
          </Layout.Content>
          <Layout.Footer className={styles.Footer}>
            <Footer />
          </Layout.Footer>
        </Layout>
        <IdleModal visible={!pause && isIdle} onAccept={() => {setIdle(false)}} isIdle={isIdle}/>
      </Fragment>
    );
  };
}

export default withHeaderAndFooter;
