import { InputProps } from "antd/lib/input"
import React from "react"
import InputMask from "react-input-mask"

import { languages } from "lib/languages"

import styles from "./NirInput.module.scss"

export interface NirInputProps
  extends Omit<InputProps, "onClick" | "onChange"> {
  onClick?: (nir: string) => void
  onChange?: (nir: string) => void
}
// NIR_MASK = _ / __ / __ / __ / ___ / ___ / __
const NIR_MASK = `${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar}${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar}${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar}${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar}${languages.nirAllowedChar}${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar}${languages.nirAllowedChar}${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar}${languages.nirAllowedChar}`

const NirInput: React.FC<NirInputProps> = (props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const unmaskedValue = e.target.value.replaceAll("_", "")
    const nir: string = unmaskedValue.replaceAll(languages.nirSpacer, "")
    nir.length === 15 && e.target.blur()
    props.onChange && props.onChange(nir)
  }
  return (
    <InputMask
      readOnly={props.readOnly}
      disabled={props.disabled}
      id={props.name}
      name={props.name}
      onChange={onChange}
      value={props.value as string}
      alwaysShowMask
      className={styles.NirInput}
      mask={NIR_MASK}
    />
  )
}

export default NirInput
