// In order to perform an action of type LOGIN_REQUEST
import {
  CALL_ERROR,
  CALL_REQUEST,
  CALL_SUCCESS,
  CALL_HAS_CHANGED,
  CALL_CANCEL_REQUEST,
  GET_CALL,
  CALL_HANGUP_REQUEST,
  CALL_HANGUP_SUCCESS,
  CALL_HANGUP_ERROR,
  CALL_CANCEL_SUCCESS,
  CALL_CANCEL_ERROR,
} from "./constants"
import { languages } from "lib/languages"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import { Call, CallRequest } from "types/payload"
/*
 */
export const getCall = (): ActionWithoutPayload => ({
  type: GET_CALL,
})

export const callHasChanged = (
  response: Call | undefined
): Action<Call | undefined> => ({
  type: CALL_HAS_CHANGED,
  payload: response,
})
/*
 */
export const callRequest = (request: CallRequest): Action<CallRequest> => ({
  type: CALL_REQUEST,
  payload: request,
})

export const callSuccess = (response: Call): Action<Call> => ({
  type: CALL_SUCCESS,
  payload: response,
})

export const callError = (error: string, delay?: number): Action<Message> => ({
  type: CALL_ERROR,
  payload: { type: "error", text: error, delay },
})
/*
 */
export const callHangupRequest = (): ActionWithoutPayload => ({
  type: CALL_HANGUP_REQUEST,
})
export const callHangupSuccess = (): ActionWithoutPayload => ({
  type: CALL_HANGUP_SUCCESS,
})
export const callHangupError = (e: string): Action<Message> => ({
  type: CALL_HANGUP_ERROR,
  payload: { text: e, type: "error" },
})

/*
 */
export const cancelCallRequest = (): ActionWithoutPayload => ({
  type: CALL_CANCEL_REQUEST,
})

export const cancelCallSuccess = ({
  call,
  message = languages.callHasBeenCancelled,
}: {
  call: Call
  message?: string
}): Action<Message & { call?: Call }> => ({
  type: CALL_CANCEL_SUCCESS,
  payload: { type: "success", text: message, call },
})

export const cancelCallError = (
  message: string = languages.callCannotBeCancelled
): Action<Message> => ({
  type: CALL_CANCEL_ERROR,
  payload: { type: "error", text: message },
})
