import React from "react"
import styles from "./Footer.module.scss"
import { useNavigate } from "react-router-dom"
import Clock from "components/Clock/Clock"

import { Medadom } from "components/Logo/Medadom"
import { HOME_PAGE } from "core/constants"
import { connect } from "react-redux"
import { ActionWithoutPayload } from "types/redux"
import surveyActions from "containers/Survey/services/actions"

const Footer: React.FC<{ resetSurvey: () => void }> = ({ resetSurvey }) => {
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  return (
    <div className={styles.Footer} data-testid="Footer">
      <a href="/">
        <Medadom />
      </a>
      <span className={styles.Clock}>
        <Clock />
      </span>
    </div>
  )
}
const p = (dispatch: (a: ActionWithoutPayload) => void) => ({
  resetSurvey: () => dispatch(surveyActions.reset()),
})
export default connect(null, p)(Footer)
