import { DemonstrationStore } from "types/store"
import React from "react"
import styles from "./Demonstration.module.scss"
import withSurveyController from "hocs/withSurveyController"
import { cdn } from "core/cdn"

const Demonstration: React.FC<DemonstrationStore> = (props): JSX.Element => {
  return (
    <video
      id="remote-media"
      className={styles.VideoDimension}
      autoPlay
      ref={(ref) => {
        // setVideoRef(ref)
      }}
      controls
      controlsList="nodownload nofullscreen noremoteplayback disablePictureInPicture"
      onPlay={() => {
        // setStartVideo(true)
        // setFinished(false)
      }}
      onEnded={() => {
        // setFinished(true)
        // setStartVideo(false)
      }}
    >
      <source
        src={cdn("/images/optic/videos/E_SANTE_V11.mp4")}
        type="video/mp4"
      />
      Sorry we are unable to process the video
    </video>
  )
}

export default withSurveyController(Demonstration as any) as any
