import Compliance from "lib/api-compliance"
import { omit } from "lodash"
import { Prospect } from "types/entity"
import { CustomerApi } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  SIGNUP_REQUEST,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  RESET_SIGNUP_STORE,
} from "./constants"

const actions = {
  request: (payload: Prospect): Action<Partial<CustomerApi>> => {
    return {
      type: SIGNUP_REQUEST,
      payload: omit(
        new Compliance<Prospect, CustomerApi>(payload).please() as CustomerApi,
        "nir"
      ),
    }
  },
  error: (error: string): Action<Message> => ({
    type: SIGNUP_ERROR,
    payload: { text: error || "Error signup", type: "error" },
  }),

  success: (success?: string): Action<Message> => ({
    type: SIGNUP_SUCCESS,
    payload: {
      text: success || "Nous vous avons envoyé un SMS !",
      type: "success",
    },
  }),
  reset: (): ActionWithoutPayload => ({ type: RESET_SIGNUP_STORE }),
}
export default actions
