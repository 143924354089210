import { Rule } from "antd/lib/form"
import dayjs, { Dayjs } from "dayjs"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { GenericModal } from "components/Modal/Modal"
import SelectPatient from "components/SelectPatient"
import { Information } from "components/Title/Title"
import { cdn } from "core/cdn"
import { SURVEY_PAGE } from "core/constants"
import { withNirReader } from "hocs"
import { languages } from "lib/languages"
import { capitalize, formatNirFromMask } from "lib/utils"
import { BirthPlace, Customer, Relative } from "types/entity"
import { Dispatcher } from "types/redux"
import { Patient } from "types/sesam"
import { InsertRelativeStore, NirReaderResponse } from "types/store"
import { InputNames } from "types/props"

import { insertRelativeRequest, resetRelativeStore } from "./services/actions"

const InsertRelative = (
  props: InsertRelativeStore & { withNirReaderResponse?: NirReaderResponse }
) => {
  const [relative, setRelative] = useState<
    Partial<Relative | Customer> | undefined
  >()
  const navigate = useNavigate()
  const [consentRelativeVisible, setConsentRelativeVisible] = useState(false)
  const [newRelative, setNewRelative] = useState<Partial<Relative> | undefined>(
    undefined
  )
  const handleInsert = (relative: Partial<Relative>) => {
    const formatNir = relative.nir
      ? formatNirFromMask(relative.nir as string)
      : undefined
    const formatted = {
      ...relative,
      nir: formatNir,
    }
    props.insert(formatted as Relative)
  }

  useEffect(() => {
    if (props.success) {
      props.reset()
      navigate(SURVEY_PAGE)
    }
  }, [props.success])

  const patientsInNir = props.withNirReaderResponse
    ? { ...props.withNirReaderResponse }
    : undefined
  if (patientsInNir && !relative) {
    return (
      <SelectPatient
        patients={patientsInNir as NirReaderResponse}
        onSelect={(patient: Partial<Patient>) => {
          setRelative(patient)
        }}
      />
    )
  }

  const inputs = [
    {
      name: "gender",
      required: true,
      label: capitalize(languages.inputThe("gender")),
    },
    {
      name: "first_birth_firstname",
      label: <span>
        {`${languages.label_input_the_male} ${languages.first} `}
        <b style={{ textDecoration: "underline" }}>
          {languages.first_firstname}
        </b>
        {languages.of_birth}
      </span>,
      condition: {
        label: languages.relativeUseAnother("Prénom"),
        type: "checkbox", filled: { name: "firstname", required: true, label: capitalize(languages.inputThe("firstname")) },
      }
    },
    {
      name: "birth_lastname",
      label: capitalize(languages.inputThe("birth_lastname")),
      condition: {
        label: languages.relativeUseAnother("Nom"),
        type: "checkbox", filled: { name: "lastname", required: true, label: capitalize(languages.inputThe("lastname")) },
      }
    },
    {
      label: capitalize(languages.inputThe("nir")),
      name: "nir",
      required: false,
      default: {
        value: "",
        warning: {
          title: languages.noNirTitleModal,
          contentText: languages.noNirContentModal,
          acceptText: languages.byPassNir_relative,
          cancelText: languages.RelativeHasNir,
        },
        label: languages.byPassNir_relative,
      },
    },
    {
      name: InputNames.INSEE_CODE,
      label: "",
      type: "hidden",
    },
    {
      name: "birthdate",
      label: capitalize(languages.inputThe("birthdate")),
      required: true,
      rules: [
        {
          // date input accept all birthdate except > now
          async validator(rule: Rule, value: Dayjs) {
            let date: Dayjs | undefined = undefined
            if (typeof value === "string") date = dayjs(value, "DDMMYYYY", true)
            const now = dayjs()
            if (date && date.isValid() && date < now)
              return await Promise.resolve()
            else return await Promise.reject(languages.dateInvalid)
          },
        },
      ],
    },
    {
      name: InputNames.BIRTH_COUNTRY, type: "hidden", default: {
        value: `${BirthPlace.FRANCE}`, label: ""
      }
    },
    { name: InputNames.BIRTH_LOCATION, required: true, label: languages.inputYourRelativeBirthCity },
  ]
  return (
    <Card
      subtitle={languages.enterRelativeInformations}
      message={props.message}
    >
      <FormPage
        loading={props.loading}
        prospect={relative}
        inputs={inputs}
        onFinish={(values) => {
          setConsentRelativeVisible(true)
          setNewRelative(values as Partial<Relative>)
        }}
        acceptText={languages.addRelative}
        onCancel={() => {
          setRelative(undefined)
          props.reset()
          navigate(SURVEY_PAGE)
        }}
      />
      <GenericModal
        visible={consentRelativeVisible}
        title={languages.enterRelativeInformations}
        acceptText={languages.IHaveRelativeConsent}
        acceptButtonProps={{
          danger: false,
          loading: props.loading,
          disabled: props.loading,
        }}
        onAccept={() => {
          newRelative && handleInsert(newRelative)
          props.reset()
        }}
        onCancel={() => {
          setConsentRelativeVisible(false)
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            style={{ paddingBottom: "50px" }}
            src={cdn("/images/proche_picto.svg")}
            alt=""
          />
          <Information
            className={"font-size-xlarge"}
            text={languages.relativeConsent}
          />
        </div>
      </GenericModal>
    </Card>
  )
}

const mapStateToProps = ({
  insertRelative,
}: {
  insertRelative: InsertRelativeStore
}) => {
  return insertRelative
}

const mapDispatchToProps = (dispatch: Dispatcher) => {
  return {
    insert: (relativeValues: Relative) =>
      dispatch(insertRelativeRequest(relativeValues)),
    reset: () => dispatch(resetRelativeStore()),
  } as Partial<InsertRelativeStore>
}

const InsertRelativeWithNirReader = withNirReader(InsertRelative)
const InsertRelativePage = InsertRelativeWithNirReader

export default connect(mapStateToProps, mapDispatchToProps)(InsertRelativePage)
