import Checkbox from "antd/lib/checkbox"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { RightsModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Information } from "components/Title/Title"
import profileActions from "containers/Profile/services/actions"
import surveyActions from "containers/Survey/services/actions"
import {
  getCurrentPatientNir,
  getCurrentSelectedPatientId,
  isCurrentPatientExempted,
} from "containers/Survey/services/selector"
import { Answer, Reply } from "containers/Survey/types"
import { cdn } from "core/cdn"
import { SURVEY_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { FOR_PATIENT } from "models/survey/constants"
import { Customer } from "types/entity"
import { CustomerType, FormPageProps } from "types/props"
import { Dispatcher, Message } from "types/redux"
import { NirStore, SurveyStore } from "types/store"

import styles from "./Nir.module.scss"
import actions from "./services/actions"

interface NirProps {
  loading: boolean
  success: boolean
  message?: Message
  answers: Answer[]
  reset: () => void
  addNir: (customer: number, p: Partial<Customer>) => void
  // below, Survey actions
  // the current question survey should be PATH.nir (exemption)
  previous: () => void
  next: () => void
  set: (replies: Reply[]) => void
  unset: () => void
  resetSurvey: () => void
  customerType?: CustomerType
}

const NirForm: React.FC<
  NirProps & Pick<FormPageProps, "onFinish" | "onCancel">
> = (props) => {
  const texts =
    props.customerType === "patient"
      ? {
          label: undefined,
          acceptText: languages.byPassNir,
          cancelText: languages.IHaveNir,
          defaultLabel: languages.byPassNir,
        }
      : {
          label: languages.enterRelativeNir,
          acceptText: languages.byPassNir_relative,
          cancelText: languages.RelativeHasNir,
          defaultLabel: languages.byPassNir_relative,
        }
  const inputsObj = [
    {
      name: "nir",
      required: false,
      label: texts.label,
      default: {
        value: "",
        warning: {
          title: languages.noNirTitleModal,
          contentText: languages.noNirContentModal,
          acceptText: texts.acceptText,
          cancelText: texts.cancelText,
        },
        label: texts.defaultLabel,
      },
    },
  ]
  return (
    <FormPage
      inputs={inputsObj}
      loading={props.loading}
      acceptText={languages.next}
      onCancel={props.onCancel}
      onFinish={props.onFinish}
    ></FormPage>
  )
}
const Nir: React.FC<NirProps> = (props): JSX.Element => {
  const navigate = useNavigate()
  const customer = useSelector(getCurrentSelectedPatientId)
  const customerHasNir: boolean = useSelector(getCurrentPatientNir)
  const customerHasExemption = useSelector(isCurrentPatientExempted)

  // userExemptionValue is what the user checked
  const [userExemptionValue, setUserExemptionValue] =
    useState(customerHasExemption)

  const [exemptionModalVisible, setExemptionModalVisible] = useState(false)

  const customerType: CustomerType = !Number.isInteger(customer)
    ? "unknown"
    : customer === FOR_PATIENT
    ? "patient"
    : "relative"

  const setSurveyExemptionAnswer = (value: boolean) => {
    props.unset()
    props.set([{ title: "exemption", value }])
  }

  const next = () => {
    props.next()
    props.reset()
    navigate(SURVEY_PAGE)
  }

  const previous = () => {
    props.previous()
    props.reset()
    navigate(SURVEY_PAGE)
  }

  useEffect(() => {
    if (customerType === "unknown") {
      props.resetSurvey()
      navigate(SURVEY_PAGE)
    }
  }, [customerType])

  return (
    <Card
      message={props.message}
      imgUrl={
        customerHasNir
          ? cdn("/images/carte_vitale_illustration.svg")
          : undefined
      }
    >
      <PreviousButton
        onClick={() => {
          previous()
        }}
      />
      {!customerHasNir ? (
        <NirForm
          {...props}
          customerType={customerType}
          onFinish={(values) => {
            if (values.nir)
              props.addNir(customer as number, {
                nir: values.nir as string,
              })
            else next()
          }}
          onCancel={() => {
            previous()
          }}
        ></NirForm>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={styles.CheckboxAME}>
            <Checkbox
              checked={userExemptionValue}
              disabled={customerHasExemption}
              onChange={(event: CheckboxChangeEvent) => {
                setUserExemptionValue(event.target.checked)
                if (event.target.checked) setExemptionModalVisible(true)
              }}
            >
              <Information
                style={{ margin: 0 }}
                text={
                  customerHasExemption
                    ? languages.WeAutomaticallyDetectedBenefit
                    : customerType === "patient"
                    ? languages.ameIBenefit
                    : languages.ameRelativeBenefit
                }
              />
            </Checkbox>
          </div>
          <Buttons>
            <Button
              type="primary"
              wide="long"
              onClick={() => {
                setSurveyExemptionAnswer(userExemptionValue)
                next()
              }}
            >
              {languages.next}
            </Button>
          </Buttons>
        </div>
      )}

      <RightsModal
        relative={customerType === "relative"}
        visible={exemptionModalVisible}
        onAccept={() => {
          setUserExemptionValue(true)
          setExemptionModalVisible(false)
        }}
        onCancel={() => {
          setUserExemptionValue(false)
          setExemptionModalVisible(false)
        }}
      />
    </Card>
  )
}

const s = ({
  survey,
  nir,
}: {
  survey: SurveyStore
  nir: NirStore
}): NirProps => {
  return {
    ...nir,
    answers: survey.answers,
  } as NirProps
}

const p = (dispatch: Dispatcher): Partial<NirProps> => {
  return {
    reset: () => dispatch(actions.reset()),
    previous: () => dispatch(surveyActions.previous()),
    next: () => dispatch(surveyActions.next()),
    addNir: (patient: number, p: Partial<Customer>) =>
      patient === FOR_PATIENT
        ? dispatch(profileActions.changePatientRequest(p))
        : dispatch(profileActions.changeRelativeRequest({ ...p, id: patient })),
    set: (replies: Reply[]) =>
      dispatch(surveyActions.setStepReplies({ replies })),
    unset: (unReplies: Reply[]) =>
      dispatch(surveyActions.unsetStepReplies({ replies: unReplies })),
    resetSurvey: () => dispatch(surveyActions.reset()),
  } as Partial<NirProps>
}

export default connect(s, p)(Nir)
