import { ACTION, Question, QUESTION_TYPE, Reply, UiView } from "containers/Survey/types"
import { cdn } from "core/cdn"
import { CONSENT_PAGE, DIAGNOSTIC, GET_DOCTOR_PAGE, INSERT_RELATIVE_PAGE, NIR_AND_EXEMPTION_PAGE, PAYMENT_PAGE, TIMELINE_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { PATH } from "models/survey/_paths"

import { DOCTOR_AVAILABLE } from "./constants"

export const booleanSubReplies: Reply[] = [
  {
    title: languages.yes,
    action: ACTION.subquestion,
    value: true,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.no,
    value: false,
    ui: {
      view: "button",
    },
  },
]

export const booleanReplies: Reply[] = [
  {
    title: languages.yes,
    value: true,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.no,
    value: false,
    ui: {
      view: "button",
    },
  },
]

const relative: Question = {
  title: languages.questionWhichRelative,
  // ui: { view: "checkbox" },
  id: PATH.relative,
  multipleReplies: false,
  replies: [],
  defaultReply: {
    title: languages.addARelative,
    value: INSERT_RELATIVE_PAGE,
    action: ACTION.redirect,
  },
}

export const patient: Question = {
  title: languages.questionForWho,
  id: PATH.patient,
  subquestion: relative,
  multipleReplies: false,
  replies: [],
}

export const inform_anamnesis: Question = {
  title: languages.inform_anamnesis,
  imgUrl: cdn("/images/anamnese_illustration.svg"),
  id: PATH.inform_anamnesis,
  multipleReplies: false,
  replies: [],
  defaultReply: {
    ui: { type: "primary" },
    title: languages.continue,
    value: -1,
  },
}

export const not_adapted: Question = {
  title: languages.notAdapted,
  id: PATH.not_adapted,
  multipleReplies: false,
  replies: [
    {
      action: ACTION.nothing,
      title: languages.childLessThanSixthen,
      imgUrl: cdn("images/optic/young_person.svg"),
      value: "",
    },
    {
      action: ACTION.nothing,
      title: languages.peoplesOlderThanSeventyFive,
      imgUrl: cdn("images/optic/old_person.svg"),
      value: "",
    },
  ],
  defaultReply: {
    ui: { type: "primary" },
    title: languages.continue,
    value: -1,
  },
}

export const remove_lenses: Question = {
  title: languages.youNeedToRemoveLensesBeforeConsultation,
  id: PATH.remove_lenses,
  multipleReplies: false,
  replies: [],
  defaultReply: {
    ui: { type: "primary" },
    title: languages.continue,
    value: -1,
  },
}

export const consultation_time: Question = {
  title: languages.averageConsultationTime,
  id: PATH.consultation_time,
  multipleReplies: false,
  replies: [
    {
      action: ACTION.nothing,
      title: " > 40 minutes",
      imgUrl: cdn("images/optic/clock_image.svg"),
      value: "",
    },
  ],
  defaultReply: {
    ui: { type: "primary" },
    title: languages.continue,
    value: -1,
  },
}

export const consent: Question = {
  id: PATH.consent,
  title: "do-not-need-title-because-it-is-a-redirection-step",
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: CONSENT_PAGE,
}

export const diagnostic: Question = {
  title: "Diagnostic",
  id: PATH.diagnostic,
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: DIAGNOSTIC,
}

export type TimelineStep =
  | "initialize"
  | "anamnesis"
  | "tutorial-video"
  | "orthoptist"
  | "ophtalmologist"
export function timelineValue(step: TimelineStep) {
  switch (step) {
    case "initialize":
      return {
        path: PATH.timeline_initialize,
        title: languages.initial_step,
        url: "parcours",
      }
    case "anamnesis":
      return {
        path: PATH.timeline_anamnesis,
        title: languages.get_ready_for_next_step(languages.question_step),
        url: "prochaine-etape-anamnese",
      }
    case "tutorial-video":
      return {
        path: PATH.timeline_tutorial,
        title: languages.get_ready_for_next_step(languages.demo),
        url: "prochaine-etape-demonstration-des-machines",
      }
    case "orthoptist":
      return {
        path: PATH.timeline_orthoptist,
        title: languages.get_ready_for_next_step(languages.call_ortho),
        url: "prochaine-etape-orthoptiste",
      }
    case "ophtalmologist":
      return {
        path: PATH.timeline_ophtalmologist,
        title: languages.get_ready_for_next_step(languages.call_ophtalmologist),
        url: "prochaine-etape-ophtalmologue",
      }
    default:
      return
  }
}

export const timeline = (step: TimelineStep): Question => ({
  imgUrl: cdn(`/images/optic/timeline/frise_step_${step}.svg`),
  title: timelineValue(step).title,
  id: timelineValue(step).path,
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: TIMELINE_PAGE,
})

export const doctor_available: Question = {
  title: languages.anamneseDoctorAvailableQuestion,
  id: PATH.doctor_available,
  multipleReplies: false,
  replies: [
    {
      title: languages.doNotHaveDoctor,
      value: DOCTOR_AVAILABLE.DOES_NOT_EXIST,
    },
    {
      title: languages.doctorNotAvailable,
      value: DOCTOR_AVAILABLE.EXISTS_BUT_UNAVAILABLE,
    },
  ],
}

export const exemption: Question = {
  id: PATH.exemption,
  title: "do-not-need-title-because-it-is-a-redirection-step",
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: NIR_AND_EXEMPTION_PAGE,
}
export const payment: Question = {
  id: PATH.payment,
  title: "paiement do-not-need-title-because-it-is-a-redirection-step",
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: PAYMENT_PAGE,
}

export const choice_of_doctor_doctor_available = {
  ...doctor_available,
  id: PATH.choice_of_doctor_doctor_available,
}

export const choice_of_doctor: Question = {
  title: languages.anamneseChoiceOfDoctor,
  id: PATH.choice_of_doctor,
  subquestion: choice_of_doctor_doctor_available,
  replies: [
    {
      title: languages.anamneseConsultNow,
      value: false,
      action: ACTION.subquestion,
    },
    {
      title: languages.anamneseChooseADoctor,
      value: GET_DOCTOR_PAGE,
      action: ACTION.redirect,
    },
  ],
}

export const call_ortho_on_going: Question = {
  title: "Consultation avec l'orthoptiste en cours",
  id: PATH.call_ortho_on_going,
  imgUrl: cdn("/images/tlc_commence_illustration.svg"),
  multipleReplies: false,
  replies: [],
}

export const call_ophta_on_going: Question = {
  title: "Consultation avec l'ophtalmologue en cours",
  id: PATH.call_ophta_on_going,
  imgUrl: cdn("/images/tlc_commence_illustration.svg"),
  multipleReplies: false,
  replies: [],
}

export const ineligible_reason: Question = {
  title:
    "Veuillez noter que la téléconsultation n'est pas adaptée pour les motifs suivants :",
  id: PATH.ineligible,
  informationText: languages.ineligibleReasonInformation,
  multipleReplies: false,
  replies: [
    {
      imgUrl: cdn("/images/ineligible/picto_bebe.svg"),
      title: "Les enfants de moins de 3 ans",
      value: "Les enfants de moins de 3 ans",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_ado.svg"),
      title: "Les mineurs non accompagnés",
      value: "Les mineurs non accompagnés",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_prolongation_arret_de_travail.svg"),
      title: "Prolongation d'arrêt de travail",
      value: "Prolongation d'arrêt de travail",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_certificat_sport.svg"),
      title: "Certificat d'aptitude (sport, crèche)",
      value: "Certificat d'aptitude (sport, crèche)",
      action: ACTION.nothing,
    },
    {
      imgUrl: cdn("/images/ineligible/picto_ordo.svg"),
      title: "Prescription de traitements psychoactifs de dépendance",
      value: "Prescription de traitements psychoactifs de dépendance",
      action: ACTION.nothing,
    },
  ],
  defaultReply: {
    title: "j'ai compris et je continue",
    value: true,
    ui: { type: "primary" },
  },
  ui: { view: "inline" as UiView },
}
