import React from "react"
import Layout from "antd/lib/layout"
import styles from "./Loading.module.scss"
import stylesError from "containers/Errors/Errors.module.scss"
import Loader from "components/Loaders/Loader/Loader"

const Loading: React.FC = (): JSX.Element => (
  <Layout className={stylesError.Layout}>
    <div className={styles.Loading} data-testid="Loading">
      <Loader />
    </div>
  </Layout>
)

export default Loading
