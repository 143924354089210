import { ArrowLeftOutlined } from "@ant-design/icons"
import { useStripe } from "@stripe/react-stripe-js"
import { PaymentIntentResult } from "@stripe/stripe-js"
import { Checkbox } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { first } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import Loader from "components/Loaders/Loader/Loader"
import { GenericModal } from "components/Modal/Modal"
import { Important } from "components/Title/Title"
import Consent from "containers/Consent/Consent"
import { Answer } from "containers/Survey/types"
import { languages } from "lib/languages"
import { CardsStore } from "types/store"
import { CardInformation } from "types/stripe"

import { CreditCardForm } from "./AddCard"
import { SelectCard } from "./SelectCard"
import actions from "./services/actions"

export const PaymentCardForm: React.FC<
  Partial<CardsStore> & { onPay: (a: Answer[]) => void }
> = (props): JSX.Element => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const [paymentRefusedModalVisible, setPaymentRefusedModalVisible] =
    useState<boolean>(false)
  const [currentCard, setCurrentCard] = useState<CardInformation | undefined>(
    first(props.cards)
  )
  const [wishToAddPaymentMethod, setWishToAddPaymentMethod] =
    useState<boolean>(true)
  const [consentChoice, setConsentChoice] = useState<boolean>(false)
  const [consentVisibility, setConsentVisibility] = useState(false)
  useEffect(() => {
    if (!props.cards && props.get) props.get()
    if (first(props.cards)) {
      setWishToAddPaymentMethod(false)
      setCurrentCard(first(props.cards))
    }
  }, [props.cards])

  useEffect(() => {
    setPaymentRefusedModalVisible(props.hasPaymentError ?? false)
  }, [props.hasPaymentError])

  useEffect(() => {
    // Requires action if payment_intent_client_secret is set
    dispatch(actions.hasStripeError(false))
    if (props.payment_intent_client_secret) {
      stripe &&
        stripe
          .handleCardAction(props.payment_intent_client_secret)
          .then((response: PaymentIntentResult) => {
            if (response.error) {
              dispatch(actions.hasStripeError(true))
              dispatch(actions.attachError(response.error.message as string))
            } else if (response.paymentIntent) {
              const paymentId = response.paymentIntent.id
              const send = []
              send.push({ value: [paymentId], questionId: "payment_intent_id" })
              props.onPay(send)
            }
          })
          .catch((e) => {
            console.error(e, {
              route: "stripe::handleCardAction",
            })
            dispatch(actions.hasStripeError(true))
            dispatch(actions.attachError(languages.genericErrorStripe))
          })
    }
  }, [props.payment_intent_client_secret])

  const pay = () => {
    const send = []
    send.push({
      value: [currentCard?.id],
      questionId: "payment_method_id",
    } as Answer)
    props.onPay(send)
  }

  if (!props.cards) return <Loader />
  return (
    <>
      {props.cards?.length && wishToAddPaymentMethod ? (
        <span className="link" onClick={() => setWishToAddPaymentMethod(false)}>
          <ArrowLeftOutlined /> {languages.backToPaymentMethod}
        </span>
      ) : null}
      {!props.cards?.length || wishToAddPaymentMethod ? (
        <CreditCardForm
          {...(props as any)}
          added={() => setWishToAddPaymentMethod(false)}
        />
      ) : (
        <>
          <Buttons direction="vertical" style={{ marginTop: "15px" }} size={30}>
            <SelectCard
              cards={props.cards}
              defaultValue={currentCard}
              onSelect={setCurrentCard}
            />
            <span
              className="link"
              onClick={() => setWishToAddPaymentMethod(true)}
            >
              {languages.addPaimentMethod}
            </span>
          </Buttons>

          <Buttons direction="vertical">
            <div
              style={{
                maxWidth: "900px",
                margin: "auto",
                marginTop: "-50px",
                marginBottom: "50px",
              }}
            >
              <Checkbox
                checked={consentChoice}
                onChange={(event: CheckboxChangeEvent) => {
                  setConsentChoice(event.target.checked)
                }}
              >
                {languages.IConfirmmyConsentDataChoice}
                <span
                  className="more-information"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setConsentVisibility(true)
                  }}
                >
                  {" "}
                  En savoir plus...
                </span>
              </Checkbox>
            </div>
            <Button
              loading={props.loading}
              disabled={!consentChoice || props.loading}
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                pay()
              }}
            >
              {languages.useCard}
            </Button>
          </Buttons>
        </>
      )}
      <Consent
        visible={consentVisibility}
        onCancel={() => {
          setConsentVisibility(false)
        }}
        onAccept={() => {
          setConsentChoice(true)
          setConsentVisibility(false)
        }}
      ></Consent>
      <GenericModal
        visible={paymentRefusedModalVisible}
        iconError
        titleProps={{ size: "small" }}
        title={languages.errorPaymentModalTitle}
        contentText={languages.errorPaymentModal_html}
        acceptButtonProps={{ danger: false }}
        acceptText={languages.errorPaymentModalRetry}
        cancelText={languages.errorPaymentModalChangeCard}
        onAccept={() => {
          setPaymentRefusedModalVisible(false)
          // Si l'utilisateur est au paiement, on le lance à nouveau
          !wishToAddPaymentMethod && pay()
        }}
        onCancel={() => {
          setPaymentRefusedModalVisible(false)
          setWishToAddPaymentMethod(true)
        }}
      ></GenericModal>
    </>
  )
}
