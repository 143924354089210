export const [
  UNSET_STEP,
  SET_STEP,
  NEXT_STEP,
  PREVIOUS_STEP,
  CREATE_STEP,
  SUBMIT_SURVEY_REQUEST,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_ERROR,
  INIT_SURVEY,
  CANCEL_SURVEY,
  SET_CUSTOMER_TYPE,
  REMOVE_STEP,
  RESET_SURVEY_STORE,
  SET_CURRENT_STEP,
  UPDATE_TIMELINE,
] = [
  "UNSET_STEP",
  "SET_STEP",
  "NEXT_STEP",
  "PREVIOUS_STEP",
  "CREATE_STEP",
  "SUBMIT_SURVEY_REQUEST",
  "SUBMIT_SURVEY_SUCCESS",
  "SUBMIT_SURVEY_ERROR",
  "INIT_SURVEY",
  "CANCEL_SURVEY",
  "SET_CUSTOMER_TYPE",
  "REMOVE_STEP",
  "RESET_SURVEY_STORE",
  "SET_CURRENT_STEP",
  "UPDATE_TIMELINE",
]

export const [
  STEP_DOCTORS_CHOICE,
  STEP_PATIENT_CHOICE,
  STEP_RELATIVES_CHOICE,
  STEP_SLOTS_CHOICE,
] = [
  "STEP_DOCTORS_CHOICE",
  "STEP_PATIENT_CHOICE",
  "STEP_RELATIVES_CHOICE",
  "STEP_SLOTS_CHOICE",
]

export const RESET_SURVEY_REPLY = "RESET_SURVEY_REPLY"
