import config from "react-global-configuration"

import { ADMIN_PAGE, LOBBY_PAGE } from "core/constants"
import { request } from "lib/request"
import { ERROR_API, ERROR_READER_SOCKET, PayloadError } from "types/error"

import { languages } from "./languages"

// actual errors since it's technically completing the response...
export async function handleApiErrors(
  response: Response
): Promise<Response | string> {
  if (!response.ok) {
    try {
      return response
        .json()
        .then((json) => getApiError({ ...json, code: response.status }))
    } catch (e) {
      console.error(`unable to process error ${response?.status} : ${response?.statusText}`, {
        route: response?.url,
      });
      throw languages.defaultErrorForm;
    }
  }
  return response
}

export const getApiError = (responseError: PayloadError) => {
  switch (responseError.code) {
    case ERROR_API.TIMEOUT_ERROR:
      if (
        window.confirm(
          "Délai de requête dépassé. Veuillez vérifier votre connexion."
        )
      )
        window.location.reload()
      throw ""
    case ERROR_API.UNAUTHORIZED:
      request(config.get("patient.logout"), { method: "POST" })
        .then(() => (window.location.href = LOBBY_PAGE))
        .catch((error) => {
          console.error(error, { route: "customers/logout" })
        })
      throw languages.unauthorized
    case ERROR_API.TOO_MANY_REQUEST:
      throw languages.TooManyRequest
    case ERROR_API.EXPIRED_TOKEN:
      setTimeout(() => window.location.reload(), 3000)
      throw languages.expiredSession
    case ERROR_API.TOO_MANY_CALLS_FOR_A_CUSTOMER:
      throw languages.tooManyCallsForACustomer
    case ERROR_API.MISMATCHING_INFORMATION:
      throw languages.mismatchingErrorForm
    case ERROR_API.PHONE_EXISTING_ERROR:
      throw languages.phoneExistingErrorForm
    case ERROR_API.PAYLOAD_ERROR:
      throw languages.payloadErrorForm
    case ERROR_API.EXISTING_ORDER:
      throw languages.existingOrderError
    case ERROR_API.CONFLICT:
      throw languages.conflictError
    case ERROR_API.NEXMO_ERROR:
      throw languages.nexmoError
    case ERROR_API.STRIPE:
      throw { message: languages.cardRefused, code: ERROR_API.STRIPE }
    case ERROR_API.DELETED_INSTALLATION:
    case ERROR_API.FORBIDDEN:
    case ERROR_API.EXPIRED_TOKEN_PHARMACY:
      localStorage.removeItem("identifier")
      window.location.href = ADMIN_PAGE
      throw "Installation purgée"
    default:
      throw languages.defaultErrorForm
  }
}

export const handleGenericApiError = (
  response: Response
): Promise<Response | string> | Response => {
  if (
    (response.status >= ERROR_API.SUCCESS &&
      response.status < ERROR_API.MULTIPLE_CHOICE) ||
    response.status === ERROR_API.NOT_FOUND
  ) {
    return response
  } else {
    return handleApiErrors(response)
  }
}

export const formatReaderSocketErrorByType = (errorType: string): string => {
  switch (errorType) {
    case ERROR_READER_SOCKET.GENERAL_ERROR:
      return languages.readerSocketGenericError
    case ERROR_READER_SOCKET.SOFT_CARD_READ_ERROR:
    case ERROR_READER_SOCKET.PCSC_READER_ERROR:
    case ERROR_READER_SOCKET.UNKNOWN_ERROR:
    case ERROR_READER_SOCKET.MUTED_OR_UNKNOWN_CARD_ERROR:
      return languages.readerSocketReadError
    default:
      return languages.readerSocketGenericError
  }
}
