import config from 'react-global-configuration';
import { BirthPlace } from 'types/entity';
import { languages } from 'lib/languages';

const FRENCH_LOCATION = ['city_district', 'municipality', 'oversea_community_municipality'];
const FOREIGN_LOCATION = ['foreign_territory'];
const UNKNOWN_INSEE_CODE = '99999';

export const getDefaultLocalisationList = () => ([
    { fullname_with_suffix: languages.unknown, insee_code: UNKNOWN_INSEE_CODE },
]);

interface locationsPayload {
    created_at: string,
    fullname: string,
    fullname_with_junction: string,
    fullname_with_suffix: string,
    insee_code: string,
    location_type: string,
    name: string,
}

export default function searchLocation(birthdate, selectedBirthLocation = `${BirthPlace.FRANCE}`, searchedValue: string = '') {
    return fetch(`/api${config.get('locations')}`, { method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ date: birthdate && `${birthdate[4]}${birthdate[5]}${birthdate[6]}${birthdate[7]}-${birthdate[2]}${birthdate[3]}-${birthdate[0]}${birthdate[1]}`,
            capitalize_article: true,
            name: searchedValue,
            location_types: selectedBirthLocation === `${BirthPlace.FRANCE}` ? FRENCH_LOCATION : FOREIGN_LOCATION }) }).then((res: any) => res.json())
        .then((r: { status: string, locations: Array<locationsPayload>}) => {
            if (r.status === 'ok') {
                return r.locations;
            }
            return getDefaultLocalisationList();
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error, { route: `${config.get('locations')}` });
            return getDefaultLocalisationList();
        });
}
