import Col from "antd/lib/col"
import Row from "antd/lib/row"
import React from "react"
import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import { Input } from "components/Input"
import { Important, Subtitle } from "components/Title/Title"
import { languages } from "lib/languages"
import { CardsStore } from "types/store"
import { CardInformation } from "types/stripe"
import { Brand } from "./Brand"

export const Cards: React.FC<CardsStore> = (props): JSX.Element => (
  <>
    <Subtitle text={languages.yourCreditCards} />
    {props.cards ? (
      props.cards.length ? (
        props.cards.map((card: CardInformation, key: number) => (
          <div key={key}>
            <CardDisplayer
              information={card}
              loading={props.loading}
              delete={() => props.delete(card)}
            />
          </div>
        ))
      ) : (
        <Buttons>
          <Important text={languages.noCreditCardAdded} />
        </Buttons>
      )
    ) : null}
  </>
)

export const CardDisplayer: React.FC<{
  information: CardInformation
  delete?: () => void
  use?: () => void
  loading?: boolean
}> = (props): JSX.Element => {
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={2}>
          <Brand width="85px" brand={props.information.card.brand} />
        </Col>
        <Col span={17}>
          <Input
            disabled={true}
            value={`XXXX ${props.information.card.last4}`}
          />
        </Col>
        <Col span={5}>
          {props.delete && (
            <Button
              size="small"
              type="link"
              danger
              onClick={() => props.delete && props.delete()}
              loading={props.loading}
              disabled={props.loading}
            >
              {languages.deleteCard}
            </Button>
          )}

          {props.use && (
            <Button
              onClick={() => props.use && props.use()}
              loading={props.loading}
              disabled={props.loading}
            >
              {languages.useCard}
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}
