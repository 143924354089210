import { all, put, select, takeLatest } from "redux-saga/effects"
import { push } from "redux-first-history";

import { getNextDoctor } from "client/optic/selectors"
import { SET_CUSTOMER, SET_RELATIVES } from "client/services/constants"
import { CALL_HAS_CHANGED } from "containers/Call/services/constants"
import { TLC_SUCCESS } from "containers/Payment/services/constants"
import { PATH } from "models/survey/_paths"
import { FOR_PATIENT } from "models/survey/constants"
import { Customer, DoctorType, Gender, Relative } from "types/entity"
import { Call, CallState } from "types/payload"
import { CustomerType } from "types/props"
import { Action, ActionWithoutPayload, GFlow, GWatcher } from "types/redux"
import { SAVE_HARDWARE_TYPE } from "client/socket/optic/contants";

import { Question } from "../types"
import actions from "./actions"
import {
  SET_STEP,
  STEP_PATIENT_CHOICE,
  STEP_RELATIVES_CHOICE,
} from "./constants"
import {
  getCurrentPatientGender,
  getCurrentSelectedPatientId,
  getSurveyId,
  isSurveyEmpty,
} from "./selector"

import { SocketStore } from "types/store"
import { HARDWARE_NIDEK_TYPE } from "client/socket/optic/contants"
import { SET_RELATIVE_PAGE } from "core/constants";


function* createRelativesQuestion({
  payload,
}: Action<Relative[]>): GFlow<Action<Relative[]>> {
  if (payload) {
    yield put({
      type: STEP_RELATIVES_CHOICE,
      payload,
    })
  } else yield put({ type: STEP_RELATIVES_CHOICE, payload: [] })
}

function* createPatientQuestion(
  action: Action<Customer>
): GFlow<Action<Customer>> {
  if (action.payload)
    yield all([put({ type: STEP_PATIENT_CHOICE, payload: action.payload })])
}

function* manageSurveyQuestions(): GFlow<
  Action<Question | "patient" | "relative">
> {
  const currentQuestionPath = yield select(getSurveyId)
  const hardwareType = yield select(({socket}: {socket: SocketStore}) => socket?.hardwareType)
  if(hardwareType === HARDWARE_NIDEK_TYPE) {
    yield put(actions.updateTimeline());
    yield put(actions.remove(PATH.timeline_tutorial));
    yield put(actions.remove(PATH.tutorial))
  }
  if ([PATH.patient, PATH.relative].includes(currentQuestionPath)) {
    const currentPatientGender = yield select(getCurrentPatientGender)
    if(![Gender.MALE, Gender.FEMALE].includes(currentPatientGender) && currentQuestionPath === PATH.relative) {
      yield put(push(SET_RELATIVE_PAGE))
    }
    const currentCustomerId = yield select(getCurrentSelectedPatientId)
    if (currentCustomerId !== undefined) {
      yield modifyTitleQuestion(currentCustomerId, currentPatientGender)
    }
  }
}
function* modifyTitleQuestion(
  customerId: number,
  gender: Gender
): GFlow<Action<CustomerType>> {
  if (customerId === FOR_PATIENT) {
    yield put(actions.for("patient"))
    // customer is the main patient
  } else {
    // customer is a relative
    if (gender === Gender.MALE) yield put(actions.for("relative_male"))
    if (gender === Gender.FEMALE) yield put(actions.for("relative_female"))
  }
}

function* paymentSuccessFlow(
  action: Action<Call>
): GFlow<ActionWithoutPayload> {
  const call = action.payload
  if (call.category_id === DoctorType.ORTHOPTIST)
    yield put(actions.setCurrentStep(PATH.call_ortho_on_going))
  else if (call.category_id === DoctorType.OPHTALMOLOGIST)
    yield put(actions.setCurrentStep(PATH.call_ophta_on_going))
}

function* callHasChangedFlow(
  action: Action<Call>
): GFlow<ActionWithoutPayload> {
  const call = action.payload
  if (!call) return
  if (call.state === CallState.ENDED) yield put(actions.next())
  else if (
    call.state === CallState.CANCELLED_BY_DOCTOR ||
    call.state === CallState.CANCELLED_BY_PATIENT ||
    call.state === CallState.EXPIRED
  ) {
    // Call annulé / expiré

    const nextDoctor = yield select(getNextDoctor)
    const surveyIsEmpty = yield select(isSurveyEmpty)
    if (
      call.state === CallState.CANCELLED_BY_DOCTOR &&
      nextDoctor === DoctorType.ORTHOPTIST &&
      surveyIsEmpty
    ) {
      // si le call est annulé côté docteur orthoptiste, le medicalPath est cancelled, donc il ne possède pas l'anamnèse
      // Donc si par malheur le survey (anamnèse) est vide (parce que refresh par ex.), alors on doit repasser par les questions
      yield put(actions.setCurrentStep(PATH.timeline_initialize))
    } else yield put(actions.setCurrentStep(PATH.payment))
  } else {
    // Call en cours
    if (call.category_id === DoctorType.ORTHOPTIST)
      yield put(actions.setCurrentStep(PATH.call_ortho_on_going))
    if (call.category_id === DoctorType.OPHTALMOLOGIST)
      yield put(actions.setCurrentStep(PATH.call_ophta_on_going))
  }
}

function* surveyWatcher(): GWatcher {
  yield takeLatest(SET_RELATIVES, createRelativesQuestion)
  yield takeLatest(SET_CUSTOMER, createPatientQuestion)
  yield takeLatest(SET_STEP, manageSurveyQuestions)
  yield takeLatest(SAVE_HARDWARE_TYPE, manageSurveyQuestions);
  yield takeLatest(TLC_SUCCESS, paymentSuccessFlow)
  yield takeLatest(CALL_HAS_CHANGED, callHasChangedFlow)
}

export default surveyWatcher
