import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { SetupIntentResult, StripeCardElement } from "@stripe/stripe-js"
import Col from "antd/lib/col"
import Row from "antd/lib/row"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Button } from "components/Button"
import inputStyles from "components/Input/Card/Card.module.scss"
import PreviousButton from "components/Previous/PreviousButton"
import { languages } from "lib/languages"
import { CardsStore } from "types/store"

import actions from "./services/actions"

export const CreditCardForm: React.FC<CardsStore> = (props): JSX.Element => {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(props.loading)
  const [wrappedInputModalVisible, setWrappedInputModalVisible] =
    useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading])

  useEffect(() => {
    !props.clientSecret && props.create()
  }, [props.clientSecret])

  const submit = async () => {
    setLoading(true)
    if (!stripe || !elements) return

    const cardElement = elements.getElement(CardElement)

    const payment_method_data = {
      billing_details: {
        name: `${props.patient.firstname} ${props.patient.lastname}`,
      },
    }
    if (props.clientSecret && stripe) {
      dispatch(actions.hasStripeError(false))
      stripe
        .confirmCardSetup(props.clientSecret, {
          payment_method: {
            card: cardElement as StripeCardElement,
            ...payment_method_data,
          },
        })
        .then((response: SetupIntentResult) => {
          const { setupIntent, error } = response
          if (error) {
            dispatch(actions.hasStripeError(true))
            dispatch(
              actions.attachError(
                (response.error.message as string) ||
                  languages.setupIntentErrorStripe
              )
            )
          } else if (setupIntent) {
            props.attach({ setupintent_id: setupIntent.id })
            cardElement?.clear()
          }
        })
        .catch((e) => {
          console.error(e, {
            route: "stripe::confirmCardSetup",
          })
          dispatch(actions.hasStripeError(true))
          dispatch(actions.attachError(languages.genericErrorStripe))
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <MaskForInputWithEmbebedKeyboard
      visible={wrappedInputModalVisible}
      onClose={() => setWrappedInputModalVisible(false)}
    >
      <Row
        gutter={[10, 50]}
        align="middle"
        justify="space-around"
        style={{ paddingTop: "50px", paddingBottom: "50px", minWidth: "50vw" }}
      >
        <Col span={15}>
          <CardElement
            onFocus={() => setWrappedInputModalVisible(true)}
            options={{
              style: {
                base: {
                  fontSize: "35px",
                },
              },
            }}
            className={inputStyles.Card}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            size="xsmall"
            onClick={() => {
              setWrappedInputModalVisible(false)
              submit()
            }}
            loading={loading}
            disabled={loading}
          >
            Ajouter
          </Button>
        </Col>
      </Row>
    </MaskForInputWithEmbebedKeyboard>
  )
}

export const MaskForInputWithEmbebedKeyboard = (props: {
  visible: boolean
  onClose: () => void
  children: JSX.Element
}) => {
  return (
    <div
      className={props.visible ? "ant-modal-mask" : ""}
      onClick={props.onClose}
    >
      <div
        style={
          props.visible
            ? { background: "white", padding: "30px", paddingTop: "200px" }
            : {}
        }
      >
        <div style={{ display: props.visible ? "block" : "none" }}>
          <PreviousButton text={languages.previous} onClick={props.onClose} />
        </div>
        {props.children}
      </div>
    </div>
  )
}
