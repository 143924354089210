import { createLocalTracks, CreateLocalTracksOptions } from "twilio-video"
import React from "react"

export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false
  }
  return /Mobile/.test(navigator.userAgent)
})()
export async function getDeviceInfo(): Promise<MediaDeviceInfo[]> {
  return await navigator.mediaDevices.enumerateDevices()
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(
  name: PermissionName
): Promise<boolean> {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name })
      return result.state === "denied"
    } catch {
      return false
    }
  } else {
    return false
  }
}
export async function getMediasVideoChat() {
  return navigator.mediaDevices.enumerateDevices().then((devices) => {
    return devices;
  });
}

export async function createUserLocalTracks(
  options?: CreateLocalTracksOptions
) {
  const mediasVideoChat = await getMediasVideoChat()

  const audioInput =
    mediasVideoChat.find(
      (device) => device.kind === "audioinput" && device.deviceId === "default"
    ) || mediasVideoChat.find((device) => device.kind === "audioinput")

  const videoInput =
    mediasVideoChat.find(
      (device) => device.kind === "videoinput" && device.deviceId === "default"
    ) || mediasVideoChat.find((device) => device.kind === "videoinput")

  console.log("[video]", videoInput?.label)
  console.log("[audio]", audioInput?.label)
  return createLocalTracks({
    ...options,
    audio: audioInput ? { deviceId: audioInput.deviceId } : true,
    video: videoInput ? { deviceId: videoInput.deviceId } : true,
  })
}