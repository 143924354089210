import config from "react-global-configuration"
import { w3cwebsocket as W3CWebSocket } from "websocket"

import { Action } from "types/redux"
import { SocketStore } from "types/store"

import {
  SAVE_CONFIGURATION_ID,
  SET_SOCKET,
  SOCKET_RETRY_CONNECT_DECREMENT_RETRY,
  SOCKET_RETRY_CONNECT_RESET,
  SOCKET_ENGINE_RESET_DIAGNOSTIC,
} from "./constants"
import {
  SOCKET_INIT_CARD_VITALE_READER,
  SOCKET_RESET_CARD_VITALE_READER,
} from "./nir/constants"
import { SAVE_HARDWARE_TYPE, SET_ENGINE_DIAGNOSTIC } from "./optic/contants"
import { Diagnostic } from "types/payload"

const initialstate: Partial<SocketStore> = {
  numberOfRetryLeft: 0,
}

const reducer = function socketReducer(
  state = {
    ...initialstate,
  },
  action: Action<
    | W3CWebSocket
    | {
        [x: string]: unknown
      }
    | number
    | string
    | Diagnostic
    |{ [x: string]: Diagnostic }
  >
): Partial<SocketStore> {
  const newState = { ...state }
  switch (action.type) {
    case SET_SOCKET: {
      return { ...newState, client: action.payload as W3CWebSocket }
    }
    case SOCKET_RETRY_CONNECT_DECREMENT_RETRY:
      const actualRetryNumber =
        (state.numberOfRetryLeft as number) - (action.payload as number)
      return {
        ...newState,
        numberOfRetryLeft: actualRetryNumber,
      }
    case SOCKET_INIT_CARD_VITALE_READER:
      return {
        ...newState,
        stop: undefined,
        numberOfRetryLeft: config.get("timeout.nb_socket_try") as number,
      }
    case SOCKET_RESET_CARD_VITALE_READER:
      return {
        ...newState,
        stop: true,
      }
    case SOCKET_RETRY_CONNECT_RESET:
      return {
        ...newState,
        numberOfRetryLeft: config.get("timeout.nb_socket_try") as number,
      }
    case SAVE_HARDWARE_TYPE:
      return { ...newState, hardwareType: action.payload as string }
    case SAVE_CONFIGURATION_ID:
      return {
        ...newState,
        ...(action.payload as {
          [x: string]: unknown
        }),
      }
    case SOCKET_ENGINE_RESET_DIAGNOSTIC:
      return {
        ...newState,
        diagnostic: undefined,
      }
      case SET_ENGINE_DIAGNOSTIC:
        return {
          ...newState,
          diagnostic: {
            ...newState.diagnostic,
            ["engine"]: action.payload as {[x :string] : Diagnostic},
          }
        }
    default:
      return { ...newState }
  }
}

export default reducer
