import config from "react-global-configuration";
import { eventChannel, EventChannel } from "redux-saga"
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from "redux-saga/effects"
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from "websocket"

import { SocketSendMessage, TerminalInformation, WebSocketMessage } from "types/payload"
import { Action, GFlow, GWatcher } from "types/redux"
import { GlobalStore, SocketStore } from "types/store"

import {
  decrementRetryBy,
  resetNumberOfRetry,
  socketErrorClosed,
  resetDiagnostic,
} from "./actions"
import {
  SOCKET_NIR_READER_MANAGER,
  SOCKET_OPTICIAN_MANAGER,
  SOCKET_SEND_MESSAGE,
  SOCKET_SUBSCRIBE_CLIENT,
  SOCKET_LOG_TERMINAL_INFORMATION,
  SOCKET_SEND_TERMINAL_INFORMATION,
  SOCKET_ENGINE_DIAGNOSTIC_START,
} from "./constants"

import { HARDWARE_NIDEK_TYPE, SET_ENGINE_DIAGNOSTIC } from "./optic/contants"

import { MESSAGE_WEBSOCKET_DOMAIN, ACTION_WEBSOCKET_TYPE } from "./optic/contants"
import { saveHardwareType } from "../socket/optic/actions";
import { HARDWARE_LUNEAU_TYPE } from "../socket/optic/contants";

import {
  getWssHost,
  SocketPayload,
  staging,
  WEBSOCKET_OPEN_STATE,
} from "./utils"

import { request } from "lib/request"

let _client: W3CWebSocket | undefined = undefined
let binaryFileContent: Blob | undefined = undefined

export function* waitSocketInOpenState(): GFlow<W3CWebSocket | undefined> {
  // delai avant de tenter le rétablissement de la connexion WS
  const numberOfRetry = yield select(
    ({ socket }: { socket: SocketStore }) => socket?.numberOfRetryLeft
  )
  const WSS_SOCKET = getWssHost()
  const client = yield new W3CWebSocket(WSS_SOCKET)
  const isOpened = yield staging(client)

  const stopLookingForClient = yield select(
    ({ socket }: GlobalStore) => socket.stop || false
  )

  if (stopLookingForClient) {
    return undefined
  }

  if (isOpened) {
    yield all([
      put(resetNumberOfRetry()),
      put({ type: SOCKET_SUBSCRIBE_CLIENT, payload: client }),
    ])
    return client
  } else if (numberOfRetry > 0) {
    client.close()
    yield all([put(decrementRetryBy(1)), delay(3000)])
    return yield call(waitSocketInOpenState)
  } else {
    console.error(`P.3/ [WS], can't connect to WebSocket`, {
      route: "wss::waitSocketInOpenState",
    })
    yield put(resetDiagnostic());
    yield delay(2000);
    yield put({type: SET_ENGINE_DIAGNOSTIC, payload: {
      eye_refract: {status: "KO", error: "unreachable"},
      vx650: {status: "KO", error: "unreachable"}
    }})
    yield put(saveHardwareType(HARDWARE_LUNEAU_TYPE))

    return undefined
  }
}

export function* initSocket(): GFlow<W3CWebSocket | undefined> {
  // retourne le websocket courant s'il existe
  // sinon retourne un nouveau ws
  if (!_client || _client.readyState !== WEBSOCKET_OPEN_STATE) {
    _client = yield waitSocketInOpenState()
  }
  return _client
}

export function setBinaryFileContent(value: Blob | undefined): void {
  binaryFileContent = value
}

export function* getBinaryFileContent(): GFlow<Blob> {
  if (
    !binaryFileContent ||
    binaryFileContent instanceof Blob === false ||
    new Blob([binaryFileContent]).size === 0
  ) {
    yield delay(2000)
  }
  return binaryFileContent
}
export function* createSocketChannel(
  client: W3CWebSocket
): GFlow<EventChannel<unknown>> {
  return yield eventChannel((emitMessage) => {
    client.onmessage = (message: IMessageEvent) => {
      if (message.data instanceof Blob) {
        console.log("setBinaryFileContent", message.data, message.data.size, new Blob([message.data]).size);
        setBinaryFileContent(message.data)
        return
      }
      const data: WebSocketMessage = JSON.parse(message.data as string)
      if (
        data.type === MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY ||
        data.type === MESSAGE_WEBSOCKET_DOMAIN.VITAL_CARD ||
        data.type === MESSAGE_WEBSOCKET_DOMAIN.INFOS
      ) {
        emitMessage({
          data: {
            domain: data.type,
            type: data.action,
            result: data.result,
            payload: data.body,
          },
          client,
        })
      }
    }

    client.onclose = () => {
      // ici, récupération de l'état fermé du socket courant
      console.error(`[WS subscribe] Le socket courant s'est fermé`, {
        route: "wss::onclose"
      })
      const message = {
        type: "carte_vitale",
        action: "SOCKET_CLOSED",
        body: undefined,
      }
      emitMessage({
        data: {
          domain: message.type,
          type: message.action,
          payload: message.body,
        },
        client,
      })
    }

    const unsubscribe = () => {
      client.close()
    }
    return unsubscribe
  })
}

function send(client: W3CWebSocket | undefined, payload: SocketSendMessage) {
  if (client && client.readyState === WEBSOCKET_OPEN_STATE)
    client.send(JSON.stringify(payload))
}

export function* sendMessage(payload: SocketSendMessage) {
  const client: W3CWebSocket = yield call(initSocket)
  send(client, payload)
  return
}

export function* startEngineDiagnostic() {
    yield put(resetDiagnostic());
    yield sendMessage({type: MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY, action: ACTION_WEBSOCKET_TYPE.DIAGNOSTIC, body: { timeout: config.get("timeout.diagnostic") } })
}

export function* subscribeWebSocket({
  payload,
}: {
  type: string
  payload: W3CWebSocket
}): GFlow<{ data: SocketPayload }> {
  console.info("---------SOCKET LINK CLIENT----------")
  const socketChannel: EventChannel<unknown> = yield call(
    createSocketChannel,
    payload
  )
  while (true) {
    try {
      const { data } = yield take(socketChannel)
      if (data.type === "SOCKET_CLOSED") {
        console.log("%c[error] Socket Closed.", "background:red;color:white;")
        return yield put(socketErrorClosed())
      } else {
        yield call(incomingPayload, data)
      }
    } catch (err) {
      console.error(`subscribe socket error : ${err}`, {
        route: `wss::incomingPayload`,
      })
    }
  }
}

function* incomingPayload(webSocketAction: any): GFlow<Action<SocketPayload>> {
  const { domain } = webSocketAction
  // if (result !== "OK" || result ) yield 0
  console.log("Payload Receiver :", webSocketAction)
  if (domain === MESSAGE_WEBSOCKET_DOMAIN.VITAL_CARD)
    yield put({ type: SOCKET_NIR_READER_MANAGER, payload: webSocketAction })
  else if (domain === MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY)
    yield put({ type: SOCKET_OPTICIAN_MANAGER, payload: webSocketAction })
  else if(domain === MESSAGE_WEBSOCKET_DOMAIN.INFOS) {
   yield put({type: SOCKET_SEND_TERMINAL_INFORMATION, payload: webSocketAction?.payload});
  }
}

function* patchManageEngineInformation(manageEngine, terminalUuid) {
  const patchTerminalUrl = config.get("terminals.patch");
  // patch manageengine information
  if(manageEngine && Object.keys(manageEngine).length > 0) {
    const manageEngineInfo = yield request(`${patchTerminalUrl}/${terminalUuid}/manageengine`, {method: "PATCH", payload: manageEngine});
  }
}

function* patchTeamViewerInformation(teamViewer, terminalUuid) {
  const patchTerminalUrl = config.get("terminals.patch");
  // patch terminal information
  if(teamViewer && Object.keys(teamViewer).length > 0){
    const teamViewerInfo = yield request(`${patchTerminalUrl}/${terminalUuid}/teamviewer`, {method: "PATCH", payload: teamViewer});
  }
}

function* bulkPatchTerminalsInformation(payload: TerminalInformation, terminalUuid) {
  const patchTerminalUrl = config.get("terminals.patch");
  const { manageengine: manageEngine, teamviewer: teamViewer, ...body } = payload;

  const terminalInfo = yield request(`${patchTerminalUrl}/${terminalUuid}`, {method: "PATCH", payload: body });

  yield patchManageEngineInformation(manageEngine, terminalUuid);

  yield patchTeamViewerInformation(teamViewer, terminalUuid);

}

function* createOrUpdateTerminalInformation(payload: TerminalInformation) {
  const getTerminalUrl = config.get("terminals.get");
  const postTerminalUrl = config.get("terminals.post");
  const terminalUuid = sessionStorage.getItem("terminal");
  if(!terminalUuid) {
    const terminalInformation = yield request(getTerminalUrl, { method: "GET" });
    if(terminalInformation?.terminal?.guid) {
      sessionStorage.setItem("terminal", terminalInformation.terminal?.guid);
      yield bulkPatchTerminalsInformation(payload, terminalInformation.terminal?.guid);
    } else {
      const res = yield request(postTerminalUrl, {method: "POST", payload })
      sessionStorage.setItem("terminal", res?.terminal?.guid);
      yield patchManageEngineInformation(payload?.manageengine, res?.terminal?.guid);

      yield patchTeamViewerInformation(payload?.teamviewer, res?.terminal?.guid);
    }
  } else {
    const patchRes = yield bulkPatchTerminalsInformation(payload, terminalUuid);
  }
}

function* sendTerminalInformation({ payload } : any){
  const currentChromeVersion = window.navigator.userAgent;
  try {
    const { terminal, manageengine, teamviewer } = payload;
    // FIX to handle ipv6 in ipv4 information
    if(terminal.ipv4_private.length >15) {
      delete terminal.ipv4_private;
    };
    const body = {...terminal, manageengine, teamviewer, webbrowser_version: currentChromeVersion };
    yield createOrUpdateTerminalInformation(body)
  } catch (error) {
    console.error("error when uploading terminal information : ", error)
  }
}

function* logTerminalInformation() {
  yield sendMessage({type: "infos", action: ACTION_WEBSOCKET_TYPE.ALL_INFOS ,body: {}})
}

export default function* rootSocketSaga(): GWatcher {
  yield takeLeading(SOCKET_SEND_MESSAGE, sendMessage)
  yield takeLeading(SOCKET_SUBSCRIBE_CLIENT, subscribeWebSocket)
  yield takeLeading(SOCKET_LOG_TERMINAL_INFORMATION, logTerminalInformation)
  yield takeLeading(SOCKET_SEND_TERMINAL_INFORMATION, sendTerminalInformation);
  yield takeLatest(SOCKET_ENGINE_DIAGNOSTIC_START, startEngineDiagnostic)
}
