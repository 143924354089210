/* eslint-disable no-unused-vars */
import { Dayjs } from 'dayjs';
import { Customer } from './entity';

export enum ReaderState {
  INITIALIZE = 'INITIALIZE',
  INSERT = 'INSERT',
  REMOVED = 'REMOVED',
  REMOVE = 'REMOVE',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

export interface At {
  codeBudget?: string
  identifiant?: string
  orgGestion?: string
}
export interface Cmu {
  periode? : {
    fin? : string
  }
}

export interface Amc {
  numAdherent?: string
  numComplB2?: string
  numComplEDI?: string
  validiteDonnees?: string
}
export interface T_AsnPeriode {
  debut?: string
  fin?: string
}
export interface Amo {
  caisse?: string
  centreCarte?: string
  centreGestion?: string
  codeGestion?: string
  codeRegime?: string
  listePeriodesDroits?: { T_AsnPeriode?: T_AsnPeriode }
  medecinTraitant?: string
  qualBenef?: number
}

export interface Ident {
  dateCertification?: string
  naissance?: { date?: string; dateEnCarte?: string }
  nir?: string // Numéro de la carte
  nirCertifie?: string
  nomUsuel?: string
  prenomUsuel?: string
  rangDeNaissance?: number
  nomPatronymique?: string
}

export interface ListeAt {
  at1?: At
  at2?: At
  at3?: At
}
export interface T_AsnBeneficiaire {
  amc?: Amc
  amo?: Amo
  cmu?: Cmu
  ident?: Ident
  listeAt?: ListeAt
}
export interface DataCarteVitale {
    T_AsnDonneesVitale?: {
      listeBenef?: {
        T_AsnBeneficiaire?: T_AsnBeneficiaire | T_AsnBeneficiaire[]
      }
      tech?: { numSerie?: string }
    }
  }
export interface RightsPeriod {
  start? : Dayjs
  end?: Dayjs
}
export interface RightsNir {
  amc?: {
    periods?: RightsPeriod
  }
  cmu?: {
    periods?: RightsPeriod
  }
  amo? : {
    periods?: RightsPeriod
  }
}
export type Patient = Omit<Customer, 'phone' | 'email'> & RightsNir

export enum CarteVitaleStatus {
    SUCCESS = 'ok',
  }
