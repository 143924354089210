export const ITEMS_PER_CAROUSEL_PAGE = 6
export const SECOND_NEXT_ANSWER = 0.2
export const PATH_DEEP = ":"
export const DURATION_SLOT_MIN = 5
export const NUMBER_OF_SLOTS_BEFORE_NOW = 5
export const NUMBER_OF_SLOTS_AFTER_NOW = 5
export const DOCTOR_AVAILABLE = {
  EXISTS_BUT_UNAVAILABLE: true,
  DOES_NOT_EXIST: false,
}

export const FOR_PATIENT = 1
export const FOR_RELATIVE = -1
