import { useEffect, useRef, useState } from "react"
import config from "react-global-configuration"

import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import Message from "components/Message/Message"
import { Modal } from "components/Modal/Modal"
import { cdn } from "core/cdn"
import { languages } from "lib/languages"
import { Doctor, DoctorType } from "types/entity"
import { Call } from "types/payload"
import { CallStore } from "types/store"

import styles from "./Call.module.scss"
import VideoProvider, {
  VideoContext,
  VideoContextProps,
} from "./contexts/videoContext"

type VideoProps = CallStore

const doctorLabel = {
  6: "Orthoptiste",
  7: "Ophtalmologue"
}

const formatAddress = ({ address } : { address : string }) => {
  return `Cabinet : ${address || languages.not_informed_singular}`;
}

const formationLabel = {
  1: 'interne',
}

const civilityLabel = {
  "0": "Mme",
  "1": "M.",
}

const CallBar = (props: { call: Call; hangup: () => void }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 5000)
  }, [])

  const [modalVisible, setModalVisibility] = useState<boolean>(false)
  const getDoctorName = (doctor: Doctor) => {
    const Ortho = DoctorType.ORTHOPTIST === props.call.category_id
    return Ortho? `(${civilityLabel[`${doctor.sex}` || "1"]}) ${doctor.firstname} ${doctor.lastname}` : `Dr. (${civilityLabel[`${doctor.sex}` || "1"]}) ${doctor.firstname} ${doctor.lastname}`
  }

  return (
    <div id={styles.CallBar}>
      <div className={styles.DoctorInformation}>
        <div className={styles.DoctorInformationSection}>
        {getDoctorName(props.call.doctor as Doctor)}
        {
          isOpen ? <img src={cdn("icons/chevron.svg")} style={{transform: "rotate(180deg)"}} alt="chevron_down" onClick={() => setIsOpen(false)} width="18px" /> : <img src={cdn("icons/chevron.svg")} alt="chevron_up" onClick={() => setIsOpen(true)} width="18px" />
        }
        </div>
        {doctorLabel[props?.call?.category_id || 1]}
        <br />
        {`RPPS: ${(props.call.doctor as Doctor).rpps}`}
        {
          isOpen && (
            <>
              <br />
              {`Formation à la téléconsultation : ${formationLabel[props?.call?.doctor?.formation_type || 1]}`}
              <br />
              {`${languages.medicalcenter} : MedikSanté`}
              <br />
              {formatAddress(props?.call?.doctor?.clinic)}
            </>
          )
        }
      </div>
      <Buttons style={{ display: "flex", justifyContent: "center" }}>
        <div
          className={styles.hangupButton}
          onClick={() => setModalVisibility(true)}
        >
          <img src={cdn("/icons/picto_hangup.svg")} />
        </div>
      </Buttons>
      <Modal
        title={languages.confirmHangupCall}
        visible={modalVisible}
        onAccept={props.hangup}
        onCancel={() => setModalVisibility(false)}
      />
    </div>
  )
}

const Connector = (props: { join: () => void }) => {
  const connectButtonRef = useRef<HTMLElement>(null)
  const [connecting, setConnecting] = useState<boolean>(false)
  const [sec, setSec] = useState<number>(config.get("timeout.before_connect"))

  useEffect(() => {
    const timer = setTimeout(function () {
      if (!(sec - 1)) {
        if (connectButtonRef?.current && !connecting) {
          setConnecting(true)
          connectButtonRef.current.click()
        }
        clearTimeout(timer)
      } else setSec(sec - 1)
    }, 1000)
  })

  return (
    <Card
      style={{ maxWidth: "none" }}
      subtitle={languages.doctorAwaiting}
      imgUrl={cdn("/images/tlc_commence_illustration.svg")}
      imgSize="large"
    >
      <Buttons>
        <Button ref={connectButtonRef} onClick={props.join}>
          {languages.joinCallIn} {sec}
        </Button>
      </Buttons>
    </Card>
  )
}

const VideoCall: React.FC<React.PropsWithChildren<VideoProps>> = (
  props
): JSX.Element => {
  const hangup = (videoManager: VideoContextProps): void => {
    props.hangup()
    videoManager?.disconnect()
  }

  const join = (videoManager: VideoContextProps): void => {
    videoManager?.connect({
      token: props.call.customer_access_token,
      tool_token: props.call.tools_access_token,
      roomName: props.call.room_name,
    })
  }
  return (
    <VideoProvider>
      {/* Here the videos are set by VideoProvider if videoManager.connected
       is true [local-media / remote-media / tool-media should be visible] */}
      <VideoContext.Consumer>
        {(videoManager: VideoContextProps) => (
          <div>
            <Message message={props.message} />
            {!videoManager.connected ? (
              <Connector join={() => join(videoManager)} />
            ) : (
              <>
                <CallBar
                  call={props.call}
                  hangup={() => hangup(videoManager)}
                />
              </>
            )}
          </div>
        )}
      </VideoContext.Consumer>
    </VideoProvider>
  )
}

export default VideoCall
