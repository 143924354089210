export const SOCKET_SUBSCRIBE_CLIENT = "SOCKET_SUBSCRIBE_CLIENT"
export const SOCKET_EMIT_MESSAGE = "SOCKET_EMIT_MESSAGE"
export const SOCKET_INCOMING_PAYLOAD = "SOCKET_INCOMING_PAYLOAD"
export const SOCKET_IN_OPEN_STATE = "SOCKET_IN_OPEN_STATE"
export const SOCKET_LAUNCH_WORKFLOW = "SOCKET_LAUNCH_WORKFLOW"
export const SOCKET_RETRY_CONNECT_RESET = "SOCKET_RETRY_CONNECT_RESET"
export const SOCKET_SEND_CANCELED_CALL = "SOCKET_SEND_CANCELED_CALL"
export const SOCKET_SEND_MESSAGE = "SOCKET_SEND_MESSAGE"
export const SOCKET_RETRY_CONNECT_DECREMENT_RETRY =
  "SOCKET_RETRY_CONNECT_DECREMENT_RETRY"
export const SAVE_CONFIGURATION_ID = "SAVE_CONFIGURATION_ID"
export const SET_SOCKET = "SET_SOCKET"
export const SOCKET_ERROR_CLOSED = "SOCKET_ERROR_CLOSED"

export const SOCKET_OPTICIAN_MANAGER = "SOCKET_OPTICIAN_MANAGER"
export const SOCKET_NIR_READER_MANAGER = "SOCKET_NIR_READER_MANAGER"

export const SOCKET_LOG_TERMINAL_INFORMATION = "SOCKET_LOG_TERMINAL_INFORMATION"

export const SOCKET_SEND_TERMINAL_INFORMATION = "SOCKET_SEND_TERMINAL_INFORMATION"
export const SOCKET_ENGINE_DIAGNOSTIC_START = "SOCKET_ENGINE_DIAGNOSTIC_START"
export const SOCKET_ENGINE_RESET_DIAGNOSTIC = "SOCKET_ENGINE_RESET_DIAGNOSTIC"
