import styles from "./../Errors.module.scss"
import Layout from "antd/lib/layout"
import { HOME_PAGE } from "core/constants"
import { Buttons } from "components/Card/Card"
import { Button } from "components/Button"
import { cdn } from "core/cdn"
import React from "react"

const NoNetwork: React.FC<unknown> = ({}): JSX.Element => {
  return (
    <Layout className={styles.Layout}>
      <div className={styles.NotAuthorized} data-testid="Home">
        <img
          className={styles.Logo}
          src={cdn("/images/medadom_logo_blue.svg")}
          alt="Logo de Medadom"
        />
        <h1 className={styles.Title}>Vérifiez votre réseau</h1>
        <Buttons>
          <Button onClick={() => (window.location.href = HOME_PAGE)}>
            Réessayer
          </Button>
        </Buttons>
      </div>
    </Layout>
  )
}
export default NoNetwork
