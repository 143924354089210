import { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"

import { Button } from "components/Button"
import FatButton from "components/Button/FatButton/FatButton"
import { Buttons, Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import Loader from "components/Loaders/Loader/Loader"
import Message from "components/Message/Message"
import PreviousButton from "components/Previous/PreviousButton"
import { getCurrentSelectedPatientId } from "containers/Survey/services/selector"
import { cdn } from "core/cdn"
import { languages } from "lib/languages"
import { FOR_PATIENT } from "models/survey/constants"
import { Carer, CARER } from "types/entity"
import { Dispatcher } from "types/redux"
import { InsertCarerStore } from "types/store"

import { CarerSelector, InsertCarerProps } from "."
import styles from "./insertCarer.module.scss"
import { InsertRelativeCarer } from "./insertRelativeCarer"
import { ADD_PHARMACY_CARERS_REQUEST, CANCEL_PROFESSIONAL_TO_INSERT, CARERS_CLEAR_MESSAGE, GET_IDENTITY_FROM_RPPS_REQUEST } from "./services/constants"

export const InsertProfessional = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    getIdentityFromRpps: (carer: Partial<Carer>) =>
      dispatch({ type: GET_IDENTITY_FROM_RPPS_REQUEST, payload: carer }),
    pharma_insert: (carer: Carer) =>
      dispatch({ type: ADD_PHARMACY_CARERS_REQUEST, payload: carer }),
    cancelProfessionalToInsert: () =>
      dispatch({ type: CANCEL_PROFESSIONAL_TO_INSERT }),
    clearMessage: () => dispatch({ type: CARERS_CLEAR_MESSAGE }),
  })
)((props: Partial<InsertCarerProps & InsertCarerStore>) => {
  const inputs = [
    {
      name: "rpps",
      required: true,
      label: "",
    },
  ]
  const checkRpps = (carer: { rpps: string }) => {
    props.getIdentityFromRpps(carer)
  }
  useEffect(() => {
    props.clearMessage()
  }, [])
  useEffect(() => {
    if (props.success && props.professionalToInsert) {
      props.onFinish(props.professionalToInsert)
      props.cancelProfessionalToInsert()
    }
  }, [props.success])
  return (
    <>
      {props.professionalToInsert ? (
        <Card
          title={languages.addPro}
          style={{ height: "70vh", justifyContent: "center" }}
          message={props.message}
        >
          {props.professionalToInsert && (
            <PreviousButton
              onClick={() => {
                props.cancelProfessionalToInsert()
              }}
            />
          )}
          <div className="flex-column flex-center">
            <FatButton disabled>
              <div className={styles.PatientInformation}>
                <img src={cdn("/images/ps_picto.svg")} alt="" />
                <b>
                  {props.professionalToInsert?.firstname}{" "}
                  {props.professionalToInsert?.lastname}
                </b>
                <i>
                  {props.professionalToInsert?.profession ??
                    languages.healthPro}
                </i>
              </div>
            </FatButton>
            <Buttons>
              <Button
                wide="long"
                onClick={() => props.cancelProfessionalToInsert()}
              >
                {languages.cancel}
              </Button>
              <Button
                wide="long"
                type="primary"
                onClick={() => props.pharma_insert(props.professionalToInsert)}
              >
                {languages.add}
              </Button>
            </Buttons>
          </div>
        </Card>
      ) : (
        <Card message={props.message} title={languages.insertRpps}>
          <FormPage
            inputs={inputs}
            loading={props.loading}
            acceptText={languages.next}
            onFinish={(carer: { rpps: string }) => {
              checkRpps(carer)
            }}
            onCancel={() => {
              props.clearMessage()
              props.onCancel()
            }}
          />
        </Card>
      )}
    </>
  )
})
export const SelectProfessionalCarer = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    pharma_insert: (carer: Carer) =>
      dispatch({ type: ADD_PHARMACY_CARERS_REQUEST, payload: carer }),
  })
)((props: Partial<InsertCarerProps & InsertCarerStore>) => {
  const [insertCarer, setInsertCarer] = useState(false)
  const currentPatientId = useSelector(getCurrentSelectedPatientId)

  useEffect(() => {
    if (props.pharmacy_carers && !props.pharmacy_carers.length)
      setInsertCarer(true)
  }, [props.pharmacy_carers])
  return (
    <>
      {insertCarer ? (
        <InsertProfessionalOrReferent
          onCancel={() =>
            insertCarer ? setInsertCarer(false) : props.onCancel()
          }
          onFinish={props.onFinish}
        />
      ) : (
        <CarerSelector
          loading={props.loading}
          title={
            currentPatientId === FOR_PATIENT
              ? languages.whichProfessionalIsCaringYou
              : languages.whichProfessionalIsCaringYou_relative
          }
          notInTheListText={languages.addProfessional}
          carers={props.pharmacy_carers}
          onSelect={(patient: Partial<Carer>) => {
            props.onFinish(patient as Carer)
          }}
          isAdminList={true}
          notInTheList={() => setInsertCarer(true)}
          onCancel={props.onCancel}
        />
      )}
    </>
  )
})

export const InsertProfessionalOrReferent = (props) => {
  const [carerType, setCarerType] = useState<CARER>(null)
  const [secured, setSecured] = useState(!!props.unsecureInsertion)
  if (!carerType)
    return (
      <Card
        title={languages.IsaPro}
        subtitle={languages.exemplePro}
        imgUrl="images/ps_picto.svg"
      >
        <PreviousButton onClick={() => props.onCancel()} />
        <Buttons>
          <Button onClick={() => setCarerType(CARER.RELATIVE)} wide="long">
            {languages.no}
          </Button>
          <Button
            type="primary"
            onClick={() => setCarerType(CARER.PROFESSIONAL)}
            wide="long"
          >
            {languages.yes}
          </Button>
        </Buttons>
      </Card>
    )
  if (carerType === CARER.RELATIVE) {
    if (secured)
      return (
        <InsertRelativeCarer
          pharmacy={true}
          onCancel={() => {
            setSecured(!!props.unsecureInsertion)
            setCarerType(null)
          }}
          onFinish={(values) => {
            // force carertype to RELATIVE + RELATIONSHIP to OTHER
            props.onFinish({
              ...values,
            })
          }}
        />
      )
    else
      return (
        <SecurityToInsertReferent
          onCancel={() => setCarerType(null)}
          onFinish={() => setSecured(true)}
        />
      )
  } // if (carerType === CARER.PROFESSIONAL)
  else
    return (
      <InsertProfessional
        onFinish={(values) => {
          props.onFinish({ ...values })
        }}
        onCancel={() => {
          setCarerType(null)
        }}
      />
    )
}

export const SecurityToInsertReferent = (props) => {
  const adminEmail = useSelector(({ client }) => client.admin.email)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const inputs = [
    {
      name: "email",
      required: true,
      label: languages.insertAdminEmail,
    },
  ]
  const security = (values: { email: string }) => {
    setLoading(true)
    setMessage(null)
    if (values.email === adminEmail) {
      setLoading(false)
      props.onFinish()
    } else {
      setTimeout(() => {
        setLoading(false)
        setMessage({ type: "error", text: languages.incorrectFieldAdminEmail })
      }, 1000)
    }
  }

  return (
    <Card>
      <Message message={message} />
      <FormPage
        inputs={inputs}
        acceptText={languages.next}
        loading={loading}
        onFinish={security}
        onCancel={props.onCancel}
      />
    </Card>
  )
}
