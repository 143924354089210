import { concat, flatten, uniq, cloneDeep } from "lodash"
import { addMinutes, getRoundHour, getTime } from "lib/utils"
import {
  PATH_DEEP,
  ITEMS_PER_CAROUSEL_PAGE,
  DURATION_SLOT_MIN,
} from "models/survey/constants"
import { ACTION, Answer, Question, Reply, UiPosition } from "./types"

/*
 *
 */
export const get_complete_path = (pathId: string): string[] => {
  const partial_path = pathId.split(":")

  let pathToIncrement = ""
  return partial_path.map((step: string, key: number) => {
    pathToIncrement += key ? `:${step}` : `${step}`
    return pathToIncrement
  })
}

export const replace_question = (
  questions: Question[],
  question: Question
): Question[] => {
  return questions.map((_q) =>
    _q.id === (question as Question).id ? question : _q
  ) as Question[]
}
export const is_deep_path = (path: string): boolean => path.includes(PATH_DEEP)

export const get_index_of_position = (
  position: UiPosition,
  itemsLength: number
): number => {
  switch (position) {
    case "right":
      return itemsLength
    case "middle":
      return itemsLength / 2
    case "left":
    default:
      return 0
  }
}

export function split<T>(array: T[], nbElement: number): T[][] {
  const chunk = []
  while (array.length > 0) chunk.push(array.splice(0, nbElement))
  return chunk
}

export const splitCarouselPages = (
  items: Reply[],
  nbElement: number
): Reply[][] => {
  const chunk = []
  const array = cloneDeep<Reply[]>(items)
  while (array.length > 0) chunk.push(array.splice(0, nbElement))
  return chunk
}

export const recursive_get = (question: Question, path: string[]): Question => {
  if (path.indexOf(question.id) === -1) return question
  else {
    path.splice(path.indexOf(question.id), 1)
    if (!path.length) return question
    else if (question.subquestion)
      return recursive_get(question.subquestion, path)
  }
  return question
}

/*
 * from replies, give the new part of the _path
 */
export const get_sub_path = (replies: Reply[], current: Question): string[] =>
  replies
    .map(({ action }: Reply) =>
      action === ACTION.subquestion && current.subquestion
        ? current.subquestion?.id
        : undefined
    )
    .filter((path: string | undefined) => path) as string[]

/*
 * update path question regarding the lasts replies
 */
export const update_path = (
  lastReplies: Reply[],
  actualPath: string[],
  current: Question
): string[] => {
  const subPath: string[] = get_sub_path(lastReplies, current)

  const split_subPath = uniq(
    flatten(subPath.map((sub) => sub.split(PATH_DEEP)))
  )

  const path: string[] | string[][] =
    actualPath.map((step) => {
      const parentOfSub = split_subPath
        .filter((v, key) => key < split_subPath.length - 1)
        .join(PATH_DEEP)

      if (step === parentOfSub) {
        return [step, ...subPath]
      }
      return [step]
    }) || actualPath
  return flatten(path)
}

export const update_answers = (answers: Answer[], fresh: Answer): Answer[] => {
  const a = answers
  const toSet: Answer | undefined = a.find(
    ({ questionId }) => questionId === fresh.questionId
  )

  if (toSet !== undefined) {
    fresh.value = uniq(concat(toSet.value, fresh.value))
    a.splice(answers.indexOf(toSet), 1)
  }
  // Push fresh values
  a.push(fresh)
  return a
}

export function insertAt<T>(array: T[], index: number, ...elements: T[]): void {
  array.splice(index, 0, ...elements)
}

export const getSlotsReplies = (
  beforeSlots: number,
  afterSlots: number
): Reply[] => {
  const date: Date = getRoundHour()
  const passedDate = addMinutes(
    date,
    ITEMS_PER_CAROUSEL_PAGE * beforeSlots * -DURATION_SLOT_MIN
  )
  // CountSlots is the number of slots page
  // regarding the nb of items in a carousel page
  const countSlots: number =
    ITEMS_PER_CAROUSEL_PAGE * (afterSlots + beforeSlots)
  const replies: Reply[] = []
  for (let i = 0; i < countSlots; i++) {
    const minutesToAdd = i * DURATION_SLOT_MIN
    replies.push({
      title: getTime(addMinutes(passedDate, minutesToAdd)),
      value: addMinutes(passedDate, minutesToAdd).getTime(),
    })
  }
  return replies
}
