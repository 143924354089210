import { useForm } from "antd/lib/form/Form"
import { Button } from "components/Button"
import FatButton from "components/Button/FatButton/FatButton"
import { Buttons } from "components/Card/Card"
import { Carousel } from "components/Carousel/Index"
import { Modal } from "components/Modal/Modal"
import { Dayjs } from "dayjs"
import { DATE_FORMAT } from "lib/utils"
import React, { useState } from "react"
import { Patient } from "types/sesam"
import { NirReaderResponse } from "types/store"

const SelectPatient = (props: {
  patients: NirReaderResponse
  onSelect: (p: Partial<Patient>) => void
}): JSX.Element => {
  const [formRef] = useForm()
  const [selectedPatient, setSelectedPatient] = useState<Partial<Patient>>()
  return (
    <Modal
      title="Choisissez votre patient"
      visible={true}
      footer={
        <Buttons>
          <Button danger onClick={() => props.onSelect(props.patients.patient)}>
            Choisir le patient principal de la carte
          </Button>
          {selectedPatient ? (
            <Button
              type="primary"
              onClick={() => props.onSelect(selectedPatient)}
            >
              choisir le proche séléctionné
            </Button>
          ) : (
            <Button onClick={() => props.onSelect({})}>
              Ajouter un nouveau patient
            </Button>
          )}
        </Buttons>
      }
    >
      <div style={{ width: "1400px", margin: "auto" }}>
        {props.patients.relatives?.length ? (
          <Carousel
            itemsPerPage={6}
            items={props.patients.relatives?.map(
              (relative: Partial<Patient>, key: number) => (
                <FatButton
                  key={key}
                  clicked={selectedPatient === relative}
                  onClick={() => {
                    if (selectedPatient === relative)
                      setSelectedPatient(undefined)
                    else setSelectedPatient(relative)
                  }}
                >
                  {`${relative?.firstname} ${relative?.lastname} (${(
                    relative.birthdate as Dayjs
                  ).format(DATE_FORMAT)})`}
                </FatButton>
              )
            )}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FatButton>pas de proche présent sur la carte</FatButton>
          </div>
        )}
      </div>
    </Modal>
  )
}
export default SelectPatient
