import { Input as AntdInput, InputProps } from "antd"
import { languages } from "lib/languages"
import { useEffect, useState } from "react"
import { InputNames } from "types/props"
import "./Input.scss";
import { formatInsString } from "lib/utils";

const INSInput = (props: InputProps & any): JSX.Element => {
    const [formatIns, setFormatIns] = useState(false)
    const [pseudoValue, setPseudoValue] = useState(false)
    const [labelIns, setLabelIns] = useState("")

    useEffect(() => {
        if ([InputNames.BIRTH_LASTNAME, InputNames.FIRST_BIRTH_FIRSTNAME].includes(props.name)) {
            setFormatIns(true)
            props.name === InputNames.FIRST_BIRTH_FIRSTNAME && setLabelIns(languages.pseudo_firstname)
            props.name === InputNames.BIRTH_LASTNAME && setLabelIns(languages.pseudo_lastname)
        }
    }, [props.name])

    useEffect(() => {
        if (props.conditionChecked) {
            const pseudoValueName = props.name === InputNames.BIRTH_LASTNAME ? InputNames.LASTNAME : InputNames.FIRSTNAME
            setPseudoValue(props.formRef?.getFieldValue(pseudoValueName))
        } else {
            setPseudoValue(undefined)
        }
    }, [props.conditionChecked])
    return <>
        <AntdInput {...props} value={formatIns && props.value ? formatInsString(props.value) : props.value} style={{ color: props.disabled ? "#7A7A7A" : null }} />
        {(formatIns && !props.disabled) && <div className="ins-label">
            {labelIns} : {pseudoValue ?? props.value}
        </div>}
    </>
}

export default INSInput