import { createSelector } from "reselect"

import {
  getCustomer,
  getNirReaderResponse,
  getRelatives,
} from "client/services/selector"
import { isPatientExempted } from "lib/sesam"
import { PATH } from "models/survey/_paths"
import { FOR_PATIENT, FOR_RELATIVE } from "models/survey/constants"
import { Customer, Relative } from "types/entity"
import { Gender } from "types/entity"
import { NirReaderResponse, SurveyStore } from "types/store"

import { Answer, Question, Value } from "../types"

const getSurveyId = ({ survey }: { survey: SurveyStore }): string | undefined =>
  survey?.current?.id

const isSurveyEmpty = ({ survey }: { survey: SurveyStore }) => {
  const answers = survey.answers
  if (!survey.answers || !survey.answers.length) return true
  const answerMustBePresent = survey.answers.find(
    (answer) => answer.questionId === PATH.illness
  )
  return !answerMustBePresent
}

const getCurrentSelectedPatientId = ({
  survey,
}: {
  survey: SurveyStore
}): Value | undefined => {
  let selectedPatientId: Answer | undefined
  selectedPatientId = survey?.answers?.find(
    (answer: Answer) => answer.questionId === PATH.patient
  )

  if (selectedPatientId?.value[0] === FOR_RELATIVE) {
    selectedPatientId = survey?.answers?.find(
      (answer: Answer) => answer.questionId === PATH.relative
    )
  }
  return selectedPatientId?.value[0] as number
}

const getCurrentPatient = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): Customer | Relative => {
    if (selectedPatientId === FOR_PATIENT) {
      return patient
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative
  }
)

const getCurrentPatientFirstName = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): string | undefined => {
    if (selectedPatientId === 0) {
      return patient?.firstname
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative?.firstname
  }
)

const getCurrentQuestion = ({ survey }): Question => survey.current

const isPregnantQuestionSet = ({
  survey,
}: {
  survey: SurveyStore
}): boolean => {
  return survey?.questions?.some(
    (question: Question) => question.id === PATH.pregnant
  )
}
const isNirQuestionSet = ({ survey }: { survey: SurveyStore }): boolean => {
  return survey?.questions?.some(
    (question: Question) => question.id === PATH.exemption
  )
}
const getCurrentPatientGender = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): string | number | undefined => {
    if (selectedPatientId === 0) {
      return patient?.gender || Gender.MALE
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative?.gender
  }
)

const isCurrentPatientExempted = createSelector(
  getCurrentSelectedPatientId,
  getNirReaderResponse,
  (selectedPatientId, nirReaderResponse?: NirReaderResponse) => {
    // si !nirReaderResponse, nous ne pouvons pas savoir s'il est exempté
    if (!nirReaderResponse) return false
    else {
      if (selectedPatientId === 0) {
        // Le patient souhaitant faire une TLC est le patient principal
        return nirReaderResponse.patient
          ? isPatientExempted(nirReaderResponse.patient)
          : false
      } else {
        // le patient souhaite faire une TLC pour un proche
        // relatives = API relatives
        // nirReaderResponse.relative !=  API.relatives
        // Nous ne sommes pas en mesure de relier le proche en DB et le proche data Nir
        // car les noms ont pu être changé et l'id n'existe pas en Nir
        // nous refusons donc l'exemption par défaut
        return false
      }
    }
  }
)

const getCurrentPatientNir = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): boolean => {
    if (selectedPatientId === FOR_PATIENT) return !!patient?.nir
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return !!selectedRelative?.nir
  }
)

export {
  getSurveyId,
  isCurrentPatientExempted,
  getCurrentSelectedPatientId,
  getCurrentPatientFirstName,
  getCurrentPatientGender,
  getCurrentPatientNir,
  getCurrentPatient,
  isPregnantQuestionSet,
  isNirQuestionSet,
  isSurveyEmpty,
  getCurrentQuestion,
}
