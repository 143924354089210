import Compliance from "lib/api-compliance"
import { Customer } from "types/entity"
import { CustomerApi, PhoneCredential, Verify2Fa, twoFaData } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_LOGIN_STORE,
  _2FA_INITIAL_STEP_SUCCESS,
  TOGGLE_2FA,
  RESEND_2FA,
  ASKING_FOR_2FA_VERIFY,
  RESEND_REQUEST_SUCCESS,
  ASKING_FOR_2FA_TOGGLE,
  VERIFY_2FA,
  CANCEL_2FA,
  _2FA_CANCELED,
  TWO_FA_VERIFY_ERROR,
  REQUEST_ONGOING,
} from "./constants"

export const loginRequest = (
  payload: PhoneCredential
): Action<PhoneCredential> => ({
  type: LOGIN_REQUEST,
  payload,
})


export const twoFaInitialStepSuccess = (
  payload: any
): Action<any> => ({
  type: _2FA_INITIAL_STEP_SUCCESS,
  payload,
})

export const activateFa = (payload: twoFaData): Action<twoFaData>=> ({
  type: TOGGLE_2FA,
  payload,
})

export const resend2FaRequest = (
  payload :twoFaData ): Action<twoFaData> => ({
  type: RESEND_2FA,
  payload,
})

export const AskingFor2FaVerify = (payload: twoFaData): Action<twoFaData> => ({
  type: ASKING_FOR_2FA_VERIFY,
  payload,
})

export const resendSuccess = (payload: twoFaData): Action<twoFaData> => ({
  type: RESEND_REQUEST_SUCCESS,
  payload,
})

export const AskingFor2FaToggle = (payload: twoFaData): Action<twoFaData> => ({
  type: ASKING_FOR_2FA_TOGGLE,
  payload,
})

export const verify2FaRequest = (payload: Verify2Fa): Action<Verify2Fa> => ({
  type: VERIFY_2FA,
  payload,
})

export const cancel2FaRequest = (payload : {
  mfa_verify_id: string
}): Action<{mfa_verify_id: string}> => ({
  type: CANCEL_2FA,
  payload,
})

export const cancel2FaSuccess = (): ActionWithoutPayload =>({
type :  _2FA_CANCELED
})

export const twoFaVerifyError = (error: string): Action<Message> => ({
  type: TWO_FA_VERIFY_ERROR,
  payload: { text: error, type: "error" },
})

export const requestOngoing = (): ActionWithoutPayload => ({
  type: REQUEST_ONGOING,
})

export const logout = (): ActionWithoutPayload => ({
  type: LOGOUT,
})

export const loginError = (error: string): Action<Message> => ({
  type: LOGIN_ERROR,
  payload: { text: error, type: "error" },
})

export const loginSuccess = (payload: CustomerApi): Action<Customer> => ({
  type: LOGIN_SUCCESS,
  payload: new Compliance<CustomerApi, Customer>(payload).please() as Customer,
})

export const resetLogin = (): ActionWithoutPayload => ({
  type: RESET_LOGIN_STORE,
})
