export enum PATH {
  unknown_path = "unknown_path",
  diagnostic = "diagnostic",
  patient = "patient",
  relative = "patient:relative",
  exemption = "exemption",
  consultation_time = "consultation_time",
  not_adapted = "not_adapted",
  remove_lenses = "remove_lenses",
  timeline_initialize = "timeline-initialize",
  timeline_anamnesis = "timeline-anamnesis",
  inform_anamnesis = "inform_anamnesis",
  reason = "reason",
  reason_details = "reason:details",
  illness = "illness",
  illness_details = "illness:details",
  optic_illness = "optic_illness",
  optic_illness_details = "optic_illness:details",
  older_than_50 = "older_than_50",
  timeline_tutorial = "timeline-tutorial",
  tutorial = "tutorial",
  timeline_orthoptist = "timeline-orthoptist",
  payment = "payment",
  timeline_ophtalmologist = "timeline-ophtalmologist",
  doctor = "doctor",
  slot = "doctor:slot",
  allergy = "allergy",
  allergy_details = "allergy:details",
  operation = "operation",
  consent = "consent",
  drug = "drug",
  pregnant = "pregnant",
  ineligible = "ineligible",
  choice_of_doctor = "choice_of_doctor",
  choice_of_doctor_doctor_available = "choice_of_doctor:doctor_available",
  doctor_available = "doctor_available",
  call_ortho_on_going = "call_ortho_on_going",
  call_ophta_on_going = "call_ophta_on_going",
  selected_carer = "selected_carer",
}
