import { first } from "lodash"

export const cdn = (url: string | undefined): string | undefined => {
  const cdn_url = localStorage.getItem("CDN_URL")

  if (!url || !url?.length) return "undefined url"

  const slashed = first(url) === "/" ? url : `/${url}`
  if (!cdn_url) return slashed

  return `${cdn_url}${slashed}`
}