import React, { useEffect } from "react"
import { formatPhoneNumber } from "react-phone-number-input"
import { connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { RESET_STORE } from "client/services/constants"
import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import useOurForm from "components/Form/useForm"
import { HOME_PAGE, PROFILE_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import { GlobalStore } from "types/store"

import actions from "./services/actions"
import styles from "./VerifyPhone.module.scss"

interface VerifyPhoneProps {
  loading?: boolean
  success?: boolean
  message?: Message
  phone: string
  verify: (payload: { code: string; phone: string }) => void
  cancel: () => void
  sendAgain: () => void
}

const VerifyPhone: React.FC<VerifyPhoneProps> = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formRef = useOurForm()

  useEffect(() => {
    if (props.success) {
      dispatch(actions.reset())
      navigate(PROFILE_PAGE)
    }
  }, [props.success])

  const label = (
    <div>
      {languages.writeVerifyCode}
      <span className={styles.Phone}>{formatPhoneNumber(props.phone)}</span>
    </div>
  )
  const inputsObj = [{ name: "code", required: true, label, autoSubmit: true }]
  return (
    <Card message={props.message}>
      <FormPage
        formRef={formRef}
        inputs={inputsObj}
        acceptText={languages.register}
        cancelText={languages.cancel}
        loading={props.loading}
        onFinish={(values) => {
          const code = (values as any).code
          props.verify({ ...(values as { code: string }), phone: props.phone })
          formRef.reset(["code"])
        }}
        onCancel={() => {
          props.cancel()
          dispatch({ type: RESET_STORE })
          navigate(HOME_PAGE)
        }}
      />
      <Buttons style={{ paddingTop: 20 }}>
        <Button type="link" size="small" onClick={() => props.sendAgain()}>
          Renvoyer le code
        </Button>
      </Buttons>
    </Card>
  )
}
const s = ({
  client,
  verifyPhone,
}: GlobalStore): Omit<VerifyPhoneProps, "cancel" | "verify" | "sendAgain"> => ({
  ...verifyPhone,
  phone: client?.customer?.phone as string,
})

const p = (
  dispatch: (a: Action<unknown> | ActionWithoutPayload) => void
): Omit<VerifyPhoneProps, "phone"> => ({
  verify: (payload: { code: string; phone: string }) =>
    dispatch(actions.request(payload)),
  cancel: () => dispatch(actions.cancelRequest()),
  sendAgain: () => dispatch(actions.sendAgainRequest()),
})

export default connect(s, p)(VerifyPhone)
