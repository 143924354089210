import { ModalProps } from "types/props"
import { GenericModal } from "./Modal"
import { Buttons } from "components/Card/Card"
import { Button } from "components/Button"
import { languages } from "lib/languages"
import Spacer from "components/Spacer"
import { Information, Title } from "components/Title/Title"
import { cdn } from "core/cdn"

export const UnderLegalAgeModal = (
    props: ModalProps
): JSX.Element => {
    return (
        <GenericModal
            {...props}
            footer={
                <Buttons direction="vertical">
                    <Button onClick={props.onAccept} wide="long" type="primary">
                        {languages.IGotIt}
                    </Button>
                </Buttons>
            }
        >
            <Spacer
                style={{ textAlign: "center" }}
                size={5}
                align="center"
                direction="vertical"
            >
                <img
                    height={114}
                    src={cdn("images/proche_picto.svg")}
                    alt="Carte vitale"
                />
                <Title text={languages.majorityRequired} />
                <div className="modal-content-left">
                    <Information
                        text={<span>{languages.majorityRequiredInfoFirstChunk}<span style={{ fontWeight: 800 }}>{languages.majorityRequiredInfoSecondChunk}</span>{languages.majorityRequiredInfoLastChunk}</span>}
                    />
                    <Information
                        style={{ fontWeight: "bold" }}
                        text={languages.majorityRequiredAddRelative}
                    />
                </div>
            </Spacer>
        </GenericModal>
    )
}