import { first } from "lodash"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import opticActions from "client/optic/actions"
import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import { Modal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Subtitle } from "components/Title/Title"
import { logout } from "containers/Login/services/actions"
import surveyActions from "containers/Survey/services/actions"
import { SURVEY_PAGE } from "core/constants"
import { languages } from "lib/languages"
import { PATH } from "models/survey/_paths"

function withSurveyController<T>(
  WrappedComponent: React.FC<T>,
  options?: { hideNext?: boolean; hideTitle?: boolean }
): React.FC<T> {
  return (props: T): JSX.Element => {
    const dispatch = useDispatch()
    const [previousText, setPreviousText] = useState(languages.back)
    const navigate = useNavigate()
    const [modalVisible, setModalVisibility] = useState(false)
    const { current } = useSelector(({ survey }) => survey)

    useEffect(() => {
      if (current.id === PATH.timeline_ophtalmologist) {
        setPreviousText(languages.cancelPath)
      } else {
        setPreviousText(languages.back)
      }
    }, [current])

    const previous = () => {
      if (current.id === PATH.timeline_ophtalmologist) {
        setModalVisibility(true)
      } else {
        dispatch(surveyActions.previous())
        navigate(SURVEY_PAGE)
      }
    }
    return (
      <>
        <PreviousButton text={previousText} onClick={previous} />
        <Card
          title={options?.hideTitle ? null : current.title}
          subtitle={current.subtitle}
        >
          <WrappedComponent {...props} />
          {options?.hideNext ? null : (
            <Buttons>
              <Button
                type="primary"
                wide="long"
                onClick={() => {
                  dispatch(surveyActions.next())
                  navigate(SURVEY_PAGE)
                }}
              >
                {languages.continue}
              </Button>
            </Buttons>
          )}
        </Card>
        <Modal
          title={languages.confirmCancelCall}
          visible={modalVisible}
          onAccept={() => {
            dispatch(opticActions.cancelPath())
            dispatch(logout())
          }}
          acceptText={languages.cancelCall}
          acceptButtonProps={{ danger: true, type: "primary" }}
          onCancel={() => setModalVisibility(false)}
        >
          <Subtitle text="Vous allez être déconnecté" />
        </Modal>
      </>
    )
  }
}

export default withSurveyController as any
