import { Buttons } from "components/Card/Card"
import { languages } from "lib/languages"
import React from "react"

export const Hangup: React.FC<unknown> = ({}): JSX.Element => {
  return (
    <Buttons style={{ paddingTop: "0" }}>
      <div className="paragraph">{languages.doctorIsWritingYourDocs}</div>
    </Buttons>
  )
}
