import { default as Space, SpaceProps } from "antd/lib/space"
import React from "react"

import Message from "components/Message/Message"
import { Message as MessageType } from "types/redux"

import { Subtitle, Title } from "../Title/Title"
import styles from "./styles.module.scss"

export const Card: React.FC<
  {
    title?: string |  any
    subtitle?: string
    message?: MessageType
    imgUrl?: string
    imgSize?: string
    titleSize?: "large" | "small" | "medium"
    imgPosition?: "bottom" | "top"
  } & SpaceProps
> = (props): JSX.Element => {
  return (
    <div className={styles.Card} style={props.style}>
      {props.title && props.imgPosition === "bottom" ? (
        <Title size={props.titleSize ?? undefined} text={props.title} />
      ) : null}
      {props.imgUrl && (
        <img
          src={props.imgUrl}
          height={props.imgSize}
          className={`${styles.Illustration} ${
            props.imgSize
              ? props.imgSize === "large"
                ? styles.LargeImg
                : props.imgSize === "medium"
                ? styles.MediumImg
                : styles.SmallImg
              : undefined
          }`}
          alt={props.title}
        />
      )}
      {props.title && props.imgPosition !== "bottom" ? (
        <Title size={props.titleSize ?? undefined} text={props.title} />
      ) : null}
      {props.subtitle && <Subtitle text={props.subtitle} />}
      <Message message={props.message} />
      <div className={styles.Content}>{props.children}</div>
    </div>
  )
}

export const Buttons: React.FC<SpaceProps> = ({
  children,
  ...rest
}): JSX.Element => {
  if (rest.direction === "vertical")
    return (
      <Space direction="vertical" className={`${styles.Buttons}`} {...rest}>
        {children}
      </Space>
    )
  return (
    <Space
      direction="horizontal"
      size={60}
      style={{
        paddingTop: "50px",
        justifyContent: "center",
        width: "100%",
        ...rest.style,
      }}
    >
      {children}
    </Space>
  )
}
