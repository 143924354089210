import { SETUPINTENT_ATTACH_ERROR } from "containers/Stripe/services/constants"
import { Answer } from "containers/Survey/types"
import { Prices } from "types/payload"
import { Action, Message } from "types/redux"
import { PaymentStore } from "types/store"

import { PAYMENT_REQUIRES_ACTION, PRICE_ERROR, PRICE_REQUEST, PRICE_SUCCESS, RESET_PAYMENT_STORE, TLC_ERROR, TLC_REQUEST, TLC_SUCCESS } from "./constants"

const initialState: Partial<PaymentStore> = {
  loading: false,
  success: false,
}

const PaymentReducer = (
  state = initialState,
  action: Action<Answer[] | Message | Prices>
): Partial<PaymentStore> => {
  switch (action.type) {
    case TLC_REQUEST:
    case PAYMENT_REQUIRES_ACTION:
      return { ...state, loading: true, message: undefined }
    case TLC_SUCCESS:
      return { ...state, loading: false, success: true }
    case PRICE_REQUEST:
      return {
        ...state,
        message: undefined,
      }
    case PRICE_SUCCESS:
      return { ...state, price: action.payload as Prices }
    case TLC_ERROR:
    case SETUPINTENT_ATTACH_ERROR:
    case PRICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload as Message,
      }
    case RESET_PAYMENT_STORE:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default PaymentReducer
