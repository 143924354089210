import { ACTION, Question, QUESTION_TYPE } from "containers/Survey/types"
import { cdn } from "core/cdn"
import { TUTORIAL_OPTIC_PAGE } from "core/constants"
import { languages } from "lib/languages"

import { PATH } from "./_paths"
import { booleanReplies, booleanSubReplies } from "./questions"

export const reason_details: Question = {
  title: languages.WhenWasThelastConsultation,
  id: PATH.reason_details,
  replies: [
    {
      title: languages.lessThanAYear,
      value: languages.lessThanAYear.toLowerCase(),
    },
    {
      title: languages.moreThanAYear,
      value: languages.moreThanAYear.toLowerCase(),
    },
  ],
}
export const reason: Question = {
  title: languages.anamneseReasonQuestion,
  id: PATH.reason,
  multipleReplies: false,
  // ui: { view: "carousel" },
  subquestion: reason_details,
  replies: [
    {
      title: languages.glassesRenewal,
      value: languages.glassesRenewal,
      action: ACTION.subquestion,
    },
    {
      title: languages.lensesRenewal,
      value: languages.lensesRenewal,
      action: ACTION.subquestion,
    },
    {
      title: languages.firstConsultation,
      value: languages.firstConsultation,
    },
  ],
}
export const optic_illness_details: Question = {
  title: languages.whichOnes,
  id: PATH.optic_illness_details,
  multipleReplies: true,
  replies: [
    {
      title: languages.glaucoma,
      value: languages.glaucoma,
      imgUrl: cdn("/images/optic_illnesses/picto_glaucoma.svg"),
    },
    {
      title: languages.DMLA,
      value: languages.DMLA,
      imgUrl: cdn("/images/optic_illnesses/picto_DMLA.svg"),
    },
    {
      title: languages.strabismus,
      value: languages.strabismus,
      imgUrl: cdn("/images/optic_illnesses/picto_strabismus.svg"),
    },
    {
      title: languages.keratoconus,
      value: languages.keratoconus,
      imgUrl: cdn("/images/optic_illnesses/picto_keratoconus.svg"),
    },
    {
      title: languages.cataract,
      value: languages.cataract,
      imgUrl: cdn("/images/optic_illnesses/picto_cataract.svg"),
    },
    {
      title: languages.otherOphtalmologicDisease,
      value: languages.otherOphtalmologicDisease,
      imgUrl: cdn("/images/optic_illnesses/picto_other_eye.svg"),
    },
  ],
}

export const optic_illness: Question = {
  imgUrl: cdn("/images/allergie_illustration.svg"),
  title: languages.antecedents,
  id: PATH.optic_illness,
  multipleReplies: false,
  subquestion: optic_illness_details,
  replies: booleanSubReplies,
}
export const older_than_50: Question = {
  imgUrl: cdn("/images/old_person.svg"),
  title: languages.olderThanFifty,
  id: PATH.older_than_50,
  multipleReplies: false,
  replies: booleanReplies,
}

export const tutorial: Question = {
  id: PATH.tutorial,
  title: languages.demo,
  multipleReplies: false,
  replies: [],
  type: QUESTION_TYPE.redirect,
  value: TUTORIAL_OPTIC_PAGE,
}
