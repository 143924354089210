import { Action, ActionWithoutPayload, Message } from "types/redux"
import { NirReaderResponse } from "types/store"

import {
  SOCKET_CANCEL_CARD_VITALE_READER,
  SOCKET_ERROR_CARD_VITALE_READER,
  SOCKET_INIT_CARD_VITALE_READER,
  SOCKET_NIR_READER_RESPONSE,
  SOCKET_REMOVE_CARD_VITALE_READER,
  SOCKET_REMOVED_CARD_VITALE_READER,
  SOCKET_RESET_CARD_VITALE_READER,
} from "./constants"

export function cancelSocketNirReader(): Action<undefined> {
  return {
    type: SOCKET_CANCEL_CARD_VITALE_READER,
    payload: undefined,
  }
}

export const removeCardSocketNirReader = (): ActionWithoutPayload => {
  return {
    type: SOCKET_REMOVE_CARD_VITALE_READER,
  }
}

export function removedCardSocketNirReader(): ActionWithoutPayload {
  return {
    type: SOCKET_REMOVED_CARD_VITALE_READER,
  }
}

export function requestSocketNirReader(): ActionWithoutPayload {
  return {
    type: SOCKET_INIT_CARD_VITALE_READER,
  }
}

export function resetSocketNirReader(): ActionWithoutPayload {
  return { type: SOCKET_RESET_CARD_VITALE_READER }
}

export const socketNirReaderResponse = (
  payload: NirReaderResponse
): Action<NirReaderResponse> => {
  return { type: SOCKET_NIR_READER_RESPONSE, payload }
}

export function errorCardSocketNirReader(error: string): Action<Message> {
  return {
    type: SOCKET_ERROR_CARD_VITALE_READER,
    payload: { text: error, type: "error" },
  }
}
