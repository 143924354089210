import { first } from "lodash"
import config from "react-global-configuration"

import { Answer, Value } from "containers/Survey/types"
import { PATH } from "models/survey/_paths"
import { Call, OpticConsultationRequest, ResponsePrice } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"

import { PAYMENT_REQUIRES_ACTION, PRICE_ERROR, PRICE_REQUEST, PRICE_SUCCESS, RESET_PAYMENT_STORE, TLC_ERROR, TLC_REQUEST, TLC_SUCCESS } from "./constants"

const actions = {
  reset: (): ActionWithoutPayload => ({ type: RESET_PAYMENT_STORE }),
  submit: (answers?: Answer[]): Action<OpticConsultationRequest> => {
    if (!answers)
      return { type: TLC_REQUEST, payload: { ...config.get("mock.get.call") } }

    const complyResponse = (wantedId: string): Value | undefined => {
      const responses: Value[] | undefined =
        answers.find(({ questionId }) => questionId === wantedId)?.value ||
        undefined
      if (!responses) return undefined
      const complied =
        responses.length > 1 ? responses.join(", ") : first(responses)

      return complied
    }

    const payload: OpticConsultationRequest = {
      ...config.get("mock.call"),
      relative_id: complyResponse(PATH.relative),
      anamnesis: {
        ophtalmologic_background: complyResponse(PATH.optic_illness),
        ophtalmologic_background_details: complyResponse(
          PATH.optic_illness_details
        ),
        illness: complyResponse(PATH.illness),
        illness_details: complyResponse(PATH.illness_details),
        reason: complyResponse(PATH.reason),
        reason_details: complyResponse(PATH.reason_details),
        older_than_50: complyResponse(PATH.older_than_50),
      },
      payment: {
        exemption: complyResponse("exemption") || false,
      },
      payment_method_id: complyResponse("payment_method_id"),
      payment_intent_id: complyResponse("payment_intent_id"),
      share_personal_data: complyResponse(PATH.consent),
      carer_guid: complyResponse(PATH.selected_carer),
    }
    return {
      type: TLC_REQUEST,
      payload,
    }
  },

  error: (error: string): Action<Message> => ({
    type: TLC_ERROR,
    payload: { text: error || "Impossible de lancer la TLC", type: "error" },
  }),

  success: (payload: Call): Action<Call> => ({
    type: TLC_SUCCESS,
    payload,
  }),
  paymentRequiresAction: (
    payment_intent_client_secret: string
  ): Action<string> => ({
    type: PAYMENT_REQUIRES_ACTION,
    payload: payment_intent_client_secret,
  }),
  priceRequest: (payload: {
    relativeId?: string
  }): Action<{ relativeId?: string }> => ({
    type: PRICE_REQUEST,
    payload,
  }),
  priceError: (payload: string): Action<Message> => ({
    type: PRICE_ERROR,
    payload: { type: "error", text: payload },
  }),
  priceSuccess: (payload: ResponsePrice): Action<ResponsePrice> => ({
    type: PRICE_SUCCESS,
    payload,
  }),
}

export default actions
