import { put, call, takeLatest, all } from "redux-saga/effects"
import { request } from "lib/request"
import config from "react-global-configuration"
import clientActions from "client/services/actions"
import { EmailCredential, ResponseAdmin, ResponsePayload } from "types/payload"

import { ADMIN_LOGIN_REQUEST, ADMIN_LOGOUT } from "./constants"
import { adminLoginError, adminLoginSuccess } from "./actions"
import { Action, GFlow, GWatcher, Message } from "types/redux"
import configurationActions from "client/config/actions"
/*
 *  API REQUESTS
 */
async function adminLoginApi(payload?: {
  email: string
  password: string
}): Promise<ResponseAdmin> {
  return request(config.get("admin.login"), {
    method: "POST",
    payload,
  })
}

async function adminLogoutApi(): Promise<ResponsePayload> {
  return request(config.get("admin.login"), { method: "POST" })
}

/*
 *  FLOWS
 */
function* logoutFlow(): GFlow<ResponsePayload> {
  yield call(adminLogoutApi)
  yield put(clientActions.reset)
}

function* adminFlow({
  payload,
}: Action<EmailCredential>): GFlow<ResponseAdmin | Action<Message>> {
  try {
    const response: ResponseAdmin = yield adminLoginApi(payload)
    yield all([
      put(adminLoginSuccess("Administrateur connecté")),
      put(configurationActions.request()),
      put(clientActions.setAdmin(response.pharmacy)),
    ])
  } catch (error) {
    yield put(adminLoginError(error as string))
  }
}

function* adminWatcher(): GWatcher {
  yield takeLatest(ADMIN_LOGIN_REQUEST, adminFlow)
  yield takeLatest(ADMIN_LOGOUT, logoutFlow)
}

export default adminWatcher
