import config from "react-global-configuration"
import { call, put, takeLatest } from "redux-saga/effects"

import { request } from "lib/request"
import {
  Action,
  ActionWithoutPayload,
  GFlow,
  GWatcher,
  Message,
} from "types/redux"

import actions from "./actions"
import { RESET_PASSWORD_REQUEST } from "./constants"

async function resetPasswordApi(payload: { phone: string }) {
  return await request(config.get("patient.sendLinkResetPassword"), {
    method: "POST",
    payload,
  })
}

function* resetPasswordFlow(
  action: Action<{ phone: string }>
): GFlow<Action<Message> | ActionWithoutPayload> {
  try {
    yield call(resetPasswordApi, action.payload)
    yield put(actions.resetPasswordRequestIsMade())
  } catch (error) {
    yield put(actions.resetPasswordRequestIsMade())
  }
}
function* patientWatcher(): GWatcher {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordFlow)
}

export default patientWatcher
